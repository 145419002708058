import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { afiQuotesWrapper, parseAfiPayload, parseAfiResponse } from '../../api/apiFunctions';
import LoadingScreen from '../../components/common/loading-screen/LoadingScreen';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import { UserContext } from '../../context/UserContext';
import { serializeAfiData } from '../../services/helpers/AFIHelpers';

const AfiLoading = () => {
  const { setCarriersError } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();
  const { AFIAnswersObject, setQuotes } = useContext(AnswerFinancialContext);
  const { authToken } = useContext(UserContext);

  const updateResult = (quotes: [], error: boolean) => {
    setQuotes(quotes);
    setCarriersError(error);
    localStorage.clear();
    navigate('/full-quote-auto/quotes/carriers', {
      replace: true,
    });
  };

  const newData = serializeAfiData(AFIAnswersObject);
  const callSequence = async (signal: AbortSignal) => {
    try {
      const xmlData = await parseAfiPayload(authToken, newData, signal);
      const startTime = new Date();
      const quotes = await afiQuotesWrapper(authToken, xmlData.data, signal);
      const endTime = new Date();
      const { urls } = quotes.data;
      const reportingData: { [key: string]: string | number } = {};
      reportingData.firstName = newData.applicant.general.name.firstName;
      reportingData.lastName = newData.applicant.general.name.lastName;
      reportingData.state = newData.applicant.general.address.state;
      reportingData.requestDuration = Number(endTime) - Number(startTime);
      if (urls?.payloadUrl && urls?.responseUrl) {
        reportingData.payloadUrl = urls.payloadUrl;
        reportingData.responseUrl = urls.responseUrl;
      }
      const response = await parseAfiResponse(authToken, quotes.data.data, signal, reportingData);
      if (['success', 'successwithwarning', 'successwithinfo'].includes(response.data.status)) {
        updateResult(response.data.policies, false);
      } else {
        updateResult([], response.data.description);
      }
    } catch (error: any) {
      if (error.name !== 'CanceledError') {
        updateResult([], true);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    callSequence(signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className="afi-loading-container">
      <LoadingScreen text="Getting your quotes" />
    </div>
  );
};

export default AfiLoading;
