import QuestionField from '../QuestionField/QuestionField';
import { FormikProps, FormikValues } from 'formik';
import { AFIQuestionInterface } from '../../../interfaces/AFIInterfaces';
import './form-questions.scss';
import AddressForm from '../../forms/AddressForm/AddressForm';
import InfoField from '../Info/InfoField';

const FormQuestions = ({
  formTitle,
  questions,
  description,
  info,
  formikConfig,
}: {
  formTitle?: string;
  description?: React.ReactNode;
  questions: AFIQuestionInterface[];
  info?: {
    anchorField: 'title' | string;
    title: string;
    hideTitle?: boolean;
    content: { subtitle?: string; description: string }[];
  };
  formikConfig: FormikProps<FormikValues>;
}) => {
  const getField = (item: AFIQuestionInterface) => {
    switch (item.identifier) {
      case 'address':
        return (
          <div key={item.identifier}>
            <AddressForm questionObject={item} formikConfig={formikConfig} />
          </div>
        );
      case 'parkingAddress':
        return (
          <div key={item.identifier}>
            <AddressForm questionObject={item} formikConfig={formikConfig} customKey="parking" />
          </div>
        );
      default:
        return (
          <div
            key={item.identifier}
            className={`${item.className || ''} ${item.hasLabel === 'left' ? 'labeled-field-container' : ''}`}
          >
            {item.hasLabel && (
              <div className={item.hasLabel === 'top' ? 'field-label-top' : 'field-label-left'}>
                {item.title}
              </div>
            )}
            <QuestionField questionObject={item} formikConfig={formikConfig} />
            {item.identifier === info?.anchorField && (
              <InfoField title={info?.title} hideTitle={info.hideTitle} content={info?.content} />
            )}
          </div>
        );
    }
  };

  return (
    <div className="form-question-container">
      <div className="form-title-container">
        {formTitle && <p className={`form-title ${description ? 'with-description' : ''}`}>{formTitle}</p>}{' '}
        {info?.anchorField === 'title' && <InfoField title={info.title} content={info?.content} hideTitle />}
      </div>
      {description && <div className="form-description">{description}</div>}
      <div className="form-fields-container">
        {questions.map((item: AFIQuestionInterface) => getField(item))}
      </div>
    </div>
  );
};

export default FormQuestions;
