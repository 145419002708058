export const introContent = {
  title: 'Can you save on <span>car insurance</span>?',
};

export const afiIntroContent = {
  title: 'Ready to keep more money in your pocket?',
  description:
    'If your car insurance rates are going up, or you’ve been with the same carrier for years, now is the time to search for a cheaper option!',
};

export const zipCodeLimit = 5;

export const mileageOptions = [
  { id: '1', value: '0 - 5,000' },
  { id: '2', value: '5,001 - 7,500' },
  { id: '3', value: '7,501 - 10,000' },
  { id: '4', value: '10,001 - 12,500' },
  { id: '5', value: '12,501 - 15,000' },
  { id: '6', value: '15,001 - 18,000' },
  { id: '7', value: '18,001 - 25,000' },
  { id: '8', value: '25,001 - 50,000' },
  { id: '9', value: 'More than 50,000' },
];

export const ownershipOptions = [
  { id: '1', value: 'Owned' },
  { id: '2', value: 'Financed' },
  { id: '3', value: 'Leased' },
];

export const defaultVehicles = [
  {
    year: '',
    make: '',
    model: '',
  },
  {
    year: '',
    make: '',
    model: '',
  },
];

export const defaultDrivers = [
  {
    creditEvaluation: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  {
    creditEvaluation: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  {
    creditEvaluation: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  {
    creditEvaluation: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  {
    creditEvaluation: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
];

const stepNames = [
  '',
  'model',
  'second-vehicle',
  'current-insurance',
  'insurer',
  'derogatories',
  'homeowner',
  'credit-score',
  'driver-age',
  'contact',
  'second-driver',
];

export const getProgressStep = (stepId: string) => {
  return stepNames.indexOf(stepId);
};

export const getProgressStepByIndex = (index: number) => {
  return stepNames[index];
};

export const disclaimerText =
  'By clicking “Next” I give my express consent to receive marketing communications via calls, texts, and emails from our partner, its marketing and re-marketing network, and up to 5 marketing partners or their affiliates at the above phone number, of which I am the regular user, via auto dialers, prerecorded messages, artificial voice or other electronic means. I am providing this consent even if my number is wireless or VOIP and/or registered on a Do-Not-Call Registry. Message & data rates may apply. I understand that my consent is not a condition of purchase and that I may revoke my consent at any time by visiting our Contact Us page, selecting "Do not call" under the "Opt-Out" section and submitting your information. Also, by providing my information and clicking "Next," I am directing harmonicft.com to share my information with their network of partners to help me obtain insurance quotes.';

export const nextPageAnimationProps = {
  animationType: 'slide-out',
  resetAnimationType: 'slide-in',
};

export const previousPageAnimationProps = {
  animationType: 'slide-out_navback',
  resetAnimationType: 'slide-in_navback',
};

type stepAnimationProps = {
  animationType: string;
  resetAnimationType: string;
};

export const stepAnimation = (setState: Function, animationStyles: stepAnimationProps, onClick: Function) => {
  const { animationType, resetAnimationType } = animationStyles;
  setState(animationType);

  setTimeout(() => {
    setState(resetAnimationType);
    onClick();
  }, 700);
};

export const introListText = [
  'Answer some questions',
  'Get a competitive quote',
  'Activate the policy with carrier',
];
