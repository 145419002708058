import { address, parkingAddress } from '../utils/afi/questions/address';
import {
  AfiIndexes,
  AfiListSections,
  AfiNavigationType,
  AfiStepType,
} from '../components/interfaces/AFIInterfaces';
import { SectionsNavigationMapping } from '../components/interfaces/CommonInterfaces';
import {
  emailAddress,
  firstName,
  homePhone,
  lastName,
  sameParkingAddress,
} from '../utils/afi/questions/applicant';
import {
  driverAAAMember,
  driverAgeLicensed,
  driverDefensiveDiscount,
  driverDOB,
  driverEducation,
  driverFirstName,
  driverGender,
  driverGoodStudentDiscount,
  driverLastName,
  driverLicenseStatus,
  driverOccupation,
  driverRelationshipToInsured,
  driverResidence,
  driverSR22,
  driverSR22FilingState,
  driverStudentLivingAway,
  driverTrainingDiscount,
  maritalStatus,
  defensiveDriverCourseDate,
  driverLicenseState,
  driverIncidents,
  incidentDate,
  incidentDescription,
} from '../utils/afi/questions/driver';
import {
  annualMileage,
  carAlarm,
  disablingDevice,
  existingDamage,
  make,
  model,
  ownership,
  principalOperator,
  purchaseDate,
  recoveryDevice,
  registeredTo,
  rideSharing,
  salvagedTitle,
  steeringLock,
  usage,
  vehicleYear,
} from '../utils/afi/questions/vehicle';
import {
  bodilyInjuryLimits,
  creditScore,
  insuranceCarrier,
  paymentFrequency,
  premium,
  canceledBefore,
  insuranceDuration,
  primaryPropertyDamage,
  medPay,
  policyStartDate,
  uninsuredPropertyDamage,
  uninsuredBodilyInjuryLimit,
  primaryBodilyInjuryLimit,
  underinsuredMotoristsBodilyInjury,
  pip,
  priorPolicyExpDate,
} from '../utils/afi/questions/coverage';
import _ from 'lodash';
import {
  addCanopyCarFieldsToCoverage,
  addCarFieldsToCoverage,
  disableFirstDriverFields,
  toggleDriverSteps,
  updateCarsSteps,
  updateCoverageSteps,
} from '../services/helpers/AFICustomNavigation';

export const afiPolicyName = 'answer_financial';

export const canopyCurrentInsurerImages = (partner: string, device: string) => {
  const CanopyCurrentInsurerImg =
    require(`../assets/images/partners/${partner}/afi/${device}/canopy-current-insurer.svg`).default;
  return <CanopyCurrentInsurerImg />;
};

export const canopySuccessImages = (partner: string, device: string) => {
  const CanopySuccessImg =
    require(`../assets/images/partners/${partner}/afi/${device}/canopy-success.svg`).default;
  return <CanopySuccessImg />;
};

export const basicsTemplate = {
  name: {
    firstName: '',
    lastName: '',
    emailAddress: '',
  },
  address: {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    driverResidence: 'RENTD',
    sameParkingAddress: 'Yes',
    parkingAddress: '',
    parkingAddress2: '',
    parkingCity: '',
    parkingState: '',
    parkingZip: '',
  },
};

export const driverTemplate = {
  details: {
    driverFirstName: '',
    driverLastName: '',
    driverDob: '',
    homePhone: '',
    driverGender: '',
    driverRelationshipToInsured: '',
    driverMaritalStatus: '',
    driverAgeLicensed: '',
    driverLicenseStatus: '',
    driverLicenseState: '',
    driverEducation: '',
    driverOccupation: '',
  },
  discounts: {
    driverAaaMember: 'None',
    driverTrainingDiscount: 'No',
    driverDefensiveDiscount: 'N',
    driverGoodStudentDiscount: 'No',
    driverStudentLivingAway: 'No',
    defensiveDriverCourseDate: '',
  },
  history: { driverIncidents: 'N', driverSr22: 'N', driverSr22FilingState: '' },
  incidents: [{ incidentDate: '', incidentDescription: '' }],
};
export const carTemplate = {
  details: {
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleOwnership: '',
    vehicleUsage: '',
    annualMileage: '',
    purchaseDate: '',
    principalOperator: '',
    registeredTo: '',
    existingDamage: 'No',
    rideSharing: 'No',
    salvagedTitle: 'No',
    carAlarm: 'None',
    disablingDevice: 'None',
    recoveryDevice: 'None',
    steeringLock: 'None',
  },
};
export let coverageTemplate = {
  existing: {
    insuranceCarrier: '',
    premium: '',
    paymentFrequency: '',
    priorInsuranceBodilyInjuryLimits: '',
    canceledBefore: 'No',
    insuranceDuration: '',
    priorPolicyExpDate: '',
  },
  credit: {
    creditScore: 'Superior',
  },
  'new-coverage': {
    primaryBodilyInjuryLimit: '',
    primaryPropertyDamage: '',
    medPay: '',
    uninsuredBodilyInjuryLimit: '',
    uninsuredPropertyDamage: '',
    underinsuredMotoristsBodilyInjury: '',
    policyStartDate: '',
  },
};
export const quotesTemplate = {};

export const AfiAnswersTemplate = {
  basics: basicsTemplate,
  drivers: [driverTemplate],
  cars: [carTemplate],
  coverage: coverageTemplate,
  quotes: quotesTemplate,
} as any;

/**
 * Returns the required image for a particular AFI step
 * @param imgName - Image name as found in the assets folder. IMPORTANT: step name and image name need to be the same.
 * @param partner - PartnerName, variable found in UserContext.
 * @param device - Device type, variable found in UserContext

 * @returns Returns the required image for a particular AFI step as JSX Element
 */

//Images
export const AfiImages = (imgName: string, partner: string, device: string) => {
  const Img = require(`../assets/images/partners/${partner}/afi/${device}/${imgName}.svg`).default;

  return <Img />;
};

// Progress Bar breakpoint multiplier
export const progressBarBreakpoints: { [key: string]: number } = {
  0: 0,
  1: 0.5,
  2: 0.9,
  3: 0.95,
  4: 1,
};

export const summaryPagesContent: AfiListSections = {
  'incidents-summary': {
    title: 'Tickets & Accidents',
    subtitle: 'Include all incidents within the last 5 years',
    summaryCardLabel: 'Ticket',
    extraButtonText: '+ Add another incident',
    ctaButtonText: 'Next',
    nextURL: 'drivers-summary',
  },
  'drivers-summary': {
    title: 'Drivers on your policy',
    subtitle: 'Add all drivers 15 and over in your household',
    summaryCardLabel: 'Policy holder',
    extraButtonText: '+ Add another driver',
    ctaButtonText: 'Next',
    nextURL: 'car-info',
  },
  'cars-summary': {
    title: 'Your cars',
    subtitle: 'Include all cars that will be included in your quote',
    summaryCardLabel: '',
    extraButtonText: '+ Add another car',
    ctaButtonText: 'Next',
    nextURL: 'coverage',
  },
};

export const coverageContent = [
  {
    title: 'Primary Liability',
    details: [
      { label: 'Bodily Injury (BI) Limit', key: 'bodilyInjury' },
      { label: 'Property Damage', key: 'propertyDamage' },
      { label: 'Medical Payments', key: 'medicalPayments' },
    ],
  },
  {
    title: 'Uninsured Liability',
    details: [
      { label: 'Bodily Injury (BI) Limit', key: 'uninsuredMotoristBodilyInjury' },
      { label: 'Property Damage', key: 'uninsuredMotoristPropertyDamage' },
      { label: 'Underinsured bodily injury limit', key: 'uninsuredMotoristBodilyInjury' },
    ],
  },
  {
    details: [
      { label: 'Comprehensive Deductible', key: 'comprehensive' },
      { label: 'Collision Deductible', key: 'collision' },
      { label: 'Rental Reimbursement', key: 'rentalReimbursement' },
      { label: 'Towing & Labor', key: 'towing' },
      { label: 'Loan or lease gap', key: 'loanLeaseGap' },
    ],
  },
];

export const basicsSteps: AfiStepType = {
  name: {
    name: 'name',
    header: 'Tell us about yourself',
    Img: AfiImages,
    buttonTxt: 'Next',
    customStepSubmit: (navigationObject) => disableFirstDriverFields(navigationObject),
    formSections: [{ fields: [firstName, lastName, emailAddress] }],
  },
  address: {
    name: 'address',
    header: 'What’s your mailing address?',
    buttonTxt: 'Next',
    onSubmitModifyOptions: (navigationObject, newValues) => updateCoverageSteps(navigationObject, newValues),
    formSections: [
      { fields: [address] },
      { title: 'Do you own this home?', fields: [driverResidence] },
      { title: 'Is this where you park your cars?', fields: [sameParkingAddress] },
      { title: 'Parking address', fields: [parkingAddress] },
    ],
  },
};

// Optional Steps
export const incidentsStep = {
  name: 'incidents',
  header: 'Tickets & Accidents',
  subHeader: 'Have you had any tickets, accidents, or filed any auto claims in the last 5 years?',
  buttonTxt: 'Next',
  formSections: [
    {
      title: 'Incident date',
      fields: [incidentDate],
    },
    {
      title: 'Violation type',
      fields: [incidentDescription],
    },
  ],
  stepSummary: 'incidents-summary',
  stepLimit: 4,
};

export const driversSteps: AfiStepType = {
  details: {
    name: 'details',
    header: 'Driver Details',
    subHeader: 'You’ll be able to add more drivers in the next step.',
    buttonTxt: 'Next',
    formSections: [
      { title: 'Driver name', fields: [driverFirstName, driverLastName] },
      { title: 'Date of birth', fields: [driverDOB] },
      { title: 'Phone Number', fields: [homePhone] },
      { title: 'Gender', fields: [driverGender] },
      { title: 'Relationship to policy holder', fields: [driverRelationshipToInsured] },
      { title: 'Marital status', fields: [maritalStatus] },
      { title: 'Age licensed', fields: [driverAgeLicensed] },
      { title: 'License status', fields: [driverLicenseStatus] },
      { title: 'License state', fields: [driverLicenseState] },
      { title: 'Education', fields: [driverEducation] },
      { title: 'Ocupation', fields: [driverOccupation] },
    ],
  },
  discounts: {
    name: 'discounts',
    header: 'Eligible for any discounts?',
    subHeader: 'Select all of the discounts you think you may qualify for.',
    infoIcon: true,
    infoContent: {
      title: 'Driver discounts',
      content: [
        {
          subtitle: 'AAA Member discount',
          description: 'For AAA members',
        },
        {
          subtitle: 'Driver training discount',
          description: 'For drivers under 19 that have taken a driver training course',
        },
        {
          subtitle: 'Defensive driver discount',
          description:
            'For those with no accidents in the last 35 months and have completed a state approved driving course',
        },
        {
          subtitle: 'Good student discount',
          description: 'Eligible for students with a B average or better',
        },
        {
          subtitle: 'Student away from home',
          description:
            'Eligible if you have a student living over 100 miles from home and is not regularly driving any car on the policy',
        },
      ],
    },
    buttonTxt: 'Next',
    formSections: [
      {
        fields: [
          driverAAAMember,
          driverTrainingDiscount,
          driverDefensiveDiscount,
          driverGoodStudentDiscount,
          driverStudentLivingAway,
        ],
      },
      {
        title: 'Defensive driver course date',
        fields: [defensiveDriverCourseDate],
      },
    ],
  },
  history: {
    name: 'history',
    header: 'Driver History',
    buttonTxt: 'Next',
    customStepSubmit: (navigationObject, newValues) => toggleDriverSteps(navigationObject, newValues),
    formSections: [
      {
        title: 'Tickets & Accidents',
        description: 'Have you had any tickets, accidents, or filed any auto claims in the last 5 years?',
        fields: [driverIncidents],
      },
      {
        title: 'Do you need an SR-22 or FR-44?',
        description: 'SR-22 and FR-44 pertain to DUI and license suspensions.',
        fields: [driverSR22],
      },
      { title: 'Filing state', fields: [driverSR22FilingState] },
    ],
  },
  incidents: incidentsStep,
};

export const carsSteps: AfiStepType = {
  details: {
    name: 'details',
    header: 'Tell us about your car',
    buttonTxt: 'Next',
    formSections: [
      { title: 'The basics', fields: [vehicleYear, make, model] },
      { title: 'Usage', fields: [ownership, usage, annualMileage] },
      { title: 'Purchase Date', fields: [purchaseDate] },
      { title: 'Who is the principal operator?', fields: [principalOperator] },
      { title: 'Who is the car registered to?', fields: [registeredTo] },
      {
        title: 'Any significant damage?',
        fields: [existingDamage],
      },
      {
        title: 'Any security features?',
        description: 'Some carriers offer discounts for these items',
        fields: [carAlarm, disablingDevice, recoveryDevice, steeringLock],
      },
      { title: 'Will this car be used for ride sharing?', fields: [rideSharing] },
      { title: 'Is this car a salvaged title?', fields: [salvagedTitle] },
    ],
  },
};

export const coverageSteps: AfiStepType = {
  existing: {
    name: 'existing',
    header: 'Tell us about your most recent auto insurance policy',
    subHeader:
      'This will help us provide accurate quotes based on your previous coverage. You can find this information on your policy’s declarations page.',
    buttonTxt: 'Next',
    formSections: [
      { title: 'Insurance Carrier', fields: [insuranceCarrier] },
      { title: 'Policy Information', fields: [premium, paymentFrequency] },
      { title: 'What are your current bodily injury limits?', fields: [bodilyInjuryLimits] },
      { title: 'Have you ever cancelled or not renewed a policy?', fields: [canceledBefore] },
      { title: 'When were you last uninsured?', fields: [insuranceDuration] },
      { title: 'When does your current policy expire?', fields: [priorPolicyExpDate] },
    ],
  },
  credit: {
    name: 'credit',
    header: 'Select your credit score',
    Img: AfiImages,
    buttonTxt: 'Next',
    disclaimer:
      'I authorize my selected insurer to obtain my credit report and/or credit score(s) if required in the underwriting process',
    formSections: [
      {
        fields: [creditScore],
      },
    ],
  },
  'new-coverage': {
    name: 'new-coverage',
    header: 'Your coverage',
    subHeader:
      'We recommend the following coverage based on your information. Continue with the recommended package or edit your coverage amounts.',
    buttonTxt: 'Next',
    formSections: [
      {
        title: 'Primary Liability',
        fields: [primaryBodilyInjuryLimit, primaryPropertyDamage, medPay, pip],
      },
      {
        title: 'Uninsured Liability',
        fields: [uninsuredBodilyInjuryLimit, uninsuredPropertyDamage, underinsuredMotoristsBodilyInjury],
      },
      {
        title: 'Policy Start Date',
        fields: [policyStartDate],
      },
    ],
  },
};

export const AfiNavigation: AfiNavigationType = {
  basics: {
    name: 'basics',
    steps: basicsSteps,
  },
  drivers: {
    name: 'drivers',
    steps: driversSteps,
    limit: 4,
    sectionSummary: 'drivers-summary',
    customSectionSubmit: (navigationObject, newValues) => updateCarsSteps(navigationObject, newValues),
  },
  cars: {
    name: 'cars',
    steps: carsSteps,
    limit: 4,
    sectionSummary: 'cars-summary',
    customSubmitModifyValues: (isCanopy, navigationObject, newValues) =>
      !isCanopy
        ? addCarFieldsToCoverage(navigationObject, newValues)
        : addCanopyCarFieldsToCoverage(navigationObject, newValues),
  },
  coverage: {
    name: 'coverage',
    steps: coverageSteps,
  },
  quotes: {
    name: 'quotes',
    steps: {
      loading: {
        name: 'Loading',
        header: 'Loading',
        buttonTxt: 'Next',
        formSections: [],
      },
    },
  },
};

export const AfiDefaultIndexes: AfiIndexes = {
  drivers: 0,
  cars: 0,
  incidents: 0,
};

// States which don't allow COMP or COLL to be "None" based on certain conditions
export const customCompCollStates = ['IA', 'IL', 'KY', 'KS', 'NE', 'NJ', 'NM', 'PA', 'WA', 'WY'];

export const AFI_DEFAULT_IMAGE =
  'https://www.answerfinancial.com/ContentResponsive/Assets/images/logos/medium/provider';

export const stepsFlow = [
  'start',
  'basics/name',
  'basics/address',
  'drivers/details',
  'drivers/discounts',
  'drivers/history',
  'drivers/drivers-summary',
  'cars/details',
  'cars/cars-summary',
  'coverage/existing',
  'coverage/credit',
  'coverage/new-coverage',
];

// Maximum time between initializing an API call and receiving a server event (2 minutes)
export const canopyTimeoutDuration = 120000;

export const defaultPriorPolicyCoverage = {
  pastProvider: '',
  amount: '',
  priorExpirationDate: '',
  priorInsuranceBodilyInjuryLimits: '',
  vehicles: [
    {
      comprehensiveDeductible: '',
      collisionDeductible: '',
      bodilyInjuryLiability: '',
      uninsuredMotoristsBodilyInjury: '',
      underinsuredMotoristsBodilyInjury: '',
      coveragesLoanLeaseGap: '',
      coveragesRental: '',
      coveragesTowing: '',
      propertyDamageLiability: '',
      medPay: '',
      uninsuredMotoristsPropertyDamage: '',
      pip: '',
      priorInsuranceBodilyInjuryLimits: '',
    },
  ],
};

export const incidentTypes: { [key: string]: string } = {
  AFABDY: 'Accident',
  AFAPDL: 'Accident',
  AFAPDH: 'Accident',
  AFAPPDL: 'Accident',
  AFAPPDH: 'Accident',
  NOTATFAULTACCD: 'Accident',
  SPEEDING1: 'Ticket',
  SPEEDING2: 'Ticket',
  SPEEDING3: 'Ticket',
  SIGNAL: 'Ticket',
  IMPROPTURN: 'Ticket',
  DEFECTIVEEQUIP: 'Ticket',
  SEATBELT: 'Ticket',
  MINOR: 'Ticket',
  NOINSURANCE: 'Ticket',
  SUSPENREVOKE: 'Ticket',
  INTOXIC: 'Ticket',
  MAJOR: 'Ticket',
  ANIMAL: 'Claim',
  NFAPARKED: 'Claim',
  THEFT: 'Claim',
  VAND: 'Claim',
  FIRE: 'Claim',
  GLASS: 'Claim',
  WEATHER: 'Claim',
  COMPREHENSIVE: 'Claim',
  AFABDYL: 'Accident',
  AFABDYH: 'Accident',
  AFAPDAVL: 'Accident',
  AFAPDAVM: 'Accident',
  AFAPDAVH: 'Accident',
  AFAPDOVL: 'Accident',
  AFAPDOVM: 'Accident',
  AFAPDOVH: 'Accident',
};

export const defaultAfiReverseMapping: Array<SectionsNavigationMapping> = [
  {
    sectionName: 'Basics',
    identifier: 'basics',
    steps: [
      {
        name: 'About yourself',
        steps: ['basicsname'],
      },
      {
        name: 'Connect your account',
        steps: ['basicsaddress'],
      },
    ],
  },
  {
    sectionName: 'Drivers',
    identifier: 'drivers',
    steps: [
      {
        name: 'Driver details',
        steps: [
          'driversdetails',
          'driversdiscounts',
          'drivershistory',
          'driversincidents',
          'driversincidents-summary',
        ],
      },
      { name: 'Additional drivers', steps: ['driversdrivers-summary'] },
    ],
  },
  {
    sectionName: 'Your cars',
    identifier: 'cars',
    steps: [],
  },
  {
    sectionName: 'Coverage',
    identifier: 'coverage',
    steps: [
      { name: 'Current policy', steps: ['coverageexisting'] },
      { name: 'Credit', steps: ['coveragecredit'] },
      { name: 'Choose your coverage', steps: ['coveragenew-coverage'] },
    ],
  },
  {
    sectionName: 'Quotes',
    identifier: 'quotes',
    steps: [],
  },
];

export const defaultCanopyReverseMapping: Array<SectionsNavigationMapping> = [
  {
    sectionName: 'Basics',
    identifier: 'basics',
    steps: [
      {
        name: 'About yourself',
        steps: ['basicsname'],
      },
      {
        name: 'Connect your account',
        steps: ['basicsaddress'],
      },
    ],
  },
  {
    sectionName: 'Drivers',
    identifier: 'drivers',
    steps: [
      {
        name: 'Driver details',
        steps: [
          'driversdetails',
          'driversdiscounts',
          'drivershistory',
          'driversincidents',
          'driversincidents-summary',
        ],
      },
      { name: 'Additional drivers', steps: ['driversdrivers-summary'] },
    ],
  },
  {
    sectionName: 'Your cars',
    identifier: 'cars',
    steps: [],
  },
  {
    sectionName: 'Quotes',
    identifier: 'quotes',
    steps: [],
  },
];
