import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import FormQuestions from '../../components/common/afi/FormQuestions/FormQuestions';
import SectionsProgressBarDesktop from '../../components/common/progress-bar/SectionsProgressBarDesktop/SectionsProgressBarDesktop';
import AfiStepComponent from '../../components/common/step-component/afi-step-component/AfiStepComponent';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import { UserContext } from '../../context/UserContext';
import { updateDriverDetails } from '../../services/helpers/AFICustomNavigation';
import {
  dynamicFieldsValidation,
  getDynamicFields,
  toggleDisabledDriverFields,
} from '../../services/helpers/AFIHelpers';
import { formikConfigBuilder } from '../../utils/afi/formik/formikConfigBuilder';
import { address, parkingAddress } from '../../utils/afi/questions/address';
import './afi.scss';
import { canopyLinkUser } from '../../api/apiFunctions';
import { defaultAfiReverseMapping, defaultCanopyReverseMapping } from '../../constants/AFIConstants';

const AfiStepFactory = ({
  sectionId,
  stepId,
  onSubmit,
}: {
  sectionId: string;
  stepId: string;
  onSubmit: (newValues: { [key: string]: string }) => any;
}) => {
  const { partnerName, device, isMobile, setShowErrorBanner } = useContext(UserContext);
  const {
    getAfiAnswerVariables,
    setAfiAnswerVariables,
    AfiNavigation,
    getUserData,
    indexes,
    createNewAfiUser,
    setVehicleMake,
    isCanopy,
    canopyPullId,
  } = useContext(AnswerFinancialContext);

  const [isLoading, setIsLoading] = useState(false);

  const variablePath: Array<string | number> = [sectionId];
  indexes[sectionId] !== undefined && variablePath.push(indexes[sectionId]);
  variablePath.push(stepId);
  indexes[stepId] !== undefined && variablePath.push(indexes[stepId]);

  const { header, subHeader, Img, infoIcon, infoContent, buttonTxt, disclaimer, formSections } =
    AfiNavigation[sectionId].steps[stepId];

  const formSectionsCopy = formSections.filter((elem) => elem !== undefined);
  const validateOnChange = formSectionsCopy.find(
    (item: any) => item.fields.includes(address) || item.fields.includes(parkingAddress),
  )
    ? false
    : true;
  const formikConfig = formikConfigBuilder(
    formSectionsCopy.map((section: any) => section.fields).flat(),
    validateOnChange,
  );

  useEffect(() => {
    if (sectionId === 'drivers' && stepId === 'details') {
      toggleDisabledDriverFields(formSections, indexes.drivers);
    }
    const prefilledData = getAfiAnswerVariables(variablePath);
    // Check if the context data is different from the default values
    if (!_.isEqual(prefilledData, formikConfig.values)) {
      // If so, set the form values to the context data
      formikConfig.setValues(
        prefilledData,
        // Look into address validation, this should be true at all times (address component messes this up, all others are fine)
        stepId === 'address' ? false : true,
      );

      if (sectionId === 'cars' && stepId === 'details') {
        setVehicleMake(prefilledData?.vehicleMake);
      }

      // Highlight any wrong fields, allowing the user to see all errors without having to manually find them
      formikConfig.setTouched(
        Object.keys(formikConfig.initialValues).reduce((acc: any, current) => {
          acc[current] = true;
          return acc;
        }, {}),
      );
    }
    // Highlighting incomplete fields from the canopy flow.
    // We shouldn't highlight fields from pages which belong to other than the first driver/car or incident pages.
    if (isCanopy && indexes[sectionId] === 0 && stepId !== 'incidents') {
      formikConfig.setTouched(
        formSections
          .map((section: any) => section.fields)
          .flat()
          .map((item) => item.name)
          .reduce((acc: any, current) => {
            acc[current] = true;
            return acc;
          }, {}),
      );
    }
  }, [stepId]);

  const formContent = {
    header: header,
    subHeader: subHeader || null,
    Img: Img && isMobile ? Img(stepId as string, partnerName, device) : null,
    infoIcon: infoIcon,
    buttonTxt: buttonTxt,
    disabledButton: dynamicFieldsValidation(formikConfig, stepId),
    onSubmit: async () => {
      if (stepId === 'name' && 'emailAddress' in formikConfig.values) {
        updateDriverDetails(
          getAfiAnswerVariables(['drivers', 0, 'details']),
          setAfiAnswerVariables,
          formikConfig.values,
        );
        if (!isCanopy) {
          setIsLoading(true);
          try {
            await getUserData(formikConfig.values.emailAddress as string);
          } finally {
            setIsLoading(false);
          }
        }
      }
      setAfiAnswerVariables(formikConfig.values, variablePath);
      if (sectionId === 'drivers' && stepId === 'details' && indexes.drivers === 0) {
        setIsLoading(true);
        try {
          const newUser = await createNewAfiUser(formikConfig.values);
          if (canopyPullId && newUser) {
            await canopyLinkUser(canopyPullId, newUser.data.token);
          }
        } catch {
          setShowErrorBanner(true);
          return;
        } finally {
          setIsLoading(false);
        }
      }
      onSubmit(formikConfig.values);
    },
    formSections: formSections.map((section: any, index: number) => {
      if (section === undefined) {
        return;
      }
      //Don't render the FormQuestion component unless there is at least one field, otherwise the title and description will still be displayed
      return getDynamicFields(formikConfig, stepId, section) ? (
        <></>
      ) : (
        section.fields.length > 0 && formikConfig.values && (
          <FormQuestions
            key={index}
            formTitle={section.title}
            description={section.description}
            questions={section.fields}
            info={section.info}
            formikConfig={formikConfig}
          />
        )
      );
    }),
    disclaimer: disclaimer,
  };

  return (
    <div className="afi-step-container">
      {isMobile ? (
        <AfiStepComponent
          header={formContent.header}
          subHeader={formContent.subHeader}
          image={formContent.Img}
          buttonTxt={formContent.buttonTxt}
          onSubmit={formContent.onSubmit}
          fields={formContent.formSections}
          disabledButton={formContent.disabledButton}
          infoIcon={formContent.infoIcon || null}
          infoContent={infoContent || null}
          disclaimer={formContent.disclaimer}
          isLoading={isLoading}
        />
      ) : (
        <div className="afi-step-wrapper">
          <SectionsProgressBarDesktop
            sections={Object.keys(AfiNavigation)}
            sectionsMapping={ isCanopy? defaultCanopyReverseMapping : defaultAfiReverseMapping}
            currentStep={stepId}
            currentSection={sectionId}
          />
          <AfiStepComponent
            header={formContent.header}
            subHeader={formContent.subHeader}
            image={formContent.Img}
            buttonTxt={formContent.buttonTxt}
            onSubmit={formContent.onSubmit}
            fields={formContent.formSections}
            disabledButton={formContent.disabledButton}
            infoIcon={formContent.infoIcon || null}
            infoContent={infoContent || null}
            disclaimer={formContent.disclaimer}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default AfiStepFactory;
