import { useState, useContext } from 'react';
import './auto.scss';
import { introContent, introListText, zipCodeLimit } from './constants';
import InputField from '../../components/common/forms/InputField/InputField';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { getNumberInputs } from '../../services/helpers/inputFieldHelpers';
import { AutoInsuranceContext } from '../../context/AutoInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getStateByZip } from '../../services/helpers/CommonHelpers';
import { introImages } from '../../constants/AutoConstants';
import { stringsObject } from '../../components/interfaces/InputInterfaces';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { getAutoDisclaimerText, setAutoAddress } from '../../services/helpers/AutoHelpers';
import { yupMessages } from '../../services/yupValidations';
import { afiStates, transparentlyOnlyPartners } from '../../constants/CommonConstants';
import BulletList from '../../components/common/bullet-list/BulletList';

const AutoIntro = () => {
  const { zip, setZip, updateAutoInsuranceStep, setStepAnimationType, setShortState, setCity } =
    useContext(AutoInsuranceContext);
  const { partnerName, device, headerContent, setShowDisclaimer, showDisclaimer } = useContext(UserContext);
  const [errors, setErrors] = useState<stringsObject | null>(null);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    setErrors(null);

    if (getNumberInputs(e.target.value) && e.target.value.length <= zipCodeLimit) {
      setZip(e.target.value);
    }
  };

  const handleClick = async () => {
    const getState = getStateByZip(zip);
    if (getState.value === 'error') {
      setErrors({ zip: yupMessages.zipCodeInvalid });
      return;
    }

    if (afiStates.includes(getState.value) && !transparentlyOnlyPartners.includes(partnerName)) {
      navigate('/full-quote-auto/import/current-insurer');
      return;
    } else {
      const res = await setAutoAddress(zip, setErrors, setShortState, setCity, setZip, setStepAnimationType);
      if (res) {
        updateAutoInsuranceStep('model');
        navigate('/auto/model');
        return;
      }
    }
    setErrors({ zip: yupMessages.zipCodeInvalid });
    return;
  };

  return (
    <div className="auto-intro-page">
      <VerticalLayout
        {...introContent}
        headerContent={headerContent}
        showInfoIcon={true}
        img={introImages(partnerName, device)}
        onIconClick={() => setShowDisclaimer(true)}
        content={
          <div className="auto-intro-content">
            <BulletList items={introListText} />
            <div className="auto-intro-fields">
              <InputField
                placeholder="Enter Zip Code"
                type="tel"
                name="zip"
                value={zip}
                onChange={handleInputChange}
                onClick={handleClick}
                errors={errors}
                setErrors={setErrors}
                required={true}
                enableSubmit={zip.length === zipCodeLimit}
                className="with-banner"
              />
              <PrimaryButton
                text="Start Saving"
                onClick={handleClick}
                disabled={!(zip.length === zipCodeLimit)}
              />
            </div>
          </div>
        }
      />
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          content={getAutoDisclaimerText(partnerName)}
          subtitle={'Disclaimer'}
        />
      )}
    </div>
  );
};

export default AutoIntro;
