import { useEffect, useState } from 'react';
import './side-progress-bar.scss';
import CheckMark from '../../../../assets/common/white-check.svg';
import ChevronDown from '../../../../assets/common/chevron-down.svg';
import ChevronUp from '../../../../assets/common/chevron-up.svg';
import { SectionsNavigationMapping, SectionsNavigationStep } from '../../../interfaces/CommonInterfaces';
import _ from 'lodash';

type SideProgressBarProps = {
  sections: string[];
  sectionsMapping: Array<SectionsNavigationMapping>;
  currentStep: string;
  currentSection: string;
  fillSection?: string | null;
};

/**
 * Component that generates a desktop progress bar with both sections and steps,
 * as implemented first in the Answer Financial flow. To be used together with SectionsProgressBarMobile
 * @param sections Array of strings. An array containing section names
 * @param sectionsMapping Array of objects. An array of objects that describe the sections and what steps they contain
 * @param currentStep String.
 * @param currentSection String.
 * @param fillSection String or Null. Name of the section you want to mark as complete while still in it. Example: "Basics"
 * section is always "complete" in the Canopy steps of the Answer Financial Flow.
 */

const SectionsProgressBarDesktop = ({
  sections,
  sectionsMapping,
  currentStep,
  currentSection,
  fillSection = null,
}: SideProgressBarProps) => {
  const currentSectionIndex = sections.indexOf(currentSection);
  const toggleInit: { [key: string]: boolean } = {};
  sections.forEach((elem) => (toggleInit[elem] = currentSection === elem));
  const [toggleStatus, setToggleStatus] = useState<{ [key: string]: boolean }>(toggleInit);

  const getStepStatus = (sectionStatus: string, foundStep: boolean, fields: Array<string>) => {
    if (sectionStatus === 'complete') {
      return 'complete';
    }

    if (sectionStatus === 'started') {
      if (fillSection && fillSection === currentSection) {
        return 'complete';
      }

      if (fields.indexOf(`${currentSection}${currentStep}`) != -1) {
        return 'started';
      }
      if (!foundStep) {
        return 'complete';
      }
      if (foundStep) {
        return 'empty';
      }
    }
    return 'empty';
  };

  useEffect(() => {
    const tmp: { [key: string]: boolean } = {};
    sections.forEach((elem) => (tmp[elem] = currentSection === elem));
    setToggleStatus(tmp);
  }, [currentSection]);

  const getSectionSteps = (steps: Array<SectionsNavigationStep>, sectionStatus: string) => {
    let foundStep = false;
    return (
      <div className="section-steps-container">
        {steps.map((step) => {
          if (step.steps.indexOf(`${currentSection}${currentStep}`) != -1 && !foundStep) {
            foundStep = true;
          }
          return (
            <div key={step.name} className="section-steps-element">
              {progressCircle(getStepStatus(sectionStatus, foundStep, step.steps))}
              {step.name}
            </div>
          );
        })}
      </div>
    );
  };

  const getSectionStatus = (index: number, currentIndex: number) => {
    let fillSectionIndex = fillSection ? sections.indexOf(fillSection) : null;
    if (fillSection && index === fillSectionIndex) {
      return 'complete';
    }

    return index < currentIndex ? 'complete' : index === currentIndex ? 'started' : 'empty';
  };

  const progressCircle = (status: string) => {
    return <div className={`progress-circle circle-${status}`}>{status === 'complete' && <CheckMark />}</div>;
  };

  const toggleSection = (section: string) => {
    const tmp = { ...toggleStatus };
    tmp[section] = !tmp[section];
    setToggleStatus(tmp);
  };

  return (
    <div className="side-progress-bar-wrapper">
      {sectionsMapping.map((section, index) => {
        const sectionStatus = getSectionStatus(index, currentSectionIndex);
        return (
          <div key={section.identifier} className="side-progress-bar-section">
            <div className="side-progress-bar-header">
              {progressCircle(sectionStatus)}
              <div className="side-progress-section-content">
                {section.sectionName}
                {section.steps.length > 0 && (
                  <div className="side-progress-section-expand">
                    {toggleStatus[section.identifier] ? (
                      <ChevronUp onClick={() => toggleSection(section.identifier)} />
                    ) : (
                      <ChevronDown onClick={() => toggleSection(section.identifier)} />
                    )}
                  </div>
                )}
              </div>
            </div>
            {section.steps.length > 0 &&
              toggleStatus[section.identifier] &&
              getSectionSteps(section.steps, sectionStatus)}
          </div>
        );
      })}
    </div>
  );
};
export default SectionsProgressBarDesktop;
