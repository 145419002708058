import './quote-info.scss';
import ChevronLeft from '../../../../assets/common/chevron-left.svg';
import { formatAFIPriceString } from '../../../../services/helpers/AFIHelpers';
import { coverageContent } from '../../../../constants/AFIConstants';
import QuoteInfoCard from './QuoteInfoCard';
import PrimaryButton from '../../buttons/PrimaryButton';
import { useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { AfiQuoteType } from '../../../interfaces/AFIInterfaces';

type QuoteInfo = {
  setShowModal: Function;
  product: AfiQuoteType;
  logo: string;
};

const QuoteInfo = ({ setShowModal, product, logo }: QuoteInfo) => {
  const { isMobile, device } = useContext(UserContext);
  const navigate = useNavigate();

  const buttonContainer = (
    <section className="afi-quotes-info-button-container">
      <a href={product?.insurer?.returnUrl} target="_blank" rel="noreferrer noopener">
        <PrimaryButton text="View Policy" />
      </a>
    </section>
  );

  return (
    <div className="afi-quote-info-container">
      {device !== 'desktop' && (
        <div
          className="afi-quote-info-back-button"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <ChevronLeft />
          back
        </div>
      )}
      <div className="afi-quotes-info-content">
        <section className="afi-quotes-description">
          <div className="afi-quotes-info-title" style={{ backgroundImage: `url(${logo || ''})` }}>
            {!logo && product?.insurer?.name}
          </div>
          <div className="afi-quotes-price">
            <span className="price">
              {formatAFIPriceString(product?.policyInfo?.fullAmount, product?.policyInfo?.duration)}
            </span>
            <span className="description"> monthly</span>
          </div>
          <ul className="afi-quotes-bullet-list">
            {[
              'Take advantage of Safe Driver',
              'Fast, easy and reliable claims and service available',
              'Bundle your auto and home policies',
            ].map((elem: string, index: number) => (
              <li key={index}>
                <span key={`${index}-${elem}`}>{elem}</span>
              </li>
            ))}
          </ul>
        </section>
        {!isMobile && buttonContainer}
        <section className="afi-quotes-info-cards-container">
          {coverageContent.map((elem, index) => (
            <QuoteInfoCard
              key={index}
              title={elem.title}
              details={elem.details}
              products={product.vehicles}
            />
          ))}
        </section>
        {isMobile && buttonContainer}
      </div>
    </div>
  );
};

export default QuoteInfo;
