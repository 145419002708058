import { useContext, useState } from 'react';
import Section from '../../../section/Section';
import { useNavigate } from 'react-router-dom';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import { isSectionComplete } from '../../../../../services/helpers/ConciergeHelpers';
import './asset-details.scss';
import NetWorth from './NetWorth/NetWorth';
import MultipleOptionsSection from './MultipleOptions/MultipleOptionsSection';
import { aircraftItems, valuableItems, watercraftItems } from '../../../../../constants/ConciergeConstants';
import BusinessAssets from './BusinessAssets/BusinessAssets';
import Vehicles from './Vehicles/Vehicles';
import Homes from './Homes/Homes';

const AssetDetails = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState('slide-open');
  const { userAnswers, setUserAnswers, setLastStep } = useContext(ConciergeContext);
  const assetItems = userAnswers.assetItems;

  const handleAnimation = () => {
    setAnimation('slide-closed');
    setTimeout(() => {
      setAnimation('slide-open');
      setActiveIndex(activeIndex + 1);
    }, 800);
  };

  const navigate = useNavigate();
  // Function passed to each item content
  const onSubmit = (newValues: any) => {
    // Update context values
    setUserAnswers({
      ...userAnswers,
      assets: { ...userAnswers.assets, [items[activeIndex].key]: newValues },
    });
    // Mark section as completed
    setIsComplete((prev) => {
      const newArray = [...prev];
      newArray[activeIndex] = true;
      return newArray;
    });
    // Proceed to the next page (if the current index is the last one) or next index
    if (activeIndex + 1 >= items.length) {
      setLastStep('assets/details');
      navigate('/concierge/existing-coverage');
    } else {
      handleAnimation();
    }
  };

  const items = [
    {
      title: 'Net Worth',
      key: 'netWorth',
      content: <NetWorth onSubmit={onSubmit} />,
    },
    {
      title: 'Residential Property',
      key: 'homes',
      content: <Homes onSubmit={onSubmit}></Homes>,
    },
    {
      title: 'Vehicles',
      key: 'vehicles',
      content: <Vehicles onSubmit={onSubmit} />,
    },
    {
      title: 'Valuables',
      key: 'valuables',
      content: (
        <MultipleOptionsSection
          defaultOptions={valuableItems}
          defaultData={userAnswers.assets.valuables}
          onSubmit={onSubmit}
          title="What type of valuables do you have?"
        />
      ),
    },
    {
      title: 'Watercraft',
      key: 'watercraft',
      content: (
        <MultipleOptionsSection
          defaultOptions={watercraftItems}
          defaultData={userAnswers.assets.watercraft}
          onSubmit={onSubmit}
          title="What type of watercraft do you have?"
        />
      ),
    },
    {
      title: 'Aircraft',
      key: 'aircraft',
      content: (
        <MultipleOptionsSection
          defaultOptions={aircraftItems}
          defaultData={userAnswers.assets.aircraft}
          onSubmit={onSubmit}
          title="What type of aircraft do you have?"
        />
      ),
    },
    {
      title: 'Business Assets',
      key: 'business',
      content: <BusinessAssets onSubmit={onSubmit} />,
    },
  ].filter((item) => assetItems.includes(item.key)); // Will only display relevant items to the user based on the assetItems array (filled on the prior page)

  const [isComplete, setIsComplete] = useState(
    Array.from({ length: items.length }, (_, index) =>
      // Will manually check if each section has been completed based on context values
      // If so, the section is marked as completed using the true flag
      isSectionComplete(items[index].key, userAnswers.assets),
    ),
  );

  return (
    <div className="asset-details-container">
      {items.map((item, index) => {
        return (
          <Section
            key={index}
            title={item.title}
            animation={animation}
            content={item.content}
            isActive={activeIndex === index}
            onEdit={isComplete[index] ? () => setActiveIndex(index) : null}
          />
        );
      })}
    </div>
  );
};

export default AssetDetails;
