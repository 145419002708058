import { useContext, useState } from 'react';
import RadioQuestionField from '../../../forms/RadioQuestionField/RadioQuestionField';
import MultipleOptions from '../../../multiple-options/MultipleOptions';
import './existing-coverage.scss';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import PrimaryButton from '../../../buttons/PrimaryButton';
import AutocompleteWrapper from '../../../autocomplete/Autocomplete';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getInsurerAutocompletePlaceholderText } from '../../../../../constants/CommonConstants';
import { canopyNavigationSteps } from '../../../../../constants/ConciergeConstants';

const ExistingCoverage = () => {
  const { userAnswers, setUserAnswers, carrierList, setIsCanopy, setLastStep, steps, setSteps } = useContext(ConciergeContext);
  const defaultAnswers = { ...userAnswers?.coverage?.existingCoverage };

  const [dataImport, setDataImport] = useState<boolean | null>(userAnswers?.coverage?.dataImport);
  const [selectInsurer, setSelectInsurer] = useState(userAnswers?.coverage?.carrier || '');
  const [coverageOptions, setCoverageOptions] = useState<{ [key: string]: boolean }>({
    'Home Insurance': defaultAnswers['Home Insurance'],
    'Auto Insurance': defaultAnswers['Auto Insurance'],
    'Valuables Insurance': defaultAnswers['Valuables Insurance'],
    'Term Life Insurance': defaultAnswers['Term Life Insurance'],
    'Whole Life Insurance': defaultAnswers['Whole Life Insurance'],
    'Umbrella Liability Insurance': defaultAnswers['Umbrella Liability Insurance'],
    'Disability Insurance': defaultAnswers['Disability Insurance'],
  });
  const navigate = useNavigate();

  const onCheck = (name: string, value: boolean) => {
    const newObj = { ...coverageOptions } as { [key: string]: boolean };
    Object.keys(newObj).map((item) => {
      if (item === name) newObj[item] = value;
    });
    setCoverageOptions(newObj);
  };

  const handleDataImportChange = (name: string, answerValue: boolean) => setDataImport(answerValue);

  const handleSubmit = () => {
    const newObj = _.cloneDeep(userAnswers);
    const newValues = {
      existingCoverage: coverageOptions,
      carrier: selectInsurer,
      dataImport: dataImport,
    };
    _.set(newObj, ['coverage'], newValues);
    setUserAnswers(newObj);

    setLastStep('existing-coverage');
    localStorage.setItem('lastConciergeStep', 'existing-coverage');
    if (dataImport) {
      setIsCanopy(true);
      steps.splice(5, 0, ...canopyNavigationSteps);
      setSteps(steps);
      navigate('/concierge/existing-coverage/connect-account');
    } else {
      setIsCanopy(false);
      navigate('/concierge/existing-coverage/eligibility');
    }
  };

  const disableButton = () => {
    return selectInsurer === '' || dataImport === null;
  };

  return (
    <div className="coverages-container">
      <h1 className="form-title">Tell us about your existing coverage </h1>

      <h3 className="field-title"> Choose all that apply</h3>
      <div className="coverages-field">
        <MultipleOptions options={coverageOptions} onCheck={onCheck} />
      </div>

      <div className="coverages-field">
        <h3 className="field-title"> What carrier do you use for your auto & home insurance?</h3>
        <AutocompleteWrapper
          placeholder={getInsurerAutocompletePlaceholderText(carrierList.status)}
          value={selectInsurer}
          setValue={setSelectInsurer}
          options={carrierList.data}
          displayValueKey="name"
          disabled={carrierList.status === 'error'}
        />
      </div>
      <div className="coverages-field">
        <RadioQuestionField
          name="dataImport"
          questionText="We can expedite your concierge service when we import data from your existing carriers. Would you like to allow a data import?"
          currentValue={dataImport}
          boldTitle={true}
          onChange={handleDataImportChange}
        />
      </div>
      <PrimaryButton text="Next" onClick={handleSubmit} disabled={disableButton()} />
    </div>
  );
};

export default ExistingCoverage;
