import { errorMessages } from '../validation';
import { FormikErrors } from 'formik';
import { geocodeByAddress } from 'react-places-autocomplete';
import { defaultFlowTitles, partnerImages } from '../../constants/CommonConstants';
import { BooleanObject } from '../../components/interfaces/InputInterfaces';
import { FamilyMemberInterface, UserInterface } from '../../components/interfaces/CommonInterfaces';
import { createMember, getFamily, updateMemberDetails, updateUser } from '../../api/apiFunctions';
import { RefObject } from 'react';
import { AFLAC_DEVICES } from '../../pages/aflac/constants';

// function to change isoDate to mm/dd/yyyy
export const formatDate = (fullDate: string) => {
  let dateArray = fullDate.slice(0, 10).split('-');
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const capitalizeName = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getAgeFromBirthday = (dob: string) => {
  const today = new Date();
  const birthday = new Date(dob.replaceAll(/[-]/g, '/'));
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }

  return age;
};

export const isValidAge = (dob: string) => {
  const today = new Date(dob);

  const minDobfor18YearOld = new Date();
  minDobfor18YearOld.setFullYear(minDobfor18YearOld.getFullYear() - 18);

  const maxDobfor78YearOld = new Date();
  maxDobfor78YearOld.setFullYear(maxDobfor78YearOld.getFullYear() - 78);

  return today >= maxDobfor78YearOld && today <= minDobfor18YearOld;
};

export const validateAge = (dob: string, errors: FormikErrors<{ dob: string }>, setErrors: Function) => {
  //check if dob is greater than 18 and less than 78
  const isValid = isValidAge(dob);
  dob.length < 10 && setErrors({ dob: '' });
  dob.length === 10 &&
    !isValid &&
    errors.dob !== 'Invalid Date' &&
    setErrors({ ...errors, dob: errorMessages.age });
};

type AddressType = {
  long_name: string;
  short_name: string;
  types: string[];
};

type AddressSectionObject = {
  [key: string]: AddressType;
};

export const getAddressInfo = async (address: string) => {
  try {
    let addressResp = await geocodeByAddress(address);
    let addressItems = addressResp[0].address_components;

    const addressInfo = {} as AddressSectionObject;
    for (let i = 0; i < addressItems.length; i++) {
      var component = addressItems[i];
      addressInfo[component.types[0]] = component;
    }
    return addressInfo;
  } catch (e) {}
};

export const formatPrice = (price: string | number) => {
  return typeof price === 'string' ? parseFloat(price).toFixed(2).toString() : price.toFixed(2).toString();
};

export const getProgressBars = (totalSteps: number, currentStep: number) => {
  return Array(totalSteps)
    .fill('')
    .map((_, i) => {
      return i + 1 <= currentStep ? 'filled' : '';
    });
};

export const getPartnerName = (partnerName: string) => {
  //update as new partners are added
  switch (partnerName) {
    case 'carshield':
    case 'car_shield':
    case 'car-shield':
      return 'carshield';
    default:
      return 'default';
  }
};

export const getHeaderContent = (partnerName: string, device: string) => {
  switch (partnerName) {
    case 'carshield':
    case 'grid':
      return partnerImages(partnerName, device);
    default:
      break;
  }
};

// FormikTest
export const isAgeInRange = (dob: string | undefined, minAge: number, maxAge: number) => {
  if (!dob) {
    return false;
  }
  const currentYear = new Date().getFullYear();
  return currentYear - parseInt(dob.slice(6)) >= minAge && currentYear - parseInt(dob.slice(6)) <= maxAge;
};

export const formatSSN = (value: string) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{3})/, '$1-');
  value = value.replace(/-(\d{2})/, '-$1-');
  value = value.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return value;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'December',
];

export function getNextMonthInfo() {
  let date = new Date();
  date = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  const lastDay = date.getDate();
  return {
    lastDay: `${lastDay}${lastDay === 31 ? 'st' : 'th'}`,
    nextMonth: months[date.getMonth()],
  };
}

export const getMonthlyPayment = (memberAmount: number) => {
  return memberAmount === 0 ? '$3.00' : '$4.15';
};

export const allUsersValid = (errorsObj: BooleanObject) => {
  return Object.values(errorsObj).every((value) => value === false);
};

export const isMemberValid = (relation: string, age: number) => {
  // // check if spouse age is between 18 and 70
  if (relation === 'spouse' && (age < 18 || age > 70)) {
    return { isValid: false, errorMessage: 'Spouse must be between 18 and 70' };
  }
  // // check if child is above 26
  if (relation === 'child' && age > 26) {
    return { isValid: false, errorMessage: 'Children cannot be older than 26' };
  }
  return { isValid: true, errorMessage: '' };
};

const handleUpdateUser = async (updatedFields: { [key: string]: string }, authToken: string) => {
  return await updateUser(updatedFields, authToken);
};

export const createFamilyMember = async (member: FamilyMemberInterface, authToken: string) => {
  // handle all member updates
  let payload = {
    firstName: member?.firstName,
    lastName: member?.lastName,
    dob: member?.dob,
    gender: member?.gender,
    kinship: member?.kinship,
  };
  return await createMember(payload, authToken);
};

export const updateFamilyMember = async (id: string, member: FamilyMemberInterface, authToken: string) => {
  let payload = {
    firstName: member?.firstName,
    lastName: member?.lastName,
    dob: member?.dob,
    gender: member?.gender,
    kinship: member?.kinship,
  };
  return await updateMemberDetails(id, payload, authToken);
};

export const handleFamilyUpdates = async (
  users: (FamilyMemberInterface | UserInterface)[],
  authToken: string,
) => {
  const promises: any[] = [];
  users.forEach((user) => {
    if (!user.ssn) {
      // if its a new member (if id starts with TEMP), create create
      if (!user.id) {
        promises.push(createFamilyMember(user as FamilyMemberInterface, authToken));
      } else {
        promises.push(updateFamilyMember(user.id, user as FamilyMemberInterface, authToken));
      }
    } else {
      promises.push(
        handleUpdateUser(
          {
            ssn: user.ssn,
            phone: user.phone,
            firstName: user.firstName,
            lastName: user.lastName,
            dob: user.dob,
            gender: user.gender,
          },
          authToken,
        ),
      );
    }
  });
  const resp = await Promise.allSettled(promises);
  return resp;
};

export const getFamilyMembers = async (token: string) => {
  let resp = await getFamily(token);
  if (resp.data) {
    let { data } = resp;
    // format dob
    data.forEach((member: { dob: string }) => {
      member.dob = formatDate(member.dob);
    });
    return data;
  }
  return [];
};

export const getProgressStep = (steps: string[], stepId: string) => {
  return steps.indexOf(stepId);
};

export const getDisplaySSN = (ssn: string) => {
  if (!ssn) {
    return '';
  }
  const last4ofSSN = ssn.substring(ssn.length - 4);
  return `****_**_${last4ofSSN}`;
};

export const capitalizeString = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getHeaderTitle = (partnerName: string, currentFlow: string) => {
  const isShortTitle = partnerName !== 'default' && currentFlow === 'accidental-death-voluntary';
  let types: { [key: string]: string } = {
    'accidental-death': 'AD&D',
    auto: 'Auto',
    'accidental-death-voluntary': 'Voluntary AD&D',
    life: 'Life',
    'answer-financial': 'Auto',
    'full-quote-auto': 'Auto',
    concierge: 'Insurance',
  };

  if (partnerName !== 'default') {
    const formatPartnerName = capitalizeString(partnerName);
    return `${types[currentFlow]} ${isShortTitle ? '' : 'insurance'} for customers of ${formatPartnerName}`;
  }
  return defaultFlowTitles[currentFlow];
};

export const scrollHandler = (action: string, ref: RefObject<HTMLDivElement>) => {
  if (ref.current) {
    ref.current.scrollBy({
      top: action === 'down' ? ref.current.scrollHeight / 8 : -(ref.current.scrollHeight / 8),
      behavior: 'smooth',
    });
  }
};

export const titleCaseString = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const aflacDevice = (deviceWidth: number) => {
  let device;
  if (deviceWidth >= AFLAC_DEVICES.desktop) device = 'desktop';
  if (deviceWidth < AFLAC_DEVICES.desktop) device = 'tablet';
  if (deviceWidth < AFLAC_DEVICES.tablet) device = 'mobile';
  return device;
};

export const dobForRequiredAge = (requiredAge: number) => {
  let today = new Date();
  return today.setFullYear(today.getFullYear() - requiredAge);
};

/**
 * Receives a number and adds it to a date in order to create a time frame.
 * @param numberOfDays - How many days will be added.
 * @param startDate - The start date for the timeframe. If omitted, it will be today by default
 * @returns an altered date.
 */

export const createTimeframe = (numberOfDays: number, startDate?: Date) => {
  const date = startDate || new Date();
  return date.setDate(new Date(date).getDate() + numberOfDays);
};

/**
 * Receives an array of objects and a key, then returns an array of values filtered by the key.
 * @param array - Array of objects.
 * @param key - Desired key to filter the array by.
 * @returns Array of values filtered by key.
 */
export const getArrayField = (array: Array<{ [key: string]: any }>, key: string) => {
  return array.map((item) => {
    return item[key];
  });
};

export const isEmptyObject = (object: { [key: string]: any }) => {
  if (object) return Object.keys(object).length === 0;
};

/**
 * Receives a deeply nested object and a value and will return the path to that value. Ideally used with lodash functions such as _.set() which require a path for assignment.
 * @param obj - Nested object.
 * @param value - Desired value.
 * @returns an array
 */
export const findValuePath: (obj: Object, value: string, path?: any[]) => any = (
  obj: { [key: string]: any },
  value: string,
  path = [],
) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (obj[prop] === value) {
        path.push(prop);
        return path;
      }
      if (Array.isArray(obj[prop]) || typeof obj[prop] === 'object') {
        path.push(prop);
        const foundPath = findValuePath(obj[prop], value, path);
        if (foundPath) {
          return foundPath;
        }
        path.pop();
      }
    }
  }
  return null;
};

/**
 * Function that returns the state of the zip code parameter
 * @param zip - ZIP code, only pass as string
 * @returns object containing the state code and name. Will return value:"error" if the state is not found.
 */
export const getStateByZip = (zip: string) => {
  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zip, 10);

  if (zipcode >= 35004 && zipcode <= 36925) {
    return { value: 'AL', name: 'Alabama' };
  }
  if (zipcode >= 99501 && zipcode <= 99950) {
    return { value: 'AK', name: 'Alaska' };
  }
  if (zipcode >= 85001 && zipcode <= 86556) {
    return { value: 'AZ', name: 'Arizona' };
  }
  if ((zipcode >= 71601 && zipcode <= 72959) || (zipcode >= 75502 && zipcode <= 75502)) {
    return { value: 'AR', name: 'Arkansas' };
  }
  if (zipcode >= 90001 && zipcode <= 96162) {
    return { value: 'CA', name: 'California' };
  }
  if (zipcode >= 80001 && zipcode <= 81658) {
    return { value: 'CO', name: 'Colorado' };
  }
  if ((zipcode >= 6001 && zipcode <= 6389) || (zipcode >= 6401 && zipcode <= 6928)) {
    return { value: 'CT', name: 'Connecticut' };
  }
  if (zipcode >= 19701 && zipcode <= 19980) {
    return { value: 'DE', name: 'Delaware' };
  }
  if (zipcode >= 32004 && zipcode <= 34997) {
    return { value: 'FL', name: 'Florida' };
  }
  if ((zipcode >= 30001 && zipcode <= 31999) || (zipcode >= 39901 && zipcode <= 39901)) {
    return { value: 'GA', name: 'Georgia' };
  }
  if (zipcode >= 96701 && zipcode <= 96898) {
    return { value: 'HI', name: 'Hawaii' };
  }
  if (zipcode >= 83201 && zipcode <= 83876 && zipcode != 83414) {
    return { value: 'ID', name: 'Idaho' };
  }
  if (zipcode >= 60001 && zipcode <= 62999) {
    return { value: 'IL', name: 'Illinois' };
  }
  if (zipcode >= 46001 && zipcode <= 47997) {
    return { value: 'IN', name: 'Indiana' };
  }
  if ((zipcode >= 50001 && zipcode <= 52809) || (zipcode >= 68119 && zipcode <= 68120)) {
    return { value: 'IA', name: 'Iowa' };
  }
  if (zipcode >= 66002 && zipcode <= 67954) {
    return { value: 'KS', name: 'Kansas' };
  }
  if (zipcode >= 40003 && zipcode <= 42788) {
    return { value: 'KY', name: 'Kentucky' };
  }
  if ((zipcode >= 70001 && zipcode <= 71232) || (zipcode >= 71234 && zipcode <= 71497)) {
    return { value: 'LA', name: 'Louisiana' };
  }
  if (zipcode >= 3901 && zipcode <= 4992) {
    return { value: 'ME', name: 'Maine' };
  }
  if (
    (zipcode >= 20331 && zipcode <= 20331) ||
    (zipcode >= 20335 && zipcode <= 20797) ||
    (zipcode >= 20812 && zipcode <= 21930)
  ) {
    return { value: 'MD', name: 'Maryland' };
  }
  if ((zipcode >= 1001 && zipcode <= 2791) || (zipcode >= 5501 && zipcode <= 5544)) {
    return { value: 'MA', name: 'Massachusetts' };
  }
  if (zipcode >= 48001 && zipcode <= 49971) {
    return { value: 'MI', name: 'Michigan' };
  }
  if (zipcode >= 55001 && zipcode <= 56763) {
    return { value: 'MN', name: 'Minnesota' };
  }
  if ((zipcode >= 38601 && zipcode <= 39776) || zipcode == 71233) {
    return { value: 'MS', name: 'Mississippi' };
  }
  if (zipcode >= 63001 && zipcode <= 65899) {
    return { value: 'MO', name: 'Missouri' };
  }
  if (zipcode >= 59001 && zipcode <= 59937) {
    return { value: 'MT', name: 'Montana' };
  }
  if (zipcode >= 27006 && zipcode <= 28909) {
    return { value: 'NC', name: 'North Carolina' };
  }
  if (zipcode >= 58001 && zipcode <= 58856) {
    return { value: 'ND', name: 'North Dakota' };
  }
  if ((zipcode >= 68001 && zipcode <= 68118) || (zipcode >= 68122 && zipcode <= 69367)) {
    return { value: 'NE', name: 'Nebraska' };
  }
  if (zipcode >= 88901 && zipcode <= 89883) {
    return { value: 'NV', name: 'Nevada' };
  }
  if (zipcode >= 3031 && zipcode <= 3897) {
    return { value: 'NH', name: 'New Hampshire' };
  }
  if (zipcode >= 7001 && zipcode <= 8989) {
    return { value: 'NJ', name: 'New Jersey' };
  }
  if (zipcode >= 87001 && zipcode <= 88441) {
    return { value: 'NM', name: 'New Mexico' };
  }
  if ((zipcode >= 10001 && zipcode <= 14975) || zipcode == 6390 || zipcode == 501 || zipcode == 544) {
    return { value: 'NY', name: 'New York' };
  }
  if (zipcode >= 43001 && zipcode <= 45999) {
    return { value: 'OH', name: 'Ohio' };
  }
  if ((zipcode >= 73001 && zipcode <= 73199) || (zipcode >= 73401 && zipcode <= 74966)) {
    return { value: 'OK', name: 'Oklahoma' };
  }
  if (zipcode >= 97001 && zipcode <= 97920) {
    return { value: 'OR', name: 'Oregon' };
  }
  if (zipcode >= 15001 && zipcode <= 19640) {
    return { value: 'PA', name: 'Pennsylvania' };
  }
  if (zipcode >= 300 && zipcode <= 999) {
    return { value: 'PR', name: 'Puerto Rico' };
  }
  if (zipcode >= 2801 && zipcode <= 2940) {
    return { value: 'RI', name: 'Rhode Island' };
  }
  if (zipcode >= 29001 && zipcode <= 29948) {
    return { value: 'SC', name: 'South Carolina' };
  }
  if (zipcode >= 57001 && zipcode <= 57799) {
    return { value: 'SD', name: 'South Dakota' };
  }
  if (zipcode >= 37010 && zipcode <= 38589) {
    return { value: 'TN', name: 'Tennessee' };
  }
  if (zipcode == 73301 || (zipcode >= 75001 && zipcode <= 79999) || (zipcode >= 88510 && zipcode <= 88589)) {
    return { value: 'TX', name: 'Texas' };
  }
  if (zipcode >= 84001 && zipcode <= 84784) {
    return { value: 'UT', name: 'Utah' };
  }
  if ((zipcode >= 5001 && zipcode <= 5495) || (zipcode >= 5601 && zipcode <= 5907)) {
    return { value: 'VT', name: 'Vermont' };
  }
  if (
    (zipcode >= 20040 && zipcode <= 20042) ||
    (zipcode >= 20040 && zipcode <= 20167) ||
    (zipcode >= 22001 && zipcode <= 24658)
  ) {
    return { value: 'VA', name: 'Virginia' };
  }
  if (
    (zipcode >= 20001 && zipcode <= 20039) ||
    (zipcode >= 20042 && zipcode <= 20599) ||
    (zipcode >= 20799 && zipcode <= 20799)
  ) {
    return { value: 'DC', name: 'Washington DC' };
  }
  if (zipcode >= 98001 && zipcode <= 99403) {
    return { value: 'WA', name: 'Washington' };
  }
  if (zipcode >= 24701 && zipcode <= 26886) {
    return { value: 'WV', name: 'West Virginia' };
  }
  if (zipcode >= 53001 && zipcode <= 54990) {
    return { value: 'WI', name: 'Wisconsin' };
  }
  if ((zipcode >= 82001 && zipcode <= 83128) || zipcode == 83414) {
    return { value: 'WY', name: 'Wyoming' };
  }
  return {
    value: 'error',
    name: 'Not found',
  };
};

export const getAddressAutocomplete = async (address: string) => {
  let addressResp = await geocodeByAddress(address);

  if (addressResp) {
    let addressItems = addressResp[0].address_components;

    const componentsByType = {} as any;
    for (let i = 0; i < addressItems.length; i++) {
      var component = addressItems[i];
      componentsByType[component.types[0]] = component;
    }
    const getAddressValue = (type: string, length?: string) => {
      if (componentsByType[type]) {
        if (length === 'short_name') {
          return componentsByType[type].short_name || componentsByType[type].long_name;
        }

        if (length === 'long_name') {
          return componentsByType[type].long_name || componentsByType[type].short_name;
        }

        return componentsByType[type].long_name || componentsByType[type].short_name;
      }
      return '';
    };
    const street = `${
      getAddressValue('street_number') ? getAddressValue('street_number') : getAddressValue('premise')
    } ${getAddressValue('route')}`;
    const city = getAddressValue('locality') || getAddressValue('political');
    const state = getAddressValue('administrative_area_level_1', 'short_name');
    const zip = `${getAddressValue('postal_code')}`;
    return { street, city, state, zip };
  } else {
    return { street: '', city: '', state: '', zip: '' };
  }
};
