// Extra completion check for each section for the assets page
export const isSectionComplete = (sectionName: string, values: any) => {
  const sectionAnswers = values[sectionName];
  switch (sectionName) {
    case 'watercraft':
    case 'valuables':
    case 'aircraft':
    case 'homes':
      return sectionAnswers.length ? true : false;
    case 'netWorth':
      return sectionAnswers.netWorth && sectionAnswers.liquidPercentage ? true : false;
    case 'vehicles':
      return sectionAnswers.vehicles.length && sectionAnswers.membersCount ? true : false;
    case 'business':
      return sectionAnswers.value && sectionAnswers.coOwners ? true : false;
    default:
      return false;
  }
};
