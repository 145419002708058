import './afi.scss';
import SummaryContainer from '../../components/common/afi/SummaryContainer/SummaryContainer';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import {
  carTemplate,
  defaultAfiReverseMapping,
  defaultCanopyReverseMapping,
  driverTemplate,
  incidentTypes,
  summaryPagesContent,
} from '../../constants/AFIConstants';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import _ from 'lodash';
import { maritalStatusOptions } from '../../utils/afi/questions/applicant';
import {
  driverEducationOptions,
  driverGenderOptions,
  driverRelationshipToInsuredOptions,
  incidentDescriptionOptions,
} from '../../utils/afi/questions/driver';
import { vehicleOwnershipOptions, vehicleUsageOptions } from '../../utils/afi/questions/vehicle';
import { UserContext } from '../../context/UserContext';
import SectionsProgressBarDesktop from '../../components/common/progress-bar/SectionsProgressBarDesktop/SectionsProgressBarDesktop';
import {
  getDriverFullName,
  getMissingCarsFields,
  getMissingDriverFields,
} from '../../services/helpers/AFIHelpers';

const SummaryPage = ({
  stepId,
  sectionId,
  onSubmit,
}: {
  stepId: string;
  sectionId: string;
  onSubmit: (newValues: any) => void;
}) => {
  const [content, setContent] = useState([]);
  const { isMobile } = useContext(UserContext);
  const {
    AFIAnswersObject,
    indexes,
    setIndexes,
    setAfiAnswerVariables,
    getAfiAnswerVariables,
    AfiNavigation,
    isCanopy,
  } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();

  const { title, subtitle, extraButtonText, ctaButtonText } = summaryPagesContent[stepId];

  const navigateWithDelay = (route: string) => {
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  useEffect(() => {
    let tmp = [];
    switch (stepId) {
      case 'incidents-summary':
        tmp = AFIAnswersObject['drivers'][indexes.drivers]['incidents'].map((elem: any) => {
          if (elem.incidentDescription) {
            return {
              label:
                elem?.incidentDescription in incidentTypes ? incidentTypes[elem.incidentDescription] : '',
              title: incidentDescriptionOptions[elem.incidentDescription],
              fields: [
                {
                  detailLabel: 'Driver',
                  detail: `${AFIAnswersObject['drivers'][indexes.drivers].details.driverFirstName} ${
                    AFIAnswersObject['drivers'][indexes.drivers].details.driverLastName
                  }`,
                },
                { detailLabel: 'Date', detail: `${elem.incidentDate}` },
              ],
            };
          } else {
            setAfiAnswerVariables(
              AFIAnswersObject.drivers[indexes.drivers].incidents.filter(
                (item: any) => item.incidentDescription !== '',
              ),
              ['drivers', indexes.drivers, 'incidents'],
            );
            let newIndexes = { ...indexes };
            newIndexes.incidents = Math.max(newIndexes.incidents - 1, 0);
            setIndexes(newIndexes);
          }
        });
        break;
      case 'drivers-summary':
        tmp = AFIAnswersObject['drivers'].map((elem: any, index: number) => {
          if (elem.details.driverFirstName) {
            return {
              label: driverRelationshipToInsuredOptions[elem.details.driverRelationshipToInsured],
              title: `${elem.details.driverFirstName} ${elem.details.driverLastName}`,
              isComplete: !getMissingDriverFields(elem).length,
              fields: [
                {
                  detailLabel: 'Date of birth',
                  detail: elem.details.driverDob,
                },
                { detailLabel: 'Gender', detail: driverGenderOptions[elem.details.driverGender] },
                {
                  detailLabel: 'Marital Status',
                  detail: maritalStatusOptions[elem.details.driverMaritalStatus],
                },
                { detailLabel: 'Education', detail: driverEducationOptions[elem.details.driverEducation] },
              ],
            };
          } else {
            setAfiAnswerVariables(
              AFIAnswersObject.drivers.filter((item: any) => item.details.driverFirstName !== ''),
              ['drivers'],
            );
            let newIndexes = { ...indexes };
            newIndexes.drivers = Math.max(newIndexes.drivers - 1, 0);
            setIndexes(newIndexes);
          }
        });
        break;
      case 'cars-summary':
        tmp = AFIAnswersObject['cars'].map((elem: any) => {
          if (elem.details.vehicleMake) {
            return {
              label: '',
              title: `${elem.details.vehicleYear} ${elem.details.vehicleMake} ${elem.details.vehicleModel}`,
              isComplete: !getMissingCarsFields(elem).length,
              fields: [
                {
                  detailLabel: 'Ownership',
                  detail: vehicleOwnershipOptions[elem.details.vehicleOwnership],
                },
                { detailLabel: 'Primary Use', detail: vehicleUsageOptions[elem.details.vehicleUsage] },
                {
                  detailLabel: 'Annual mileage',
                  detail: elem.details.annualMileage,
                },
                {
                  detailLabel: 'Parking Address',
                  detail: AFIAnswersObject['basics']?.address?.address || '',
                },
                {
                  detailLabel: 'Primary Driver',
                  detail:
                    elem.details.principalOperator &&
                    getDriverFullName(AFIAnswersObject, elem.details.principalOperator.slice(-1) - 1),
                },
                { detailLabel: 'Damage', detail: elem.details.existingDamage },
              ],
            };
          } else {
            setAfiAnswerVariables(
              AFIAnswersObject.cars.filter((item: any) => item.details.vehicleMake !== ''),
              ['cars'],
            );
            let newIndexes = { ...indexes };
            newIndexes.cars = Math.max(newIndexes.cars - 1, 0);
            setIndexes(newIndexes);
          }
        });
        break;

      default:
        tmp = _.cloneDeep(AFIAnswersObject['drivers']);
    }
    setContent(tmp?.filter((item: any) => item !== undefined) || []);
  }, [sectionId, stepId]);

  const handleExtraOnClick = () => {
    let newIndexes;
    switch (stepId) {
      case 'incidents-summary':
        const newIncidents = _.cloneDeep(
          getAfiAnswerVariables(['drivers', indexes.drivers, 'incidents']),
        ).concat(_.cloneDeep(driverTemplate.incidents));
        setAfiAnswerVariables(newIncidents, ['drivers', indexes.drivers, 'incidents']);
        newIndexes = { ...indexes };
        newIndexes.incidents = newIncidents.length - 1;
        setIndexes(newIndexes);
        navigateWithDelay('/full-quote-auto/drivers/incidents');

        break;
      case 'drivers-summary':
        const newDrivers = _.cloneDeep(getAfiAnswerVariables(['drivers'])).concat(
          _.cloneDeep(driverTemplate),
        );
        setAfiAnswerVariables(newDrivers, ['drivers']);
        newIndexes = { ...indexes };
        newIndexes.drivers = newDrivers.length - 1;
        newIndexes.incidents = 0;
        setIndexes(newIndexes);
        navigateWithDelay('/full-quote-auto/drivers/details');
        break;
      case 'cars-summary':
        const newCars = _.cloneDeep(getAfiAnswerVariables(['cars'])).concat(_.cloneDeep(carTemplate));
        setAfiAnswerVariables(newCars, ['cars']);
        newIndexes = { ...indexes };
        newIndexes.cars = newCars.length - 1;
        setIndexes(newIndexes);
        navigateWithDelay('/full-quote-auto/cars/details');
        break;
      default:
        break;
    }
  };

  const handleOnEdit = () => {
    switch (stepId) {
      case 'incidents-summary':
        return (index: number) => {
          const indexesTmp = _.cloneDeep(indexes);
          indexesTmp.incidents = index;
          setIndexes(indexesTmp);
          navigateWithDelay('/full-quote-auto/drivers/incidents');
        };
      case 'drivers-summary':
        return (index: number) => {
          const indexesTmp = _.cloneDeep(indexes);
          indexesTmp.drivers = index;
          indexesTmp.incidents = 0;
          setIndexes(indexesTmp);
          navigateWithDelay('/full-quote-auto/drivers/details');
        };
      case 'cars-summary':
        return (index: number) => {
          const indexesTmp = _.cloneDeep(indexes);
          indexesTmp.cars = index;
          setIndexes(indexesTmp);
          navigateWithDelay('/full-quote-auto/cars/details');
        };
      default:
        return (index: number) => {
          const indexesTmp = _.cloneDeep(indexes);
          indexesTmp.drivers = index;
          setIndexes(indexesTmp);
          navigateWithDelay('/full-quote-auto/drivers/details');
        };
    }
  };

  const handleDelete = (index: number) => {
    let indexesTmp = _.cloneDeep(indexes);
    let items = [];
    let newContent = [];
    switch (stepId) {
      case 'incidents-summary':
        indexesTmp.incidents = Math.max(0, indexesTmp.incidents - 1);
        items = _.cloneDeep(getAfiAnswerVariables(['drivers', indexes.drivers, 'incidents']));
        items.splice(index, 1);
        setAfiAnswerVariables(items, ['drivers', indexes.drivers, 'incidents']);
        newContent = _.cloneDeep(content);
        newContent.splice(index, 1);
        setContent(newContent);
        break;
      case 'drivers-summary':
        indexesTmp.drivers = Math.max(0, indexesTmp.drivers - 1);
        indexesTmp.incidents = 0;
        items = _.cloneDeep(getAfiAnswerVariables(['drivers']));
        items.splice(index, 1);
        setAfiAnswerVariables(items, ['drivers']);
        const carItems = _.cloneDeep(getAfiAnswerVariables(['cars']));
        /**
         * When removing a driver go through all the cars:
         * Delete the pincipal operator if it's the driver to delete
         * Decrease the driverId if the driverId > driver to delte id
         * Ex: D1-C1, D2-C2, D3-C3 => Delete D2 => D1-C1, _-C2, D2-C3
         **/
        carItems.forEach((item: any) => {
          if (item?.details?.principalOperator) {
            const currentIndex = parseInt(item?.details?.principalOperator.split('D')[1]);
            if (currentIndex === index + 1) {
              item.details.principalOperator = '';
            } else if (currentIndex > index + 1) {
              item.details.principalOperator = `D${currentIndex - 1}`;
            }
          }
        });
        setAfiAnswerVariables(carItems, ['cars']);
        newContent = _.cloneDeep(content);
        newContent.splice(index, 1);
        setContent(newContent);
        break;
      case 'cars-summary':
        indexesTmp.cars = Math.max(0, indexesTmp.cars - 1);
        items = _.cloneDeep(getAfiAnswerVariables(['cars']));
        items.splice(index, 1);
        setAfiAnswerVariables(items, ['cars']);
        newContent = _.cloneDeep(content);
        newContent.splice(index, 1);
        setContent(newContent);
        break;
      default:
        indexesTmp.drivers = Math.max(0, indexesTmp.drivers - 1);
        items = _.cloneDeep(getAfiAnswerVariables(['drivers']));
        items.splice(index, 1);
        setAfiAnswerVariables(items, ['drivers']);
        newContent = _.cloneDeep(content);
        newContent.splice(index, 1);
        setContent(newContent);
        break;
    }
    setIndexes(indexesTmp);
  };

  return (
    <div className="afi-summary-page-container">
      <div className="afi-summary-page-layout">
        {isMobile ? (
          <div className="afi-summary-content-container">
            <div className="afi-summary-header">
              <h1 className="afi-summary-title">{title}</h1>
              <p className="afi-summary-subtitle"> {subtitle} </p>
            </div>
            <SummaryContainer
              extraButtonText={extraButtonText}
              content={content}
              onEdit={handleOnEdit()}
              onDelete={handleDelete}
              extraOnClick={handleExtraOnClick}
            />
            <PrimaryButton
              text={ctaButtonText}
              onClick={() => onSubmit(AFIAnswersObject)}
              disabled={content.find((item: any) => item.isComplete === false) ? true : false}
            />
          </div>
        ) : (
          <div className="afi-summary-page-wrapper">
            <SectionsProgressBarDesktop
              sections={Object.keys(AfiNavigation)}
              sectionsMapping={isCanopy ? defaultCanopyReverseMapping : defaultAfiReverseMapping}
              currentSection={sectionId}
              currentStep={stepId}
            />
            <div className="afi-summary-content-container">
              <div className="afi-summary-header">
                <h1 className="afi-summary-title">{title}</h1>
                <p className="afi-summary-subtitle"> {subtitle} </p>
              </div>
              <SummaryContainer
                extraButtonText={extraButtonText}
                content={content}
                onEdit={handleOnEdit()}
                onDelete={handleDelete}
                extraOnClick={handleExtraOnClick}
              />
              <PrimaryButton
                text={ctaButtonText}
                onClick={() => onSubmit(AFIAnswersObject)}
                disabled={content.find((item: any) => item.isComplete === false) ? true : false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryPage;
