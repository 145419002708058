export const customStateOptions: {
  [key: string]: {
    [key: string]: { [key: string]: string };
  };
} = {
  default: {
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AIG Agency Auto': 'AIG',
      'AIG Direct': 'AIG Direct',
      Alfa: 'Alfa',
      'Allstate Insurance Co.': 'Allstate Ins Co',
      'American Modern': 'American Modern',
      'Auto Owners Group': 'Auto Owners Group',
      'Cincinnati Financial Group': 'Cincinnati Financial Group',
      'Cotton States': 'Cotton States',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Insurance': 'Farmers Ins',
      Geico: 'Geico',
      Hallmark: 'Hallmark',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Metropolitan (Metlife)': 'Metropolitan',
      'National General': 'GMAC Insurance',
      Nationwide: 'Nationwide',
      'Omni Insurance': 'Omni Insurance',
      'Progressive Casualty': 'Progressive Casualty',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      'State Farm': 'State Farm',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      'US Auto': 'US Auto',
      USAA: 'USAA',
    },
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25,000/50,000': '$25,000/$50,000',
      '30,000/60,000': '$30,000/$60,000',
      '50,000/100,000': '$50,000/$100,000',
      '100,000/300,000': '$100,000/$300,000',
      '250,000/500,000': '$250,000/$500,000',
      '500,000/500,000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'None',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '25,000/50,000': '$25,000/$50,000',
      '30,000/60,000': '$30,000/$60,000',
      '50,000/100,000': '$50,000/$100,000',
      '100,000/300,000': '$100,000/$300,000',
      '250,000/500,000': '$250,000/$500,000',
      '500,000/500,000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    pip: {
      'Not Available': 'Not Available',
    },
  },
  AL: {
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      Alfa: 'Alfa',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Modern': 'American Modern',
      'Auto Owners Group': 'Auto Owners Group',
      'Cincinnati Financial Group': 'Cincinnati Financial Group',
      'Cotton States': 'Cotton States',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      Hallmark: 'Hallmark',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Omni Insurance': 'Omni Insurance',
      'Progressive Casualty': 'Progressive Casualty',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      'US Auto': 'US Auto',
      USAA: 'USAA',
    },
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'None',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  AZ: {
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'None',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'None',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Bristol West': 'Bristol West',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      'Country Companies': 'Country Companies',
      Dairyland: 'Dairyland',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      'GE Capital': 'GE Capital',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Mercury Ins. Co.': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      Sentry: 'Sentry',
      'State Farm': 'State Farm',
      'State National': 'State National',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '15/30': '$15,000/$30,000',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  AR: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '500': '$500',
      '1000': '$1,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '500': '$500',
      '1000': '$1,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '65000': '$65,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    pip: {
      None: 'No Coverage',
      '1000': '$1,000',
      '5000': '$5,000',
      '10000': '$10,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  CA: {
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '100000/300000': '$100,000/$300,000',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '250000/500000': '$250,000/$500,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '5000': '$5,000',
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'None',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'None',
      '3500': '$3,500',
      CWAIV: 'Waiver of Collision Deductible',
    },
    insuranceCarrier: {
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American International': 'American International',
      'Auto Club Nor Cal (AAA/CSAA)': 'Auto Club Nor Cal (AAA/CSAA)',
      'Auto Club So Cal (AAA)': 'Auto Club So Cal (AAA)',
      'Bristol West': 'Bristol West',
      'Civil Service': 'Civil Service',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      Evererst: 'Evererst',
      Explorer: 'Explorer',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'GMAC Insurance': 'National General',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      Hillstar: 'Hillstar',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      Nationwide: 'Nationwide',
      None: 'None',
      Other: 'Other',
      'Pennsylvania General': 'Pennsylvania General',
      'Progressive Casualty': 'Progressive Casualty',
      'Royal &amp; SunAlliance': 'Royal &amp;amp; SunAlliance',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '100/300': '$100,000/$300,000',
      '15/30': '$15,000/$30,000',
      '25/50': '$25,000/$50,000',
      '250/500': '$250,000 or greater',
      '30/60': '$30,000/$60,000',
      '50/100': '$50,000/$100,000',
    },
  },
  CO: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25/50': '$25,000/$50,000',
      '30/60': '$30,000/$60,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000/$500,000',
      '500/500': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '30000': '$30,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '70000': '$70,000',
      '75000': '$75,000',
      '80000': '$80,000',
      '90000': '$90,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '175000': '$175,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '5000': '$5,000',
      '10000': '$100,00',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25/50': ' $25,000/ $50,000',
      '30/60': ' $30,000/ $60,000',
      '50/100': ' $50,000/ $100,000',
      '100/300': ' $100,000/ $300,000',
      '250/500': ' $250,000/ $500,000',
      '500/500': ' $500,000/ $500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '0': 'No Deductible',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '500': '$500',
      '1000': '$1,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Bristol West': 'Bristol West',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      'Colorado FB Mutual': 'Colorado FB Mutual',
      Dairyland: 'Dairyland',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Royal &amp; SunAlliance': 'Royal &amp; SunAlliance',
      Safeco: 'Safeco',
      Shelter: 'Shelter',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  CT: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      Amica: 'Amica',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Foremost: 'Foremost',
      Geico: 'Geico',
      'Grange Mutual': 'Grange Mutual',
      HANOVER: 'HANOVER',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Enumclaw': 'Mutual of Enumclaw',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      PEMCO: 'PEMCO',
      'Progressive Casualty': 'Progressive Casualty',
      'Royal &amp; SunAlliance': 'Royal &amp; SunAlliance',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Winterthur Swiss': 'Winterthur Swiss',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '20/40': '$20,000/$40,000',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  DE: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    insuranceCarrier: {
      None: 'Select One',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Independent': 'American Independent',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      AMERIPRISE: 'AMERIPRISE',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      'Metropolitan (Metlife)': 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
    },
  },
  FL: {
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '10000/20000': '$10,000/$20,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    bodilyInjury_FR44: {
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    propertyDamage_FR44: {
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'None',
      '1000': '$1,000',
      '2000': '$2,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '10000/20000': '$10,000/$20,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'None',
      '10000/20000': '$10,000/$20,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      Amica: 'Amica',
      'Aris Insurance': 'Aris Insurance',
      'Auto Owners Group': 'Auto Owners Group',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Direct Insurance Company': 'Direct Insurance Company',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Foremost: 'Foremost',
      'GE Capital': 'GE Capital',
      Geico: 'Geico',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Mercury Ins. Co.': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Ocean Harbor': 'Ocean Harbor',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      Safeco: 'Safeco',
      'Southern Farm Bureau': 'Southern Farm Bureau',
      'State Farm': 'State Farm',
      'The General (Permanent General)': 'The General (Permanent General)',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      United: 'United',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '10/20': '$10,000/$20,000',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  ID: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  IN: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farm Bureau': 'Farm Bureau',
      'Farmers Ins': 'Farmers Insurance',
      Founders: 'Founders',
      Geico: 'Geico',
      Grange: 'Grange',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      Nationwide: 'Nationwide',
      'Progressive Casualty': 'Progressive Casualty',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'No prior insurance',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  GA: {
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
    },
    medPay: {
      None: 'None',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'None',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      Aggressive: 'Aggressive',
      AIG: 'AIG Agency Auto',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Ins Co',
      'American Family': 'American Family',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Ins',
      Geico: 'Geico',
      Grange: 'Grange',
      'Grange Mutual': 'Grange Mutual',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Mercury Ins. Co.': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Enumclaw': 'Mutual of Enumclaw',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      PEMCO: 'PEMCO',
      'Progressive Casualty': 'Progressive Casualty',
      'Royal &amp; SunAlliance': 'Royal &amp; SunAlliance',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Ins',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Winterthur Swiss': 'Winterthur Swiss',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  IA: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '20000/40000': '$20,000/$40,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '20000/40000': '$20,000/$40,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '20000/40000': '$20,000/$40,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Ins Co',
      'Amco Ins Co': 'Amco Ins Co',
      'American Alliance Ins Co': 'American Alliance Ins Co',
      'American Economy Ins Co': 'American Economy Ins Co',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National Property and Casualty',
      'American Reliable Ins Co': 'American Reliable Ins Co',
      'Amex Assurance Co': 'Amex Assurance Co',
      'Amica Mutual Ins Co': 'Amica Mutual Ins Co',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co',
      'Calfarm Ins Co': 'Calfarm Ins Co',
      'California Automobile Ins Co': 'California Automobile Ins Co',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins Co',
      'California State Auto Assoc': 'California State Auto Assoc',
      'Century National Ins': 'Century National Ins',
      'Chubb Group of Ins Co': 'Chubb Group of Ins Co',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins Co',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Ins Co',
      'Electric Insurance Co': 'Electric Insurance Co',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins Co',
      'Farmers Ins': 'Farmers Ins',
      'Federal Ins Co': 'Federal Ins Co',
      'Financial Indemnity': 'Financial Indemnity',
      'Firemans Fund': 'Firemans Fund',
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins Co',
      'Hanover Ins Co': 'Hanover Ins Co',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins Co',
      'Insurance Co of the West': 'Insurance Co of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': 'Lumbermans Mutual',
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins Co',
      Metropolitan: 'Metropolitan',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'GMAC (National General)',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective InsGroup',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins Co',
      'State Auto Ins Co': 'State Auto Ins Co',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group',
      'Unigard Ins': 'Unigard Ins',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': 'Workmens Auto Insurance',
      'Zurich Ins Group': 'Zurich Ins Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '20000/40000': '20,000/40,000',
      '25000/50000': '25,000/50,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000 or greater',
    },
  },
  IL: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '200000/200000': '$200,000/$200,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '200000/200000': '$200,000/$200,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      Affirmative: 'Affirmative',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Access': 'American Access',
      'American Family': 'American Family',
      'Civil Service': 'Civil Service',
      'Country Companies': 'Country Companies',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Founders: 'Founders',
      Geico: 'Geico',
      Grange: 'Grange',
      Hallmark: 'Hallmark',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      Kingsway: 'Kingsway',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      Nationwide: 'Nationwide',
      Pekin: 'Pekin',
      'Progressive Casualty': 'Progressive Casualty',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      United: 'United',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Warrior Insurance Group': 'Warrior Insurance Group',
      'Winterthur Swiss': 'Winterthur Swiss',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  KY: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25000',
      '35000': '$35000',
      '50000': '$50000',
      '100000': '$100000',
      '150000': '$150000',
      '200000': '$200000',
      '250000': '$250000',
      '500000': '$500000',
      '750000': '$750000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      Alfa: 'Alfa',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Direct Insurance Company': 'Direct Insurance Company',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Foremost: 'Foremost',
      Geico: 'Geico',
      Grange: 'Grange',
      'Grange Mutual': 'Grange Mutual',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Kentucky Farm Bureau Mutual': 'Kentucky Farm Bureau Mutual',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'Motorist mutual': 'Motorist mutual',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Shelter: 'Shelter',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      10000: '$10,000',
      20000: '$20,000',
      30000: '$30,000',
      40000: '$40,000',
      50000: '$50,000',
    },
  },
  KS: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '25,000/$50,000',
      '30000/60000': '30,000/$60,000',
      '50000/100000': '50,000/$100,000',
      '100000/300000': '100,000/$300,000',
      '250000/500000': '250,000/$500,000',
      '500000/500000': '500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Ins Co',
      'Amco Ins Co': 'Amco Ins Co',
      'American Alliance Ins Co': 'American Alliance Ins Co',
      'American Economy Ins Co': 'American Economy Ins Co',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National Property and Casualty',
      'American Reliable Ins Co': 'American Reliable Ins Co',
      'Amex Assurance Co': 'Amex Assurance Co',
      'Amica Mutual Ins Co': 'Amica Mutual Ins Co',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co',
      'Calfarm Ins Co': 'Calfarm Ins Co',
      'California Automobile Ins Co': 'California Automobile Ins Co',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins Co',
      'California State Auto Assoc': 'California State Auto Assoc',
      'Century National Ins': 'Century National Ins',
      'Chubb Group of Ins Co': 'Chubb Group of Ins Co',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins Co',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Ins Co',
      'Electric Insurance Co': 'Electric Insurance Co',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins Co',
      'Farmers Ins': 'Farmers Ins',
      'Federal Ins Co': 'Federal Ins Co',
      'Financial Indemnity': 'Financial Indemnity',
      'Firemans Fund': 'Firemans Fund',
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins Co',
      'Hanover Ins Co': 'Hanover Ins Co',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins Co',
      'Insurance Co of the West': 'Insurance Co of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': 'Lumbermans Mutual',
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins Co',
      Metropolitan: 'Metropolitan',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'GMAC (National General)',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective InsGroup',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins Co',
      'State Auto Ins Co': 'State Auto Ins Co',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group',
      'Unigard Ins': 'Unigard Ins',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': 'Workmens Auto Insurance',
      'Zurich Ins Group': 'Zurich Ins Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      4500: '$4,500',
      12500: '$12,500',
      27500: '$27,500',
    },
  },
  MO: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      'AIG Agency Auto': 'AIG',
      'Allied Group': 'Allied Group',
      'Allstate Insurance Co.': 'Allstate Ins Co',
      'American Family': 'American Family',
      'Auto Club of Missouri': 'Auto Club of Missouri',
      'CGU Group': 'CGU Group',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Electric Insurance Co.': 'Electric Insurance Co',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farm Bureau Group of Iowa': 'Farm Bureau Group of Iowa',
      'Farmers Insurance': 'Farmers Ins',
      'Farmers Insurance Insurance': 'Farmers Insurance Insurance',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Metropolitan (Metlife)': 'Metropolitan',
      'National General': 'GMAC (National General)',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Shelter: 'Shelter',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Insurance': 'Unigard Ins',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '25,000/50,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000 or greater',
    },
  },
  MS: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      Access: 'Access',
      AIG: 'AIG Agency Auto',
      Alfa: 'Alfa',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Direct Insurance Company': 'Direct Insurance Company',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      Guideone: 'Guideone',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mississippi Farm Bureau': 'Mississippi Farm Bureau',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      Safeco: 'Safeco',
      Safeway: 'Safeway',
      Shelter: 'Shelter',
      'State Farm': 'State Farm',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group (Travelers)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  NE: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  NJ: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '150': '$150',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '1500': '$1,500',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '150': '$150',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '1500': '$1,500',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '5000': '$5,000',
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American International': 'American International',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      'First Trenton': 'First Trenton',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      'High Point': 'High Point',
      IFA: 'IFA',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'NJ Manufactures Ins. Co.': 'NJ Manufactures Ins. Co.',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      Palisades: 'Palisades',
      'Plymouth Rock': 'Plymouth Rock',
      Proformance: 'Proformance',
      'Progressive Casualty': 'Progressive Casualty',
      'Selective Ins. Co of America': 'Selective Ins. Co of America',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'No prior insurance',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      15000: '$15,000',
      50000: '$50,000',
      75000: '$75,000',
      150000: '$150,000',
      250000: '$250,000',
    },
  },
  NC: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '30000/60000': '30000/60000',
      '50000/100000': '50000/100000',
      '100000/300000': '100000/300000',
      '250000/500000': '250000/500000',
      '500000/500000': '500000/500000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'No prior insurance',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    incidents: {
      SPEEDING1: 'Speeding less than 10-MPH over limit',
      SPEEDING2: 'Speeding 10-to-20-MPH over limit',
      SPEEDING3: 'Speeding more than 20-MPH over limit',
      SIGNAL: 'Traffic sign violation (run a red light)',
      IMPROPTURN: 'Improper lane change / turn',
      DEFECTIVEEQUIP: 'Equipment failure (burned out light)',
      SEATBELT: 'No seatbelt',
      MINOR: 'Other minor violation (carpool, tail-gating)',
      NOINSURANCE: 'Driving without insurance',
      SUSPENREVOKE: 'Driving with suspended or revoked license',
      INTOXIC: 'Driving under the influence (DUI/DWI)',
      MAJOR: 'Other major violation (reckless driving)',
      AFABDYL: 'At fault Accident with Bodily Injury under $1801',
      AFABDYH: 'At fault Accident with Bodily Injury over $1800',
      AFAPDAVL: 'At fault Accident with Property Damage only to all vehicles, under $2,301',
      AFAPDAVM: 'At fault Accident with Property Damage only to all vehicles, from $2,301 to $3,850',
      AFAPDAVH: 'At fault Accident with Property Damage only to all vehicles, over $3,850',
      AFAPDOVL: 'At fault Accident with Property Damage only to other vehicle, under $2,301',
      AFAPDOVM: 'At fault Accident with Property Damage only to other vehicle, from $2,301 to $3,850',
      AFAPDOVH: 'At fault Accident with Property Damage only to other vehicle, over $3,850',
      NOTATFAULTACCD: 'Accident was ruled "not my fault"',
      ANIMAL: 'Collided with animal',
      NFAPARKED: 'Vehicle hit while parked',
      THEFT: 'My vehicle was stolen',
      VAND: 'My vehicle was vandalized',
      FIRE: 'My vehicle caught fire',
      GLASS: 'Cracked windshield (not damaged in accident)',
      WEATHER: 'Weather-related damage',
      COMPREHENSIVE: 'Other non-accident claim or loss',
    },
  },
  NM: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  NV: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '20000': '$20,000',
      '25000': '$25,000',
      '30000': '$30,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '45000': '$45,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '175000': '$175,000',
    },
    medPay: {
      None: 'No Coverage',
      '1000': '$1,000',
      '2000': '$2,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      'AIG Agency Auto': 'AIG',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Insurance Co.': 'Allstate Ins Co',
      'American Family': 'American Family',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Insurance': 'Farmers Ins',
      Geico: 'Geico',
      'Grange Mutual': 'Grange Mutual',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Metropolitan (Metlife)': 'Metropolitan',
      'Mutual of Enumclaw': 'Mutual of Enumclaw',
      Nationwide: 'Nationwide',
      PEMCO: 'PEMCO',
      'Progressive Casualty': 'Progressive Casualty',
      'Royal &amp; SunAlliance': 'Royal &amp; SunAlliance',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Insurance': 'Unigard Ins',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
      'Winterthur Swiss': 'Winterthur Swiss',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '15/30': '15,000/30,000',
      '25/50': '25,000/50,000',
      '50/100': '50,000/100,000',
      '100/300': '100,000/300,000',
      '250/500': '250,000 or greater',
    },
  },
  OH: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1000',
      '2000': '$2000',
      '2500': '$2500',
      '5000': '$5000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1000',
      '2000': '$2000',
      '2500': '$2500',
      '5000': '$5000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1000',
      '2000': '$2000',
      '3000': '$3000',
      '4000': '$4000',
      '5000': '$5000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '7500': '$7,500',
      '10000': '$10,000',
      '25000': '$25,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      'AIG Agency Auto': 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      Alfa: 'Alfa',
      'Allied Group': 'Allied Group',
      'Allstate Insurance Co.': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Auto Owners Group': 'Auto Owners Group',
      'Cincinnati Financial Group': 'Cincinnati Financial Group',
      'Civil Service': 'Civil Service',
      Dairyland: 'Dairyland',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farmers Insurance': 'Farmers Insurance',
      Geico: 'Geico',
      Grange: 'Grange',
      'Grange Mutual': 'Grange Mutual',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Metropolitan (Metlife)': 'Metropolitan (Metlife)',
      'Motorists Insurance Group': 'Motorists Insurance Group',
      Nationwide: 'Nationwide',
      'Ohio Casualty': 'Ohio Casualty',
      'Ohio Mutual': 'Ohio Mutual',
      'Progressive Casualty': 'Progressive Casualty',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      'State Auto Insurance Co.': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'The General (Permanent General)': 'The General (Permanent General)',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Insurance': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Western Reserve': 'Western Reserve',
      Westfield: 'Westfield',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  OK: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '25,000/50,000',
      '30000/60000': '30,000/60,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000/500,000',
      '500000/500000': '500,000/500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '30000': '$30,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '175000': '$175,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '1000': '$1,000',
      '2000': '$2,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'No prior insurance',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  OR: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '20000': '$20,000',
      '25000': '$25,000',
      '30000': '$30,000',
      '45000': '$45,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '175000': '$175,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '100000': '$100,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Country Companies': 'Country Companies',
      Dairyland: 'Dairyland',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'North Pacific': 'North Pacific',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Oregon Mutual': 'Oregon Mutual',
      'Progressive Casualty': 'Progressive Casualty',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      15000: '$15,000',
      25000: '$25,000',
      50000: '50,000',
    },
  },
  PA: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1000',
      '2000': '$2000',
      '2500': '$2500',
      '5000': '$5000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1000',
      '2000': '$2000',
      '2500': '$2500',
      '5000': '$5000',
    },
    bodilyInjuryLiability: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '5000': '$5000',
      '10000': '$10000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/100000': '$100,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      Grange: 'Grange',
      'Great American Ins Co': 'Great American Ins. Co.',
      Harleysville: 'Harleysville',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      'The General (Permanent General)': 'The General (Permanent General)',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    pip: {
      5000: '$5,000',
      10000: '$10,000',
      25000: '$25,000',
      50000: '$50,000',
      100000: '$100,000',
    },
    priorInsuranceBodilyInjuryLimits: {
      '15000/30000': '$15,000/$30,000',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  SC: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25/50': '$25,000/$50,000',
      '30/60': '$30,000/$60,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25/50': '25,000/50,000',
      '30/60': '30,000/60,000',
      '50/100': '50,000/100,000',
      '100/300': '100,000/300,000',
      '250/500': '$250,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25/50': '$25,000/$50,000',
      '30/60': '$30,000/$60,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000/$500,000',
    },
    underinsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Auto Owners Group': 'Auto Owners Group',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Direct Insurance Company': 'Direct Insurance Company',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Sentry: 'Sentry',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'No prior insurance',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  TN: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Civil Service': 'Civil Service',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Direct Insurance Company': 'Direct Insurance Company',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farm Bureau': 'Farm Bureau',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      Grange: 'Grange',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Safe Auto': 'Safe Auto',
      Safeco: 'Safeco',
      Shelter: 'Shelter',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'Tennessee Farmers': 'Tennessee Farmers',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25/50': '$25,000/$50,000',
      '50/100': '$50,000/$100,000',
      '100/300': '$100,000/$300,000',
      '250/500': '$250,000 or greater',
    },
  },
  TX: {
    comprehensiveDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'None',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'None',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'None',
      '30000/60000': '30,000/60,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000/500,000',
      '500000/500000': '500,000/500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'None',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '250000': '$250,000',
      '500000': '$500,000',
    },
    pip: {
      None: 'None',
      '2500': '$2,500',
      '5000': '$5,000',
      '10000': '$10,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate County Mutual': 'Allstate County Mutual',
      'Allstate Indemnity': 'Allstate Indemnity',
      'Civil Service': 'Civil Service',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Texas County Mutual': 'Farmers Texas County Mutual',
      Geico: 'Geico',
      'Geico Indemnity': 'Geico Indemnity',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Home State County Mutual': 'Home State County Mutual',
      'Infinity Ins. Co.': 'Infinity Ins. Co.',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mid Century Insurance Company': 'Mid Century Insurance Company',
      Nationwide: 'Nationwide',
      'Old American County Mutual': 'Old American County Mutual',
      'Progressive Casualty': 'Progressive Casualty',
      Safeco: 'Safeco',
      'Southern Farm Bureau': 'Southern Farm Bureau',
      'State Farm County Mutual': 'State Farm County Mutual',
      'State Farm Mutual': 'State Farm Mutual',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      'USAA Casualty Insurance': 'USAA Casualty Insurance',
      'USAA County Mutual': 'USAA County Mutual',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '30/60': '30,000/60,000',
      '50/100': '50,000/100,000',
      '100/300': '100,000/300,000',
      '250/500': '250,000 or greater',
    },
  },
  UT: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/65000': '$25,000/$65,000',
      '50000/100000': '$50,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/65000': '$25,000/$65,000',
      '50000/100000': '$50,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/65000': '$25,000/$65,000',
      '50000/100000': '$50,000/$100,000',
      '200000/200000': '$200,000/$200,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '3500': '$3,500',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/65000': '$25,000/$65,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
    pip: {
      3000: '$3,000',
      5000: '$5,000',
      10000: '$10,000',
    },
  },
  VA: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    bodilyInjury_FR44: {
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    propertyDamage_FR44: {
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/50,000',
      '30000/60000': '$30,000/60,000',
      '50000/100000': '$50,000/100,000',
      '100000/300000': '$100,000/300,000',
      '250000/500000': '$250,000/500,000',
      '500000/500000': '$500,000/500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      '20000': '$20,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      Alfa: 'Alfa',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Bristol West': 'Bristol West',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Electric Insurance Co': 'Electric Insurance Co.',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Mercury Ins. Co.': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'GMAC Insurance': 'National General',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      'Progressive Casualty': 'Progressive Casualty',
      'QBE Re Group': 'QBE Re Group',
      Safeco: 'Safeco',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Virginia Farm Bureau': 'Virginia Farm Bureau',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '25,000/50,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000 or greater',
    },
  },
  WA: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    pip: {
      None: 'Declined',
      '10000': '$10,000',
      '35000': '$35,000',
    },
    underinsuredMotoristsPropertyDamage: {
      None: 'No Coverage',
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '30000': '$30,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'American Family': 'American Family',
      AMERIPRISE: 'AMERIPRISE',
      Amica: 'Amica',
      'Civil Service': 'Civil Service',
      CNA: 'CNA',
      Dairyland: 'Dairyland',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Farmers Ins': 'Farmers Insurance',
      Geico: 'Geico',
      'Grange Mutual': 'Grange Mutual',
      'Hartford Ins Co': 'Hartford Ins Co',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Enumclaw': 'Mutual of Enumclaw',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'Omni (Good2Go)': 'Omni (Good2Go)',
      PEMCO: 'PEMCO',
      'Progressive Casualty': 'Progressive Casualty',
      'Royal &amp; SunAlliance': 'Royal &amp; SunAlliance',
      Safeco: 'Safeco',
      'State Farm': 'State Farm',
      Stillwater: 'Stillwater',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Titan (Victoria)': 'Titan (Victoria)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Winterthur Swiss': 'Winterthur Swiss',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  WI: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '750': '$750',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '150000/300000': '$150,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '10000': '$10,000',
      '15000': '$15,000',
      '20000': '$20,000',
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '75000': '$75,000',
      '100000': '$100,000',
      '150000': '$150,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '1000': '$1,000',
      '5000': '$5,000',
      '10000': '$10,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '150000/300000': '$150,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '150000/300000': '$150,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      'AAA (Auto Club)': 'AAA (Auto Club)',
      'AIG Agency Auto': 'AIG',
      'Allied Group': 'Allied Group',
      'Allstate Insurance Co.': 'Allstate Ins Co',
      'American Family': 'American Family',
      'American National P and C': 'American National Property and Casualty',
      'Amica Mutual Ins. Co.': 'Amica Mutual Ins Co',
      'Auto Owners Group': 'Auto Owners Group',
      'Bristol West': 'Bristol West',
      'Civil Service': 'Civil Service',
      Dairyland: 'Dairyland',
      'Direct Insurance Company': 'Direct Insurance Company',
      'Electric Insurance Co.': 'Electric Insurance Co',
      Encompass: 'Encompass',
      Erie: 'Erie',
      Esurance: 'Esurance',
      'Farmers Insurance': 'Farmers Ins',
      Geico: 'Geico',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Integrity (Grange)': 'Integrity (Grange)',
      Kemper: 'Kemper',
      'Liberty Mutual': 'Liberty Mutual',
      'Metropolitan (Metlife)': 'Metropolitan',
      'National General': 'GMAC Insurance',
      Nationwide: 'Nationwide',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      Safeco: 'Safeco',
      Sentry: 'Sentry',
      'State Farm': 'State Farm',
      'TIG Insurance Group (Travelers)': 'TIG Insurance Group',
      Stillwater: 'Stillwater',
      'Titan (Victoria)': 'Titan (Victoria)',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      Viking: 'Viking',
      'Wisconsin Mutual': 'Wisconsin Mutual',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '25,000/50,000',
      '50000/100000': '50,000/100,000',
      '100000/300000': '100,000/300,000',
      '250000/500000': '250,000 or greater',
    },
  },
  WV: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '25000': '$25,000',
      '35000': '$35,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '3000': '$3,000',
      '4000': '$4,000',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
      '100000': '$100,000',
    },
    uninsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    uninsuredMotoristsPropertyDamage: {
      '25000': '$25,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    underinsuredMotoristsPropertyDamage: {
      '25000': '$25,000',
      '50000': '$50,000',
      '60000': '$60,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '300000': '$300,000',
      '500000': '$500,000',
    },
    insuranceCarrier: {
      None: 'None',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
  WY: {
    comprehensiveDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    collisionDeductible: {
      None: 'No Coverage',
      '100': '$100',
      '200': '$200',
      '250': '$250',
      '300': '$300',
      '400': '$400',
      '500': '$500',
      '1000': '$1,000',
      '2500': '$2,500',
      '5000': '$5,000',
    },
    bodilyInjuryLiability: {
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    propertyDamageLiability: {
      '20000': '$20,000',
      '25000': '$25,000',
      '30000': '$30,000',
      '40000': '$40,000',
      '50000': '$50,000',
      '100000': '$100,000',
      '200000': '$200,000',
      '250000': '$250,000',
      '300000': '$300,000',
      '400000': '$400,000',
      '500000': '$500,000',
      '750000': '$750,000',
    },
    medPay: {
      None: 'No Coverage',
      '500': '$500',
      '1000': '$1,000',
      '2000': '$2,000',
      '2500': '$2,500',
      '5000': '$5,000',
      '10000': '$10,000',
      '25000': '$25,000',
      '50000': '$50,000',
      '75000': '$75,000',
    },
    uninsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    underinsuredMotoristsBodilyInjury: {
      None: 'No Coverage',
      '25000/50000': '$25,000/$50,000',
      '30000/60000': '$30,000/$60,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000/$500,000',
      '500000/500000': '$500,000/$500,000',
    },
    insuranceCarrier: {
      None: 'No Coverage',
      Other: 'Other',
      '21st Century': '21st Century',
      AIG: 'AIG Agency Auto',
      'AIG Direct': 'AIG Direct',
      'Allied Group': 'Allied Group',
      'Allstate Ins Co': 'Allstate Insurance Co.',
      'Amco Ins Co': 'Amco Insurance Co.',
      'American Alliance Ins Co': 'American Alliance Ins. Co.',
      'American Economy Ins Co': 'American Economy Ins. Co.',
      'American Family': 'American Family',
      'American Manufacturers Mutual': 'American Manufacturers Mutual',
      'American National Property and Casualty': 'American National P and C',
      'American Reliable Ins Co': 'American Reliable Ins. Co.',
      'Amex Assurance Co': 'Amex Assurance Co.',
      'Amica Mutual Ins Co': 'Amica Mutual Ins. Co.',
      'Atlanta Casualty': 'Atlanta Casualty',
      'Atlantic Mutual Co': 'Atlantic Mutual Co.',
      'Calfarm Ins Co': 'Calfarm Ins. Co.',
      'California Automobile Ins Co': 'California Automobile Ins. Co.',
      'California Casualty and Fire Ins Co': 'California Casualty and Fire Ins. Co.',
      'California State Auto Assoc': 'California State Auto Assoc.',
      'Century National Ins': 'Century National Ins.',
      'Chubb Group of Ins Co': 'Chubb Group',
      CNA: 'CNA',
      'Colonial Penn': 'Colonial Penn',
      'Commerce West': 'Commerce West',
      'Commercial Union': 'Commercial Union',
      'Continental Ins Co': 'Continental Ins. Co.',
      Dairyland: 'Dairyland',
      'Direct General': 'Direct General',
      'Eagle Ins Co': 'Eagle Insurance Co.',
      'Electric Insurance Co': 'Electric Insurance Co.',
      'Empire Fire and Marine': 'Empire Fire and Marine',
      Encompass: 'Encompass',
      Esurance: 'Esurance',
      'Explorer Ins Co': 'Explorer Ins. Co.',
      'Farmers Ins': 'Farmers Insurance',
      'Federal Ins Co': 'Federal Ins. Co.',
      'Financial Indemnity': 'Financial Indemnity Co (FIC)',
      'Firemans Fund': "Fireman's Fund",
      Geico: 'Geico',
      'General Accident Insurance': 'General Accident Insurance',
      'Great American Ins Co': 'Great American Ins. Co.',
      'Hanover Ins Co': 'Hanover Ins. Co.',
      'Hartford Ins Co': 'Hartford Ins Co',
      'Infinity Ins Co': 'Infinity Ins. Co.',
      'Insurance Co of the West': 'Insurance Co. of the West',
      Kemper: 'Kemper',
      'Leader National': 'Leader National',
      'Liberty Mutual': 'Liberty Mutual',
      'Lumbermans Mutual': "Lumberman's Mutual",
      'Maryland Casualty': 'Maryland Casualty',
      'Mercury Ins Co': 'Mercury Ins. Co.',
      Metropolitan: 'Metropolitan (Metlife)',
      'Mutual of Omaha': 'Mutual of Omaha',
      'GMAC (National General)': 'National General',
      Nationwide: 'Nationwide',
      'NJ Skylands Insurance': 'NJ Skylands Insurance',
      'Northwestern Pacific Indemnity': 'Northwestern Pacific Indemnity',
      'Ohio Casualty': 'Ohio Casualty',
      'Omni Insurance': 'Omni Insurance',
      'Orion Auto Ins Co': 'Orion Auto Ins Co',
      'Permanent General': 'Permanent General',
      'Progressive Casualty': 'Progressive Casualty',
      Prudential: 'Prudential',
      'Republic Indemnity': 'Republic Indemnity',
      Safeco: 'Safeco',
      'Selective InsGroup': 'Selective Insurance Group',
      'St. Paul Fire and Marine Ins Co': 'St. Paul Fire and Marine Ins. Co.',
      'State Auto Ins Co': 'State Auto Insurance Co.',
      'State Farm': 'State Farm',
      'TIG Insurance Group': 'TIG Insurance Group (Travelers)',
      'Unigard Ins': 'Unigard Insurance',
      Unitrin: 'Unitrin',
      USAA: 'USAA',
      'Wawanesa Mutual': 'Wawanesa Mutual',
      'Workmens Auto Insurance': "Workmen's Auto Insurance",
      'Zurich Ins Group': 'Zurich Ins. Group',
    },
    priorInsuranceBodilyInjuryLimits: {
      None: 'None (no prior insurance)',
      '25000/50000': '$25,000/$50,000',
      '50000/100000': '$50,000/$100,000',
      '100000/300000': '$100,000/$300,000',
      '250000/500000': '$250,000 or greater',
    },
  },
};
