import axios from 'axios';
import endpoints from './endpoints';
import { CreateUserInterface, CheckUserInterface } from '../components/interfaces/AddTypeInterfaces';
import { HavenLinkPayloadInterface } from '../components/interfaces/LifeInterfaces';
import { FamilyMemberInterface, FamilyMemberPayloadType } from '../components/interfaces/CommonInterfaces';

export const getDecryptedContent = (content: string) => {
  return axios.get(endpoints.decryptLink(content));
};

export const createUser = (payload: CreateUserInterface) => {
  return axios.post(endpoints.createUsers, payload);
};

export const checkUserEmail = (payload: CheckUserInterface) => {
  return axios.post(endpoints.checkUser, payload);
};

export const createADDBeneficiary = (payload: {}, token: string) => {
  return axios.post(endpoints.beneficiaries, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const finalizeAdd = (payload: {}, token: string) => {
  return axios.post(endpoints.finalizeAdd, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getHavenLink = (payload: HavenLinkPayloadInterface, token: string) => {
  return axios.post(endpoints.havenLink, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getLifeQuote = (payload: {}, token: string) => {
  return axios.post(endpoints.lifeQuote, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const updateUser = (payload: { [key: string]: any }, token: string) => {
  return axios.patch(endpoints.updateUser, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getFamily = (token: string) => {
  return axios.get<FamilyMemberInterface[]>(endpoints.family, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const createMember = (payload: FamilyMemberPayloadType, token: string) => {
  return axios.post(endpoints.family, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const updateMemberDetails = (id: string, payload: FamilyMemberPayloadType, token: string) => {
  return axios.put(endpoints.updateMember(id), payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const removeFamilyMember = (id: string, token: string) => {
  return axios.delete(endpoints.updateMember(id), {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const deleteBeneficiary = (id: string, token: string) => {
  return axios.delete(endpoints.deleteBeneficiaries(id), {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const bindPolicy = (
  id: string,
  payload: { coverageAmount: number; startDate?: string; endDate?: string },
  token: string,
) => {
  return axios.post(endpoints.bind(id), payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getAfiUserInfo = (token: string) => {
  return axios.get(endpoints.afiUserInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const submitAfiUserInfo = (token: string, data: any) => {
  return axios.post(endpoints.afiUserInfo, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const parseAfiPayload = (token: string, data: any, signal?: AbortSignal) => {
  return axios.post(endpoints.afiParserPayload, data, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/xml',
    },
    signal,
  });
};

export const parseAfiResponse = (
  token: string,
  data: any,
  signal?: AbortSignal,
  reportingData?: { [key: string]: string | number },
) => {
  const url = endpoints.afiParserResponse;
  return axios.post(
    url,
    { data: data, reportingData: { ...reportingData } },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      signal,
    },
  );
};

export const afiQuotesWrapper = (token: string, data: any, signal?: AbortSignal) => {
  return axios.post(endpoints.afiQuotes, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/xml',
    },
    signal,
  });
};

export const getCanopyTOS = () => {
  return axios.get(endpoints.canopyTos);
};

export const getCanopyCarriers = () => {
  return axios.get(endpoints.canopyCarriers);
};

export const chooseCanopyMFAOption = (option: string, pullId: string, pullJwt: string, token: string) => {
  return axios.post(
    endpoints.canopyMFAOptions,
    {
      pullId: pullId,
      pullJwt: pullJwt,
      selectedOption: option,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const sendCanopyMFACode = (code: string, pullId: string, pullJwt: string, token: string) => {
  return axios.post(
    endpoints.canopyCode,
    {
      pullId: pullId,
      pullJwt: pullJwt,
      validationCode: code,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getCanopyUser = (payload: { [key: string]: string }) => {
  const { insurerName, insurerPassword, insurerUsername, insurerThirdField } = payload;
  const data: {
    [key: string]: string | number;
  } = {
    termsVersion: 1,
    consentLanguage: 'en',
    insurerName: insurerName,
    insurerUsername: insurerUsername,
    insurerPassword: insurerPassword,
  };
  if (insurerThirdField) {
    data.insurerThirdField = insurerThirdField;
  }
  return axios.post(endpoints.getCanopyUser, data);
};

export const getCanopyPull = (pullId: string, token: string) => {
  return axios.get(`${endpoints.canopyPull}/${pullId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const canopyLinkUser = (canopyId: string, token: string) => {
  return axios.put(
    `${endpoints.canopyLinkUser}`,
    { canopyId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};
