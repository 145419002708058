import { string } from 'yup';
import { generateSelectOptionsArray } from '../../../services/helpers/AFIHelpers';
import { questionBuilder } from '../../../services/helpers/AFIQuestionBuilder';
import { yupValidations } from '../../../services/yupValidations';

export const firstName = questionBuilder(
  'first_name',
  'First Name',
  'First Name',
  yupValidations.stringMinMaxLength(2, 25),
  'input',
);

export const lastName = questionBuilder(
  'last_name',
  'Last Name',
  'Last Name',
  yupValidations.stringMinMaxLength(2, 25),
  'input',
);

export const dateOfBirth = questionBuilder(
  'dob',
  'Date of Birth',
  'MM/DD/YYYY',
  yupValidations.ageMinMax(18, 100),
  'date',
);

const genderOptions = { M: 'Male', F: 'Female' };
export const gender = questionBuilder(
  'gender',
  'Gender',
  'Gender',
  string().oneOf(Object.keys(genderOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(genderOptions),
);

export const maritalStatusOptions: { [key: string]: string } = {
  S: 'Single',
  M: 'Married',
  W: 'Widowed',
  D: 'Divorced',
  V: 'Registered Domestic Partner',
};
export const maritalStatus = questionBuilder(
  'marital_status',
  'Marital Status',
  'Marital Status',
  string().oneOf(Object.keys(maritalStatusOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(maritalStatusOptions),
);

export const homePhone = questionBuilder(
  'homePhone',
  'Home Phone',
  '(123) 456-7890',
  yupValidations.phone,
  'phone',
);

export const businessPhone = questionBuilder(
  'business_phone',
  'Business Phone',
  '+1-NNN-NNNNNNN',
  yupValidations.phone,
  'phone',
);

export const emailAddress = questionBuilder(
  'email_address',
  'Email Address',
  'Email Address',
  yupValidations.email,
  'email',
);

const sameParkingAddressOptions = {
  Yes: 'Yes',
  No: 'No',
};

export const sameParkingAddress = questionBuilder(
  'sameParkingAddress',
  'Is this where you park your cars?',
  'Is this where you park your cars?',
  string().oneOf(Object.keys(sameParkingAddressOptions)).required('This can’t be left blank'),
  'radio',
  'Yes',
  generateSelectOptionsArray(sameParkingAddressOptions),
);
