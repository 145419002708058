import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from '../../api/endpoints';
import CanopyIntegrationFlow from '../../components/canopy/canopy-integration-flow/CanopyIntegrationFlow';
import AboutYou from '../../components/common/steps/concierge/AboutYouScreens/AboutYou';
import UserInfo from '../../components/common/steps/concierge/AboutYouScreens/UserInfo';
import AssetDetails from '../../components/common/steps/concierge/AssetDetails/AssetDetails';
import AssetOverview from '../../components/common/steps/concierge/AssetOverview/AssetOverview';
import Eligibility from '../../components/common/steps/concierge/ExistingCoverage/Eligibility';
import ExistingCoverage from '../../components/common/steps/concierge/ExistingCoverage/ExistingCoverage';
import Thanks from '../../components/common/steps/concierge/Thanks/Thanks';
import { CanopyInsurerType, CanopyUserType } from '../../components/interfaces/AFIInterfaces';
import QuestionPageLayout from '../../components/layouts/question-page-layout/QuestionPageLayout';
import { canopyTimeoutDuration } from '../../constants/AFIConstants';
import {
  conciergeMobileSections,
  conciergeNavigationSteps,
  conciergeSections,
  defaultConciergeMapping,
} from '../../constants/ConciergeConstants';
import { useConcierge } from '../../context/ConciergeContext';
import SSEService from '../../services/SSEService';
import './concierge.scss';

const ConciergeForm = () => {
  const { steps,
    lastStep,
    userAnswers,
    canopyTOS,
    isCanopy,
    setIsCanopy,

    setLastStep } = useConcierge();

  const [canopyUser, setCanopyUser] = useState<CanopyUserType | null>(
    JSON.parse(localStorage.getItem('canopy_user') as string) || null,
  );
  // Timeout logic, for every API call, the page needs to receive a server event, if that doesn't happen, the user should be redirected to the failure page
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const timeOut = () => {
    const newTimeoutID = setTimeout(() => {
      navigate('/concierge/existing-coverage/failed');
    }, canopyTimeoutDuration);
    setTimeoutId(newTimeoutID);
  };

  // Concierge form hooks
  const { sectionId, stepId } = useParams();
  const currentStep = sectionId + (stepId ? '/' + stepId : '');
  const navigate = useNavigate();

  // Redirect logic
  useEffect(() => {
    if (steps.indexOf(currentStep) === -1) navigate('/concierge/about/basics'); // Step not found in array -> redirect to start
    if (steps.indexOf(currentStep) > steps.indexOf(lastStep) + 1) navigate('/concierge/about/basics'); // Trying to progress to a step yet to be unlocked -> redirect to start
  }, [sectionId, stepId, lastStep]);


  const service = new SSEService();

  const conciergeContent = useMemo(() => {
    switch (sectionId) {
      case 'about':
        if (stepId === 'basics') return <UserInfo />;
        if (stepId === 'details') return <AboutYou />;
        break;
      case 'assets':
        if (stepId === 'details') return <AssetDetails />;
        if (stepId === 'overview') return <AssetOverview />;
        break;
      case 'existing-coverage':
        if (!stepId) return <ExistingCoverage />;
        if (stepId === 'eligibility') return <Eligibility />;

        if (!isCanopy) {
          navigate('/concierge/existing-coverage/eligibility');
        }

        const canopyInsurer = userAnswers?.coverage?.carrier as CanopyInsurerType;
        return <CanopyIntegrationFlow
          stepId={stepId}
          canopyInsurer={canopyInsurer}
          canopyUser={canopyUser as CanopyUserType}
          setCanopyUser={setCanopyUser}
          mfaOptions={service.serverEvent?.data?.mfa_options}
          canopyTOS={canopyTOS}
          setIsCanopy={setIsCanopy}
          setLastStep={setLastStep}
          timeOut={timeOut}
          onSkip={() => {
            setLastStep('existing-coverage/success')
            navigate('/concierge/existing-coverage/eligibility')
          }}
          onSuccess={() => {
            localStorage.removeItem('canopy_user');
            setLastStep('existing-coverage/success')
            navigate('/concierge/existing-coverage/eligibility')
          }}
          onTryAgain={() => {
            setLastStep('existing-coverage')
            navigate('/concierge/existing-coverage/connect-account')
          }}
        />;
      case 'request':
        setLastStep('request')
        return <Thanks />;
      default:
        navigate('/concierge/about/basics');
        break;
    }
  }, [stepId, sectionId]);

  const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);
  useEffect(() => {
    if (canopyUser) {
      const newEventSource = new EventSource(
        `${endpoints.canopyEventSource}/${canopyUser.pullId}?token=${canopyUser.eventJWT}`,
      );
      setEventSource(newEventSource);
      return () => {
        newEventSource.close();
      };
    }
  }, [canopyUser]);
  // Whenever we're creating a new time interval, we need to update the eventSource onMessage function with the new ID
  useEffect(() => {
    if (eventSource) {
      eventSource.onmessage = (e) => {
        if (JSON.parse(e.data)?.status !== 'KEEP_ALIVE') {
          clearTimeout(timeoutId);
          const urlPromise = service.nextStep(JSON.parse(e.data), canopyUser as CanopyUserType, setLastStep);
          urlPromise.then((url) => navigate(url as string));
        }
      };
    }
  }, [eventSource, timeoutId]);

  const getProgress = () => {
    const totalSections = conciergeSections.length; // 4
    const sectionsLength = 100 / totalSections; // 100 / 4 = 25
    const sectionProgress = sectionsLength * conciergeSections.indexOf(sectionId as string); // 25 * 1 = 25, 25 * 2 for the second section, etc.
    const currentSteps = conciergeNavigationSteps.filter((item) => item.includes(sectionId as string)); // Only steps for current section
    const stepsLength = currentSteps.length; // 2
    const stepProgress =
      (currentSteps.findIndex((item) => item.includes(stepId as string)) + 1 || 1) * // certain sections have no stepId, we want to default to 1
      (sectionsLength / stepsLength); // 1 * (25 / 2), 2* (25 / 2) for second step, etc
    return sectionProgress + stepProgress; // 50 + 12.5 (first step of the third section)
  };

  return (
    <div className="concierge-layout-container">
      <QuestionPageLayout
        filledSection={sectionId === 'request' ? sectionId : undefined}
        disclaimerContent={<></>}
        isAFIStyle={true}
        headerInfo={{ showIcon: false, onIconClick: () => { } }}
        progressBarInfo={{
          sectionsMobile: conciergeMobileSections,
          sectionsDesktop: conciergeSections,
          sectionId: sectionId,
          progress: getProgress(),
          sectionsMapping: defaultConciergeMapping,
          stepId: stepId,
        }}
        stepContent={conciergeContent}
        customCss="concierge"
      />
    </div>
  );
};

export default ConciergeForm;
