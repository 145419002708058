import { useContext, useState } from 'react';
import MultipleOptions from '../../../../multiple-options/MultipleOptions';
import { ConciergeContext } from '../../../../../../context/ConciergeContext';
import { useFormik } from 'formik';
import { boolean, object, string } from 'yup';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import RadioQuestionField from '../../../../forms/RadioQuestionField/RadioQuestionField';
import './business-assets.scss';

const BusinessAssets = ({ onSubmit }: { onSubmit: (newValues: any) => void }) => {
  const { userAnswers } = useContext(ConciergeContext);
  const defaultAnswers = userAnswers?.assets?.business || '';

  const [options, setOptions] = useState<{ [key: string]: boolean }>({
    '$500,000 - $5 million': '$500,000 - $5 million' === defaultAnswers.value,
    '$5 million - $10 million': '$500,000 - $5 million' === defaultAnswers.value,
    '$10 million - $25 million': '$10 million - $25 million' === defaultAnswers.value,
    '$25 million and more': '$25 million and more' === defaultAnswers.value,
  });

  const formikConfig = useFormik({
    initialValues: {
      value: defaultAnswers.value,
      coOwners: defaultAnswers.coOwners,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: object({
      value: string().oneOf(Object.keys(options)).required('Required'),
      coOwners: boolean().required('Required'),
    }),
    onSubmit: () => { },
  });

  const { values, setFieldValue, isValid } = formikConfig;

  const onCheck = (name: string, value: boolean) => {
    const newObj = { ...options } as { [key: string]: boolean };
    Object.keys(newObj).map((item) => {
      if (item === name) newObj[item] = value;
      else newObj[item] = false;
    });
    setFieldValue('value', value ? name : '');
    setOptions(newObj);
  };

  return (
    <div className="business-assets-container">
      <div className="value-question">
        <p className="title">What is the approximate value of your business?</p>
        <MultipleOptions options={options} onCheck={onCheck} />
      </div>
      <div className="co-owners-question">
        <p className="title">About co-owners</p>
        <RadioQuestionField
          name="coOwners"
          questionText="Do you have co-owners?"
          currentValue={values.coOwners}
          onChange={setFieldValue}
        />
      </div>
      <div className="submit-button-container">
        <PrimaryButton text="Next" disabled={!isValid} onClick={() => onSubmit(values)}></PrimaryButton>
      </div>
    </div>
  );
};

export default BusinessAssets;
