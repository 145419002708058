import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { FormikProps, FormikValues } from 'formik';
import QuestionField from '../../afi/QuestionField/QuestionField';
import { AFIQuestionInterface } from '../../../interfaces/AFIInterfaces';
import {
  apt,
  city,
  parkikngState,
  parkingApt,
  parkingCity,
  parkingZip,
  state,
  zip,
} from '../../../../utils/afi/questions/address';
import _ from 'lodash';
import { getAddressAutocomplete } from '../../../../services/helpers/CommonHelpers';

type AddressFormProps = {
  questionObject: AFIQuestionInterface;
  formikConfig: FormikProps<FormikValues>;
  customKey?: string;
};

type AddressType = {
  long_name: string;
  short_name: string;
  types: string[];
};

type AddressSectionObject = {
  [key: string]: AddressType;
};

const AddressForm = ({ questionObject, formikConfig, customKey }: AddressFormProps) => {
  const { values, setFieldValue } = formikConfig;

  const setAddress = (value: string) => {
    setFieldValue(_.camelCase((customKey || '') + ' address'), value);
  };
  const setCity = (value: string) => {
    setFieldValue(_.camelCase((customKey || '') + ' city'), value);
  };
  const setZip = (value: string) => {
    setFieldValue(_.camelCase((customKey || '') + ' zip'), value);
  };
  const setShortState = (value: string) => {
    setFieldValue(_.camelCase((customKey || '') + ' state'), value);
  };

  //Set values for all address field when an option is selected in the dropdown
  const handleSelect = async (address: string) => {
    const { street, city, state, zip } = await getAddressAutocomplete(address);

    setFieldValue(_.camelCase((customKey || '') + ' address'), street);
    setCity(city);
    setShortState(state);
    setZip(zip);
  };

  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  };

  const formFields =
    customKey === 'parking' ? [parkingApt, parkingCity, parkikngState, parkingZip] : [apt, city, state, zip];

  return (
    <div className="address-form-component">
      <PlacesAutocomplete
        value={values[_.camelCase((customKey || '') + ' address')] || ''}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="location-search-container question-field-container">
            <input
              {...getInputProps({
                placeholder: questionObject.placeholder,
                name: questionObject.name,
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: suggestion.active ? 'suggesstion-item-active' : 'suggesstion-item',
                  })}
                  key={index}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {formFields.map((item) => {
        return <QuestionField key={item.identifier} questionObject={item} formikConfig={formikConfig} />;
      })}
    </div>
  );
};

export default AddressForm;
