import { formatAFIPriceString, splitBodilyInjuryPrices } from '../../../../../services/helpers/AFIHelpers';
import SelectField from '../../../select-field/SelectField';
import './grid-row.scss';

const DoubleRow = ({
  label,
  rowPrevValuePerPerson,
  rowPrevValuePerAccident,
  handleSelect,
  coverages,
  covKey,
  toggleAdjust,
  selectOptions,
  isLoading,
}: {
  label: string;
  rowPrevValuePerPerson: string;
  rowPrevValuePerAccident: string;
  handleSelect: any;
  coverages: any;
  covKey: string;
  toggleAdjust: boolean;
  selectOptions: any[];
  isLoading: boolean;
}) => {
  const tmp = splitBodilyInjuryPrices(coverages[covKey]);
  const perPerson = formatAFIPriceString(tmp.perPerson, '');
  const perAccident = formatAFIPriceString(tmp.perAccident, '');
  const formatedValue = formatAFIPriceString(coverages[covKey], 'bodilyInjury');

  return (
    <>
      <p className="primary-liability first-column">
        <span className="label-title">{label}</span>
        <span className="label">Bodily Injury (BI) Limit </span>
        <span className="label">Per person</span>
        <span className="label"> Per accident</span>
      </p>
      <p className="bodily-injury second-column">
        <span className="value">{rowPrevValuePerPerson}</span>
        <span className="value">{rowPrevValuePerAccident}</span>
      </p>

      <div className="bodily-injury third-column">
        {toggleAdjust ? (
          <SelectField
            disabled={isLoading}
            options={selectOptions}
            name={covKey}
            placeholder={formatedValue}
            handleChange={(e) => {
              handleSelect(e);
            }}
            value={coverages[covKey] === 'None' ? 'Not covered' : coverages[covKey]}
          />
        ) : (
          <>
            <span className="value">{perPerson}</span>
            <span className="value">{perAccident}</span>
          </>
        )}
      </div>
    </>
  );
};

export default DoubleRow;
