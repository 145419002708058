import { useContext } from 'react';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { UserContext } from '../../../../context/UserContext';
import './canopy-failure.scss';

const CanopyFailure = ({ onTryAgain, onSkip, setIsCanopy }: { onTryAgain: Function, onSkip: Function, setIsCanopy: Function }) => {
  const { device, partnerName } = useContext(UserContext);
  const image = require(`../../../../assets/images/partners/${partnerName}/afi/${device}/canopy-failure.png`);

  return (
    <div className="failure-container">
      <h1 className="failure-title">Connection failed</h1>
      <p className="failure-description">
        We weren’t able to get information on your existing coverage. Don’t worry though, we’ll guide you
        through the application process
      </p>
      <div className="failure-image-container">
        <img src={image} />
      </div>

      <div className="failure-buttons-container">
        <PrimaryButton
          text="Try Again"
          onClick={() => {
            if (onTryAgain) {
              onTryAgain();
            }

          }}
        />
        <div className="failure-skip-button" onClick={() => { setIsCanopy(false); onSkip() }}>
          Skip
        </div>
      </div>
    </div>
  );
};

export default CanopyFailure;
