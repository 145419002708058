import './grid-row.scss';

const GridRow = ({
  rowLabel,
  rowPrevValue,
  rowNewValue,
  rowClassname = '',
}: {
  rowLabel?: string;
  rowPrevValue?: string;
  rowNewValue?: React.ReactNode;
  rowClassname?: string;
}) => {
  return (
    <>
      <p className={`first-column padded-cell ${rowClassname}`}>
        <span className="label">{rowLabel}</span>
      </p>
      <p className={`second-column padded-cell ${rowClassname}`}>
        <span className="label">{rowPrevValue}</span>
      </p>
      <div className={`third-column padded-cell ${rowClassname}`}>{rowNewValue}</div>
    </>
  );
};

export default GridRow;
