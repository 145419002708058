import { Link } from 'react-router-dom';
import PrimaryButton from '../../../components/common/buttons/PrimaryButton';
import ConciergeHeader from '../../../components/common/concierge-header/concierge-header';
import '../concierge.scss';
import './conciergeIntro.scss';


const ConciergeIntro = () => {
  const supportImage = require(`../../../assets/common/support.png`);
  const screenshot = require(`../../../assets/common/screenshot.png`);
  const peopleImage = require(`../../../assets/common/people.png`);
  const ServeImage = require(`./concierge-intro.png`);

  return (
    <>
      <ConciergeHeader />
      <div className="concierge-lading-page">
        <section>
          <h1 className="title">
            Thank you for making the time for this strategic review of your risks and coverages.
          </h1>
          <div className={`first-container`}>
            <div>
              <p className={`content-1`}>
                Our licensed specialists are ready to assist you and your investment advisor in protecting your assets, optimizing tax advantages and securing your family’s future.
              </p>
              <Link to={'/concierge/about/basic'}>
                <span className={`lp-button`}>
                  <PrimaryButton text="Let’s Get Started" />
                </span>
              </Link>
              <p className="content-2">
                Help us get to know your unique situation by <b>completing this assessment.</b>  We’ll review and connect you and your investment advisor to the licensed specialist with the expertise you need.
              </p>

              <p className="content-2">
                As with all interactions with your Alden advisors, this information is <b>secure and confidential.</b>
              </p>
            </div>
            <div>
              <img className='img-intro' src={ServeImage} />
            </div>
          </div>
        </section>
        <section className="card-container">
          <div className="concierge-card">
            <div>
              <div className="ordinal">1.</div>
              <div>Answer some questions about your family and your assets.</div>
              <div>
                <img src={supportImage} alt="support" />
              </div>
            </div>
          </div>
          <div className="concierge-card">
            <div className="ordinal">2.</div>
            <div>Connect any of your current insurance policies.</div>
            <div>
              <img src={screenshot} alt="insurance" />
            </div>
          </div>
          <div className="concierge-card">
            <div className="ordinal">3.</div>
            <div>A specialist for high net worth individuals will review their assessment with you and your asset manager.</div>
            <div>
              <img src={peopleImage} alt="people" />
            </div>
          </div>
        </section>
      </div >
    </>
  );
};

export default ConciergeIntro;
