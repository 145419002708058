import { ReactNode } from 'react';
import './section.scss';

const Section = ({
  title,
  onEdit,
  isActive,
  content,
  animation,
}: {
  title: string;
  onEdit: (() => void) | null;
  isActive: boolean;
  content: ReactNode;
  animation: string;
}) => {
  return (
    <div
      className={`section ${isActive ? 'active ' : ''} ${!isActive && !onEdit ? 'incomplete ' : ''} ${
        animation ? animation : ''
      }`}
    >
      <h2 className="title">{title}</h2>
      {!isActive && onEdit && (
        <button className="edit-button" aria-label={'Edit ' + title + ' section'} onClick={onEdit}>
          Edit
        </button>
      )}
      {isActive && content}
    </div>
  );
};

export default Section;
