const api = process.env.REACT_APP_API;

export default {
  createUsers: `${api}/users`,
  checkUser: `${api}/users/check`,
  links: `${api}/links`,
  decryptLink: (content: string) => `${api}/links/decrypt?content=${content}`,
  finalizeAdd: `${api}/insurances/accidental-death`,
  beneficiaries: `${api}/beneficiaries`,
  havenLink: `${api}/insurances/life/transfer`,
  upsellRecommendations: `${api}/insurances/upsell`,
  lifeQuote: `${api}/insurances/life/quotes`,
  addReport: `${api}/admins/amalgamated/report`,
  updateUser: `${api}/users`,
  family: `${api}/family-members`,
  updateMember: (id: string) => `${api}/family-members/${id}`,
  voluntaryAddUserCheck: `${api}/insurances/voluntary_accidental_death/check`,
  createPolicy: `${api}/insurances`,
  voluntaryGenerateIframe: `${api}/insurances/voluntary_add/generate`,
  deleteBeneficiaries: (id: string) => `${api}/beneficiaries/${id}`,
  bind: (policyId: string) => `${api}/insurances/${policyId}/bind`,
  afiUserInfo: `${api}/insurances/afi/user-info`,
  afiQuotes: `${api}/insurances/afi/quote`,
  afiParserPayload: `${process.env.REACT_APP_AFI_PARSER}/afi/payload`,
  afiParserResponse: `${process.env.REACT_APP_AFI_PARSER}/afi/response`,
  canopyTos: `${api}/insurances/canopy/tos`,
  canopyCarriers: `${api}/insurances/canopy/carriers`,
  canopyMFAOptions: `${api}/insurances/canopy/connect/options`,
  canopyCode: `${api}/insurances/canopy/connect/code`,
  getCanopyUser: `${api}/insurances/canopy/connect/start`,
  canopyPull: `${api}/insurances/canopy/pulls`,
  canopyEventSource: `${api}/insurances/canopy/events`,
  canopyLinkUser: `${api}/insurances/canopy/users/link`,
};
