import { useState, useContext, useMemo } from 'react';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { Disclaimer } from '../../../../components/common/disclaimer/Disclaimer';
import InputField from '../../../../components/common/forms/InputField/InputField';
import { CanopyInsurerType } from '../../../../components/interfaces/AFIInterfaces';
import './canopy-login.scss';
import { getCanopyUser } from '../../../../api/apiFunctions';
import CanopyTOSModal from '../../../../components/common/afi/CanopyTOS/CanopyTOS';
import { allFieldsValid } from '../../../../services/validation';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { UserContext } from '../../../../context/UserContext';
import { camelCase } from 'lodash';
import { object, string } from 'yup';
import { yupMessages, yupValidations } from '../../../../services/yupValidations';

const CanopyLogin = ({
  currentInsurer,
  updateCanopy,
  canopyTOS,
  setCanopyUser,
  startNewTimeout,
  onSuccess,
  onSkip,
}: {
  currentInsurer: CanopyInsurerType;
  updateCanopy: Function;
  canopyTOS: string;
  setCanopyUser: Function;
  startNewTimeout: Function;
  onSkip: Function;
  onSuccess?: Function;
}) => {
  const { setShowErrorBanner } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showTOS, setShowTOS] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const hasThirdField = useMemo(() => {
    return currentInsurer?.thirdFieldPlaceholder ? true : false;
  }, [currentInsurer]);

  const thirdFieldKey = useMemo(() => {
    return camelCase(currentInsurer?.thirdFieldPlaceholder || '');
  }, [currentInsurer]);

  const defaultValues = useMemo(() => {
    const obj: { [key: string]: string } = {
      username: '',
      password: '',
    };
    if (hasThirdField) {
      obj[thirdFieldKey] = '';
    } else {
      delete obj[thirdFieldKey];
    }
    return obj;
  }, [hasThirdField]);

  const canopyLoginSchema = useMemo(() => {
    //some thirdFields come with their own regex validations, if they exist use them, if not
    //use string required
    return hasThirdField
      ? object({
        username: string().required(yupMessages.required),
        password: yupValidations.stringMinMaxLength(1, null),
        [thirdFieldKey]: currentInsurer?.thirdFieldValidatorRgx
          ? string()
            .test('field', currentInsurer?.thirdFieldValidatorError || 'Invalid field', (val) => {
              if (val !== undefined) {
                const pattern = new RegExp(currentInsurer?.thirdFieldValidatorRgx || '');
                return pattern.test(val);
              }
              return false;
            })
            .required(yupMessages.required)
          : string().required(yupMessages.required),
      })
      : object({
        username: string().required(yupMessages.required),
        password: yupValidations.stringMinMaxLength(1, null),
      });
  }, [hasThirdField]);

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: canopyLoginSchema,
    onSubmit: () => { },
  });

  const { values, handleChange, errors, setErrors, handleBlur, touched } = formik;

  const handleCloseModal = () => {
    setShowTOS(false);
  };
  const handleDisableButton = () => {
    return allFieldsValid(values, errors) || !isChecked;
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    const payload: { [key: string]: string } = {
      insurerName: currentInsurer.carrier_id,
      insurerUsername: values?.username,
      insurerPassword: values?.password,
    };
    if (hasThirdField) {
      payload.insurerThirdField = values[thirdFieldKey];
    }
    try {
      const res = await getCanopyUser(payload);
      if (res?.status === 200) {
        const newUser = {
          canopyJWT: res.data.canopyJwtToken,
          pullId: res.data.pull_id,
          pullJWT: res.data.pull_jwt,
          username: values?.username,
          eventJWT: res.data.eventsToken,
        };
        startNewTimeout();
        localStorage.setItem('canopy_user', JSON.stringify(newUser));
        setCanopyUser(newUser);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        setIsLoading(false);
        setShowErrorBanner(true);
      }
    } catch {
      setIsLoading(false);
      setShowErrorBanner(true);
    }
  };

  return (
    <div className="canopy-login">
      <h1 className="canopy-title">Connect your account</h1>
      <div className="canopy-insurer-name">
        <p
          style={{ color: `${currentInsurer.color ? currentInsurer.color : 'var(--secondary-text-color)'}` }}
        >
          {currentInsurer.name ?? 'unknown'}
        </p>
      </div>
      <h2 className="canopy-subtitle">Easy policy comparison</h2>
      <ul className="canopy-login-list">
        <li>Login and let us do the work for you</li>
        <li>No need to input information yourself</li>
      </ul>
      <div className="canopy-login-inputs-container">
        <InputField
          placeholder="Username"
          name="username"
          type="text"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors['username'] && touched['username'] ? errors : null}
          setErrors={setErrors}
          required={true}
        />
        <InputField
          placeholder="Password"
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors['password'] && touched['password'] ? errors : null}
          setErrors={setErrors}
          required={true}
        />
        {currentInsurer.thirdFieldPlaceholder && (
          <InputField
            placeholder={currentInsurer.thirdFieldPlaceholder}
            name={thirdFieldKey}
            type="text"
            value={values[thirdFieldKey]}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors[thirdFieldKey] && touched[thirdFieldKey] ? errors : null}
            setErrors={setErrors}
            required={true}
          />
        )}
      </div>
      <div className="canopy-login-secondary-buttons">
        {currentInsurer.resetPasswordUrl && (
          <div className="canopy-login-forgot-password">
            <a href={currentInsurer.resetPasswordUrl} target="_blank" rel="noreferrer noopener">
              Forgot password
            </a>
          </div>
        )}
        <Disclaimer
          checked={isChecked}
          setChecked={setIsChecked}
          labelText={
            <>
              I allow{' '}
              <u
                onClick={() => {
                  setShowTOS((prev) => !prev);
                }}
              >
                Harmonic Insurance Services
              </u>{' '}
              to view and receive my existing auto policy information.
            </>
          }
          labelAction={() => {
            setIsChecked((prev) => !prev);
          }}
        />
      </div>
      <div className="canopy-login-buttons-container">
        <PrimaryButton
          text="Connect"
          onClick={handleSubmit}
          disabled={handleDisableButton() || isLoading}
          loading={isLoading}
        />
        <button className="canopy-login-skip-button" onClick={() => {
          updateCanopy(false);
          onSkip()
        }}>
          Skip
        </button>
      </div>
      {showTOS && <CanopyTOSModal canopyTOS={canopyTOS} closeModal={handleCloseModal} />}
    </div>
  );
};

export default CanopyLogin;
