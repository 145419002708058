import { Fragment, useContext, useState } from 'react';
import { ConciergeContext } from '../../../../../../context/ConciergeContext';
import './vehicles.scss';
import { useFormik } from 'formik';
import SelectField from '../../../../select-field/SelectField';
import { array, object, string } from 'yup';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import VehicleForm from '../../../../forms/VehicleForm/VehicleForm';
import SecondaryButton from '../../../../buttons/SecondaryButton';
import SummaryItem from '../../../../SummaryItem/SummaryItem';
import PlusSign from '../../../../../../assets/common/plus-sign.svg';
import { conciergeMaxMembersCount } from '../../../../../../constants/ConciergeConstants';

const membersCountOptions = Array.from({ length: conciergeMaxMembersCount }, (_, index) => {
  return { value: index + '' };
});

const defaultVehicle = {
  year: '',
  type: '',
  make: '',
  model: '',
};

const Vehicles = ({ onSubmit }: { onSubmit: (newValues: any) => void }) => {
  const { userAnswers } = useContext(ConciergeContext);
  const defaultAnswers = userAnswers?.assets?.vehicles || {
    vehicles: [],
    membersCount: '',
  };

  const [isVehicleValid, setIsVehicleValid] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState({ ...defaultVehicle });

  const formikConfig = useFormik({
    initialValues: {
      vehicles: defaultAnswers.vehicles || [],
      membersCount: defaultAnswers?.membersCount || '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: object({
      vehicles: array().min(1),
      membersCount: string()
        .oneOf(membersCountOptions.map((item) => item.value))
        .required('Required field'),
    }),
    onSubmit: () => { },
  });

  const { values, setFieldValue, isValid, handleChange, errors, touched, setFieldTouched } = formikConfig;

  const handleDeleteVehicle = (index: number) => {
    const newVehicles = [...values.vehicles];
    newVehicles.splice(index, 1);
    setFieldValue('vehicles', newVehicles);
  };

  const handleAddVehicle = () => {
    const newVehicles = [...values.vehicles, currentVehicle];
    setCurrentVehicle({ ...defaultVehicle });
    setFieldValue('vehicles', newVehicles);
  };

  const handleNext = () => {
    onSubmit(isVehicleEmpty ? values : { ...values, vehicles: [...values.vehicles, currentVehicle] });
  };

  const isVehicleEmpty = !(
    currentVehicle.make ||
    currentVehicle.model ||
    currentVehicle.type ||
    currentVehicle.year
  );

  const shouldProgress = () => {
    // If the current vehicle form has data in it and is not fully filled
    // The user should progress with either none or fully valid data inside the form
    if (!isVehicleEmpty && !isVehicleValid) return false;
    if (
      !isValid && // If the formik configuration is not valid, we can still progress, but we must check:
      values.vehicles.length === 0 && // 1. We must not have any vehicle in the formik object
      isVehicleValid && // 2. The currentVehicle form is valid, even if it hasn't been added to the formik object yet
      values.membersCount && // 3. The user has filled the other field(s) (membersCount)
      !errors.membersCount // 4. The formik object does not contain any errors in regards to other field(s)
    )
      return true;
    // For any other case, we follow the formik validation
    return isValid;
  };

  return (
    <div className="vehicles-container">
      <p className="title">What type of vehicles do you have?</p>
      <div className="vehicle-forms">
        {values.vehicles.map((vehicle, index) => (
          <Fragment key={vehicle.year + vehicle.make + vehicle.model}>
            <SummaryItem
              text={vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model}
              onDelete={() => handleDeleteVehicle(index)}
            />
          </Fragment>
        ))}
        <VehicleForm
          vehicleInfo={currentVehicle}
          onInputChange={(newVehicle) => setCurrentVehicle(newVehicle)}
          setIsFormValid={setIsVehicleValid}
        />
        <SecondaryButton
          onClick={() => handleAddVehicle()}
          text={
            <p>
              <PlusSign />
              Add a vehicle
            </p>
          }
          disabled={!isVehicleValid}
        ></SecondaryButton>
      </div>
      <div className="members-count-question">
        <p className="question">How many family members will be drivers on the policy?</p>
        <div className="members-count-select">
          <SelectField
            options={membersCountOptions}
            name="membersCount"
            value={values.membersCount}
            placeholder="Select"
            handleChange={handleChange}
            error={touched.membersCount && errors.membersCount}
            handleBlur={() => setFieldTouched('membersCount')}
          />
        </div>
      </div>
      <div className="submit-button-container">
        <PrimaryButton text="Next" disabled={!shouldProgress()} onClick={handleNext}></PrimaryButton>
      </div>
    </div>
  );
};

export default Vehicles;
