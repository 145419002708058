import { useContext } from 'react';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { CanopyInsurerType } from '../../../../components/interfaces/AFIInterfaces';
import { canopySuccessImages } from '../../../../constants/AFIConstants';
import { UserContext } from '../../../../context/UserContext';
import './canopy-success.scss';

const CanopySuccess = ({ currentInsurer, onNext }: { currentInsurer: CanopyInsurerType, onNext: Function }) => {
  const { device, partnerName } = useContext(UserContext);


  return (
    <div className="success-container">
      <h1 className="success-title">Connection success!</h1>
      <p className="success-description">
        We imported all your policy information from {currentInsurer.name}. Let’s review it to make sure
        everything looks right
      </p>
      <div className="success-svg-container">{canopySuccessImages(partnerName, device)}</div>
      <PrimaryButton
        text="Next"
        onClick={() => {
          onNext();
        }}
      />
    </div>
  );
};

export default CanopySuccess;
