import { useContext, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { UserContext } from '../../context/UserContext';
import ProgressBar from '../../components/common/progress-bar/ProgressBar/ProgressBar';
import { VoluntaryAddContext } from '../../context/VoluntaryContext';
import VoluntaryCoverage from '../../components/common/steps/voluntary-add-insurance/VoluntaryCoverage';
import VoluntaryAmounts from '../../components/common/steps/voluntary-add-insurance/VoluntaryAmounts';
import VoluntaryBeneficiary from '../../components/common/steps/voluntary-add-insurance/VoluntaryBeneficiary';
import { voluntaryAddStepIds, VoluntaryDisclaimerContent } from '../../constants/VoluntaryConstants';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import AddFamily from './AddFamily';
import VoluntaryReviewPage from './VoluntaryReviewPage';
import { getProgressStep } from '../../services/helpers/CommonHelpers';
import Header from '../../components/common/Header/Header';
import VoluntaryAddress from '../../components/common/steps/voluntary-add-insurance/VoluntaryAddress';

const VoluntaryAddQuestionsPage = () => {
  const { progressStep, totalSteps, setProgressStep, lastStep} =
    useContext(VoluntaryAddContext);
  const { setStepAnimationType, showDisclaimer, setShowDisclaimer, headerContent } = useContext(UserContext);
  let { stepId } = useParams();
  let navigate = useNavigate();
  let history = createBrowserHistory({ window });

  useEffect(() => {
    if (stepId) {
      const newProgressStep = getProgressStep(voluntaryAddStepIds, stepId);
      const lastProgressStep = getProgressStep(voluntaryAddStepIds, lastStep);
      if (
        newProgressStep <= 0 || // Redirect if the url is wrong (-1 means it was not found in the array)
        lastProgressStep < 0 || // Redirect if lastStep wasn't found in the array (probably manually edited)
        newProgressStep > lastProgressStep + 1 // Redirect if the new step isn't exactly one step after the previous one
        // A step is marked as completed once the "Next" button has been pressed and all actions completed, usually before the navigate() function
      ) {
        navigate('/accidental-death-voluntary/start');
        return;
      }
      setProgressStep(newProgressStep);
    }
  }, [stepId]);

  useEffect(() => {
    history.listen(({ action }) => {
      action === 'POP' && setStepAnimationType('slide-in_navback');
    });
  }, [history]);

  const checkStep = useMemo(() => {
    switch (stepId) {
      case 'address':
        return <VoluntaryAddress />;
      case 'family':
        return <AddFamily />;
      case 'coverage':
        return <VoluntaryCoverage />;
      case 'beneficiaries':
        return <VoluntaryBeneficiary />;
      case 'beneficiary-split':
        return <VoluntaryAmounts />;
      case 'payment':
        return <VoluntaryReviewPage />;
      default:
        break;
    }
  }, [stepId]);

  return (
    <div className="voluntary-questions-page">
      <Header headerContent={headerContent} showIcon={true} onIconClick={() => setShowDisclaimer(true)} />
      <ProgressBar currentStep={progressStep} totalSteps={totalSteps} />
      {checkStep}
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          subtitle="Disclaimer"
          content={<VoluntaryDisclaimerContent />}
        />
      )}
    </div>
  );
};

export default VoluntaryAddQuestionsPage;
