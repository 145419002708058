import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutocompleteWrapper from '../../../../components/common/autocomplete/Autocomplete';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../../components/common/buttons/SecondaryButton';
import { CanopyInsurerType } from '../../../../components/interfaces/AFIInterfaces';
import { canopyCurrentInsurerImages } from '../../../../constants/AFIConstants';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { UserContext } from '../../../../context/UserContext';
import './current-insurer.scss';
import { getInsurerAutocompletePlaceholderText } from '../../../../constants/CommonConstants';

const CurrentInsurer = ({
  currentInsurer,
  setCurrentInsurer,
  carrierList,
}: {
  currentInsurer: CanopyInsurerType;
  setCurrentInsurer: Dispatch<SetStateAction<CanopyInsurerType>>;
  carrierList: {
    status: 'error' | 'success' | 'loading';
    data: any[];
  };
}) => {
  const { partnerName, device } = useContext(UserContext);
  const [selectedInsurer, setSelectedInsurer] = useState<CanopyInsurerType>(currentInsurer || '');
  const { setIsCanopy } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();

  const onSubmit = () => {
    if (selectedInsurer) {
      setCurrentInsurer(selectedInsurer);
      localStorage.setItem('afi_current_insurer', JSON.stringify(selectedInsurer));
      navigate('/full-quote-auto/import/login');
    }
  };

  return (
    <div className="current-insurer-container">
      <div className="current-insurer-text">
        <h1 className="current-insurer-title">Who is your current auto insurance provider?</h1>
        <p className="current-insurer-description">
          We’ll use the information from your current policy to get you the best quote possible
        </p>
      </div>
      {canopyCurrentInsurerImages(partnerName, device)}
      <div className="current-insurer-autocomplete-container">
        <AutocompleteWrapper
          placeholder={getInsurerAutocompletePlaceholderText(carrierList.status)}
          value={selectedInsurer.name}
          setValue={setSelectedInsurer}
          options={carrierList.data}
          displayValueKey="name"
          disabled={carrierList.status === 'error'}
        />
      </div>
      <div className="current-insurer-buttons-container">
        <SecondaryButton
          text="I don’t have insurance"
          fontColor="black"
          onClick={() => {
            setIsCanopy(false);
          }}
        />
        <PrimaryButton text="Next" disabled={!selectedInsurer} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default CurrentInsurer;
