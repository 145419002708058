import { SectionsNavigationMapping } from '../components/interfaces/CommonInterfaces';
import { ConciergeAnswers } from '../components/interfaces/ConciergeInterfaces';

export const canopyNavigationSteps = [
  'existing-coverage/connect-account',
  'existing-coverage/selection',
  'existing-coverage/code',
  'existing-coverage/connecting',
  'existing-coverage/failed',
  'existing-coverage/success',
];

export const conciergeNavigationSteps = [
  'about/basics',
  'about/details',
  'assets/overview',
  'assets/details',
  'existing-coverage',
  'existing-coverage/eligibility',
  'request',
];


export const defaultConciergeAnswers: ConciergeAnswers = {
  about: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    civilStatus: '',
    profession: '',
    passions: '',
    strategicReview: null,
    estatePlanning: null,
    kids: [],
  },
  assetItems: [],
  assets: {
    netWorth: {
      netWorth: '',
      liquidPercentage: '',
    },
    homes: [],
    vehicles: {
      vehicles: [],
      membersCount: '',
    },
    watercraft: [],
    valuables: [],
    aircraft: [],
    business: {
      value: '',
      coOwners: true,
    },
  },
  coverage: {
    existingCoverage: {
      'Home Insurance': false,
      'Auto Insurance': false,
      'Valuables Insurance': false,
      'Term Life Insurance': false,
      'Whole Life Insurance': false,
      'Umbrella Liability Insurance': false,
      'Disability Insurance': false,
    },
    carrier: '',
    dataImport: null,
  },
  eligibility: {
    bankruptcy: null,
    judgement: null,
    fraud: null,
    declinedCoverage: null,
  },
};

export const conciergeMobileSections = ['About You', 'Your Assets', 'Existing Coverage', 'request'];

export const conciergeSections = ['about', 'assets', 'existing-coverage', 'request'];

export const defaultConciergeMapping: Array<SectionsNavigationMapping> = [
  {
    sectionName: 'About You',
    identifier: 'about',
    steps: [],
  },
  {
    sectionName: 'Your Assets',
    identifier: 'assets',
    steps: [
      {
        name: 'Overview',
        steps: ['assetsoverview'],
      },
      {
        name: 'Details',
        steps: ['assetsdetails'],
      },
    ],
  },
  {
    sectionName: 'Existing Coverage',
    identifier: 'existing-coverage',
    steps: [],
  },
  {
    sectionName: 'Request a call',
    identifier: 'request',
    steps: [],
  },
];

export const conciergeMinVehicleYear = 1940;
export const conciergeMaxMembersCount = 11;

export const conciergeVehicleTypes = ['Car', 'Motorcycle', 'Off-Road', 'Other'];

export const valuableItems = [
  'Jewelry',
  'Fine Art',
  'Musical Instruments',
  'Stamps',
  'Coins',
  'Wine',
  'Guns',
  'Furs',
  'Collectibles',
  'Crypto',
  'NFTs',
  'Other',
  'None',
];

export const aircraftItems = ['Turboprops', 'Jet', 'Helicopter', 'Other', 'None'];

export const watercraftItems = [
  'Runabouts',
  'Ski boat',
  'Fishing boat',
  'Sailboat',
  'Cabin cruiser',
  'Pontoon boat',
  'Other',
  'None',
];

export const addressDescriptionOptions = ['Primary residence', 'Vacation home', 'Rental property', 'Other'];
export const eligibilityContent: {
  name: 'bankruptcy' | 'judgement' | 'fraud' | 'declinedCoverage';
  question: string;
}[] = [
    {
      name: 'bankruptcy',
      question:
        'Have you had a foreclosure, repossession or bankruptcy, or filed for bankruptcy within the past 5 years?',
    },
    {
      name: 'judgement',
      question: 'Have you had a judgment or lien during the past five years?',
    },
    {
      name: 'fraud',
      question:
        'Have you been indicted for or convicted of any fraud, bribery, arson, or any other arson-related crime in connection with your or any other property within the past 5 years (10 years if you are a resident of RI)?',
    },
    {
      name: 'declinedCoverage',
      question:
        'Has your insurance coverage been declined, lapsed, cancelled or non-renewed within the last 3 years?',
    },
  ];

export const eligibilityDisclaimer =
  'We will also pull an Insurance Risk Score which involves pulling your credit but it does not affect your Credit Score since it is for insurance purposes.';

export const CONCIERGE_START_PAGE = 'about/basics';
