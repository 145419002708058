import { useFormik } from 'formik';
import _ from 'lodash';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { genderOptions } from '../../../../../constants';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import { UserContext } from '../../../../../context/UserContext';
import { allFieldsValid } from '../../../../../services/validation';
import { yupValidations } from '../../../../../services/yupValidations';
import { userInfoSchema } from '../../../../schemas/conciergeSchemas';
import BirthdayInput from '../../../birthday-input/BirthdayInput';
import PrimaryButton from '../../../buttons/PrimaryButton';
import InputField from '../../../forms/InputField/InputField';
import PhoneField from '../../../forms/PhoneField/PhoneField';
import SelectField from '../../../select-field/SelectField';
import './about-you-screens.scss';

const UserInfo = () => {
  const { isMobile, partnerName } = useContext(UserContext);
  const { userAnswers, setUserAnswers, setLastStep } = useContext(ConciergeContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: userAnswers?.about?.firstName,
      lastName: userAnswers?.about?.lastName,
      email: userAnswers?.about?.email,
      phone: userAnswers?.about?.phone,
      dob: userAnswers?.about?.dob,
      gender: userAnswers?.about?.gender,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: userInfoSchema,
    onSubmit: () => { },
  });
  const { values, setFieldValue, handleChange, handleBlur, errors, touched, setErrors } = formik;

  const img = (partner: string) => {
    const NameImage = require(`../../../../../assets/images/partners/${partner}/afi/mobile/name.svg`).default;
    return <NameImage />;
  };

  const handleSubmit = () => {
    const userAnswersCopy = { ...userAnswers };
    _.set(userAnswersCopy, ['about'], { ...userAnswers?.about, ...values });
    setUserAnswers(userAnswersCopy);
    setLastStep('about/basics');
    navigate('/concierge/about/details');
  };

  return (
    <div className="about-you-screen-container">
      <h1 className="form-title">Tell us about yourself: </h1>
      {isMobile && <div className="about-you-image">{img(partnerName)}</div>}

      <div className={`about-you-field-container ${!isMobile ? 'short-container' : ''}`}>
        <div className="short-field">
          <InputField
            placeholder="First Name"
            name="firstName"
            type="text"
            value={values.firstName}
            setErrors={setErrors}
            errors={touched.firstName ? errors : {}}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className={`about-you-field-container ${!isMobile ? 'short-container' : ''}`}>
        <div className="short-field">
          <InputField
            placeholder="Last Name"
            name="lastName"
            type="text"
            value={values.lastName}
            setErrors={setErrors}
            errors={touched.lastName ? errors : {}}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="about-you-split-fields-wrapper">
        <div className="about-you-field-container">
          <p className="regular-text-styling">
            {isMobile ? (
              <span>My email is</span>
            ) : (
              <span>
                An <b>email</b> we can contact you through is
              </span>
            )}
          </p>
          <InputField
            placeholder="email@example.com"
            name="email"
            type="email"
            inputMode="email"
            value={values.email}
            setErrors={setErrors}
            errors={touched.email ? errors : {}}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="about-you-field-container">
          <p className="regular-text-styling">My phone </p>
          <PhoneField name="phone" placeholder="301-555-1930" formikConfig={formik} />
        </div>
      </div>
      <div className="about-you-field-container birthday">
        <p className="regular-text-styling">Birthday </p>
        <BirthdayInput
          defaultData={values.dob}
          onChange={(value) => {
            setFieldValue('dob', value);
          }}
          customValidation={yupValidations.ageMinMax(18, 70)}
        />
      </div>
      <div className={`about-you-field-container ${!isMobile ? 'short-container' : ''}`}>
        <p className="regular-text-styling">My gender is </p>
        <div className="short-field">
          <SelectField
            name="gender"
            value={values.gender}
            options={genderOptions}
            handleChange={handleChange}
            placeholder="Gender"
          />
        </div>
      </div>

      <div className="button-container">
        <PrimaryButton text="Next" disabled={allFieldsValid(values, errors)} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default UserInfo;
