import React, { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { FlowDisclaimer } from '../../common/flow-disclaimer/FlowDisclaimer';
import Header from '../../common/Header/Header';
import SectionsProgressBarMobile from '../../common/progress-bar/SectionsProgressBarMobile/SectionsProgressBarMobile';
import ProgressBar from '../../common/progress-bar/ProgressBar/ProgressBar';
import './question-page-layout.scss';
import TimedErrorBanner from '../../common/error-banner/ErrorBanner';
import SectionsProgressBarDesktop from '../../common/progress-bar/SectionsProgressBarDesktop/SectionsProgressBarDesktop';
import { SectionsNavigationMapping } from '../../interfaces/CommonInterfaces';
import ConciergeHeader from '../../common/concierge-header/concierge-header';
const AldenImg = require(`../../../pages/concierge/alden.svg`).default;
const HarmonicImg = require(`../../../pages/concierge/harmonic.svg`).default;
const InfoIcon = require('../../../pages/concierge/info-black.svg').default;

type LayoutProps = {
  disclaimerContent: React.ReactNode;
  isAFIStyle: Boolean;
  headerInfo: {
    showIcon?: boolean;
    onIconClick?: Function;
  };
  progressBarInfo: {
    sectionsMobile?: string[];
    sectionsDesktop?: string[];
    sectionsMapping?: SectionsNavigationMapping[];
    stepId?: string;
    sectionId?: string;
    progress?: number;
    totalSteps?: number;
    currentStep?: number;
  };
  stepContent: React.ReactNode;
  customCss?: string;
  filledSection?: string | null;
};

const QuestionPageLayout = ({
  disclaimerContent,
  isAFIStyle,
  headerInfo,
  progressBarInfo,
  stepContent,
  filledSection,
  customCss,
}: LayoutProps) => {
  const { headerContent, showDisclaimer, isMobile, showErrorBanner, setShowErrorBanner } =
    useContext(UserContext);
  return (
    <>
      <ConciergeHeader />
      <div className={`layout-container ${customCss ? customCss : ''}`}>

        {showErrorBanner && (
          <TimedErrorBanner
            closeFunction={setShowErrorBanner}
            delay={3000}
            message="Uh oh! Something unexpected occurred. We were not able to process your request."
          />
        )}
        {isMobile &&
          (isAFIStyle ? (
            <SectionsProgressBarMobile
              sections={progressBarInfo.sectionsMobile || []}
              sectionId={progressBarInfo.sectionId || ''}
              progress={progressBarInfo.progress || 0}
            />
          ) : (
            <div className="default-progress-bar">
              <ProgressBar
                totalSteps={progressBarInfo.totalSteps || 0}
                currentStep={progressBarInfo.currentStep || 0}
              />
            </div>
          ))}
        <div className={`${isAFIStyle ? 'layout-content-wrapper' : ''}`}>
          {!isMobile && isAFIStyle && (
            <SectionsProgressBarDesktop
              sections={progressBarInfo.sectionsDesktop || []}
              sectionsMapping={progressBarInfo.sectionsMapping || []}
              currentStep={progressBarInfo.stepId || ''}
              currentSection={progressBarInfo.sectionId || ''}
              fillSection={filledSection ? filledSection : null}
            />
          )}
          <div className="layout-content">{stepContent}</div>
        </div>
        {showDisclaimer && (
          <FlowDisclaimer subtitle="Disclaimer" headerContent={headerContent} content={disclaimerContent} />
        )}
      </div>
    </>
  );
};

export default QuestionPageLayout;
