import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import {
  CONCIERGE_START_PAGE,
  canopyNavigationSteps,
  conciergeNavigationSteps,
  defaultConciergeAnswers,
} from '../constants/ConciergeConstants';
import { ConciergeAnswers } from '../components/interfaces/ConciergeInterfaces';
import { getCanopyCarriers, getCanopyTOS } from '../api/apiFunctions';
import { UserContext } from './UserContext';

export const ConciergeContext = createContext(
  {} as {
    userAnswers: ConciergeAnswers;
    setUserAnswers: Dispatch<SetStateAction<ConciergeAnswers>>;
    canopyData: {};
    setCanopyData: Dispatch<SetStateAction<{}>>;
    isCanopy: boolean;
    setIsCanopy: Dispatch<SetStateAction<boolean>>;
    setSteps: Dispatch<SetStateAction<string[]>>;
    steps: string[];
    lastStep: string;
    setLastStep: Dispatch<SetStateAction<string>>;
    conciergeInsurer: { name: string; carrier_id: string; homepageUrl: string };
    setConciergeInsurer: Dispatch<SetStateAction<{ name: string; carrier_id: string; homepageUrl: string }>>;
    carrierList: {
      status: 'error' | 'success' | 'loading';
      data: any[];
    };
    setCarrierList: Dispatch<
      SetStateAction<{
        status: 'error' | 'success' | 'loading';
        data: any[];
      }>
    >;
    canopyTOS: string;
  },
);

const ConciergeContextProvider = ({ children }: { children: ReactNode }) => {
  const { setShowErrorBanner } = useContext(UserContext);
  const [userAnswers, setUserAnswers] = useState(
    JSON.parse(localStorage.getItem('conciergeAnswers') || JSON.stringify(defaultConciergeAnswers)) ||
    defaultConciergeAnswers,
  );
  const [canopyData, setCanopyData] = useState(
    JSON.parse(localStorage.getItem('conciergeCanopyData') || '{}') || {},
  );

  const [lastStep, setLastStep] = useState(localStorage.getItem('lastConciergeStep') || CONCIERGE_START_PAGE);
  const [steps, setSteps] = useState([...conciergeNavigationSteps]);

  const [isCanopy, setIsCanopy] = useState(JSON.parse(localStorage.getItem('isConciergeCanopy') || 'false'));
  const [canopyTOS, setCanopyTOS] = useState('');

  const [conciergeInsurer, setConciergeInsurer] = useState(
    JSON.parse(localStorage.getItem('concierge_insurer') as string) || {
      name: '',
      carrier_id: '',
      homepageUrl: '',
    },
  );
  const [carrierList, setCarrierList] = useState<{
    status: 'error' | 'success' | 'loading';
    data: any[];
  }>({
    status: 'loading',
    data: [],
  });

  const fetchTOS = async () => {
    try {
      const res = await getCanopyTOS();
      setCanopyTOS(res.data.terms_html);
    } catch {
      setShowErrorBanner(false);
    }
  };

  const getCarrierList = async () => {
    setCarrierList({
      status: 'loading',
      data: [],
    });
    try {
      const data = await getCanopyCarriers();
      setCarrierList({
        status: 'success',
        data: data.data,
      });
    } catch (error) {
      setShowErrorBanner(true);
      setCarrierList({
        status: 'error',
        data: [],
      });
    }
  };

  useEffect(() => {
    !canopyTOS && fetchTOS();
    carrierList.status !== 'success' && getCarrierList();
  }, []);

  useEffect(() => {
    localStorage.setItem('conciergeAnswers', JSON.stringify(userAnswers));
  }, [userAnswers]);

  useEffect(() => {
    localStorage.setItem('conciergeCanopyData', JSON.stringify(canopyData));
  }, [canopyData]);

  useEffect(() => {
    localStorage.setItem('lastConciergeStep', lastStep);
  }, [lastStep]);

  // If canopy is available, navigation steps should be updated to accept all possible canopy steps
  // Local redirect logic for the canopy part should still be handled by the canopy component
  useEffect(() => {
    localStorage.setItem('isConciergeCanopy', JSON.stringify(isCanopy));
  }, [isCanopy]);

  return (
    <ConciergeContext.Provider
      value={{
        userAnswers,
        setUserAnswers,
        canopyData,
        setCanopyData,
        isCanopy,
        setIsCanopy,
        steps,
        lastStep,
        setLastStep,
        carrierList,
        setCarrierList,
        conciergeInsurer,
        setConciergeInsurer,
        setSteps,
        canopyTOS,
      }}
    >
      {children}
    </ConciergeContext.Provider>
  );
};
export default ConciergeContextProvider;

export const useConcierge = () => {
  return useContext(ConciergeContext);
};
