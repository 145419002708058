import { useContext, useEffect, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
// Images
import { useNavigate } from 'react-router';
import { AddInsuranceContext } from '../../../../context/AddInsuranceContext';
import { UserContext } from '../../../../context/UserContext';
import { useFormik } from 'formik';
import { object, date } from 'yup';
import { allFieldsValid, errorMessages } from '../../../../services/validation';
import { userDobImages } from '../../../../constants/AddConstants';

import DateField from '../../forms/DateField/DateField';
import { isValidAge } from '../../../../services/helpers/CommonHelpers';

export const UserDob = () => {
  const { updateAddInsuranceStep } = useContext(AddInsuranceContext);
  const { userInfo, setUserInfo, device, partnerName } = useContext(UserContext);
  const [ageError, setAgeError] = useState(false);

  const navigate = useNavigate();

  const userInfoSchema = object({
    dob: date().required('').typeError('Invalid Date'),
  });

  const formik = useFormik({
    initialValues: {
      dob: userInfo.dob,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: userInfoSchema,
    onSubmit: () => { },
  });

  const { values, errors, touched, setFieldTouched, setErrors, setFieldValue } =
    formik;

  useEffect(() => {
    setAgeError(values.dob.length === 10 && !isValidAge(values.dob));
  }, [values]);

  const formContent = {
    header: 'What\'s your date of birth?',
    buttonTxt: 'Continue',
    disabledButton:
      allFieldsValid(values, errors) || ageError || values.dob.length !== 10,
    Img: userDobImages(partnerName, device),
    onSubmit: () => {
      setUserInfo({ ...userInfo, dob: values.dob });
      updateAddInsuranceStep('gender');
      navigate('/accidental-death/gender');
    },
    fields: [
      <p className="birthday-error">{ageError ? errorMessages.age : ' '}</p>,
      <DateField
        value={values.dob}
        setValue={(e: any) => {
          setFieldValue('dob', e);
        }}
        errors={touched['dob'] ? errors : {}}
        setErrors={setErrors}
        setFieldTouched={setFieldTouched}
        formik={true}
        placeholder='MM/DD/YYYY*'
        className={ageError ? 'input-error birthday' : ''}
        hasRequiredMessage={true}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disabledButton}
        cardImage={formContent.Img}
        className="birthday"
      />
    </>
  );
};
