import { RecommendationInterface } from '../../interfaces/VehicleInterfaces';
import PrimaryButton from '../buttons/PrimaryButton';
import './brand-focus-card.scss';

const BrandFocusCard = (props: { brandInfo: RecommendationInterface | null }) => {
  const { brandInfo } = props;
  return (
    <div className="brand-focus-card">
      <div className="brand-focus-image" style={{ backgroundImage: `url(${brandInfo?.logoUrl})` }}></div>
      <div className="brand-focus-content-container">
        <div className="brand-focus-descritpion">{brandInfo?.title}</div>
        {(brandInfo?.bulletedDescription?.length || 0) > 0 && (
          <ul
            className={`brand-focus-points ${(brandInfo?.bulletedDescription?.length || 0) >= 4 ? 'show-border' : ''
              }`}
          >
            {brandInfo?.bulletedDescription.map((description: string, idx: any) => {
              return <li key={idx}><span>{description}</span></li>;
            })}
          </ul>
        )}
        <div className="brand-focus-button-container">
          <a href={brandInfo?.clickUrl} target="_blank" rel="noreferrer noopener">
            <PrimaryButton text="View Rate" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BrandFocusCard;
