import { string } from 'yup';
import { generateSelectOptionsArray } from '../../../services/helpers/AFIHelpers';
import { questionBuilder } from '../../../services/helpers/AFIQuestionBuilder';
import { yupValidations } from '../../../services/yupValidations';
import { closedQuestionBuilder } from '../../../services/helpers/AFIQuestionBuilder';

const insuranceCarrierOptions = { First: 'First', Second: 'Second' };
export const insuranceCarrier = questionBuilder(
  'insuranceCarrier',
  'Who is your carrier?',
  'Who is your carrier?',
  string().oneOf(Object.keys(insuranceCarrierOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(insuranceCarrierOptions),
);

const coveragesTowingOptions = { None: 'None', Covered: 'Covered' };
export const coveragesTowing = questionBuilder(
  'coveragesTowing',
  'Towing & Labor',
  'Towing & Labor',
  string().oneOf(Object.keys(coveragesTowingOptions)).required('This can’t be left blank'),
  'select',
  'Select',
  generateSelectOptionsArray(coveragesTowingOptions),
  'left',
);

const coveragesRentalOptions = {
  None: 'Not Covered',
  '20/600': '20/600',
  '30/900': '30/900',
  '40/1200': '40/1200',
  '50/1500': '50/1500',
  Covered: 'Covered',
};
export const coveragesRental = questionBuilder(
  'coveragesRental',
  'Rental Reimbursement',
  'Rental Reimbursement',
  string().oneOf(Object.keys(coveragesRentalOptions)).required('This can’t be left blank'),
  'select',
  'Select',
  generateSelectOptionsArray(coveragesRentalOptions),
  'left',
);

const coveragesLoanLeaseGapOptions = { None: 'None', Covered: 'Covered' };
export const coveragesLoanLeaseGap = questionBuilder(
  'coveragesLoanLeaseGap',
  'Loan or lease gap',
  'Loan or lease gap',
  string().oneOf(Object.keys(coveragesLoanLeaseGapOptions)).required('This can’t be left blank'),
  'select',
  'Select',
  generateSelectOptionsArray(coveragesLoanLeaseGapOptions),
  'left',
);

const bodilyInjuryLimitsOptions = {
  '25/50': '$25k/$50k (state minimum)',
  '50/100': '$50k/$100k',
  '100/300': '$100k/$300k',
  None: "I don't know",
};
export const bodilyInjuryLimits = questionBuilder(
  'priorInsuranceBodilyInjuryLimits',
  'What are your current bodily injury limits?',
  'What are your current bodily injury limits?',
  yupValidations.optionRequired,
  'radio',
  '25/50',
  generateSelectOptionsArray(bodilyInjuryLimitsOptions),
);

const creditScoreOptions = {
  Superior: '750+',
  Excellent: '700-749',
  Good: '650-699',
  Fair: '<649',
};
export const creditScore = questionBuilder(
  'creditScore',
  'Select your credit score',
  'Select your credit score',
  yupValidations.optionRequired,
  'radio',
  'Superior',
  generateSelectOptionsArray(creditScoreOptions),
);

const paymentFrequencyOptions = {
  Monthly: 'Monthly',
  'Semi-Annual': 'Semi-Annual',
  Yearly: 'Yearly',
};
export const paymentFrequency = questionBuilder(
  'paymentFrequency',
  'Payment Frequency',
  'Payment Frequency',
  string().oneOf(Object.keys(paymentFrequencyOptions)).required('This can’t be left blank'),
  'multi-field-select',
  undefined,
  generateSelectOptionsArray(paymentFrequencyOptions),
  'top',
);

export const premium = questionBuilder(
  'premium',
  'Premium',
  '',
  yupValidations.numberRequired,
  'multi-field-input',
  undefined,
  undefined,
  'top',
);

export const policyStartDate = questionBuilder(
  'policyStartDate',
  'Policy Start Date',
  'MM/DD/YYYY',
  yupValidations.policyDate,
  'date',
);

const insuranceDurationOptions = {
  ContinuousLT6M: 'Less than 6 months',
  ContinuousGT6MLT1Y: '6 months to less than 1 year',
  Continuous1: '1 to less than 2 years',
  Continuous2: '2 to less than 3 years',
  Continuous3: '3 to less than 4 years',
  Continuous4: '4 to less than 5 years',
  Continuous5: '5 to less than 6 years',
  Continuous6: '6 or more years',
  None: 'No Prior Insurance',
};

export const insuranceDuration = questionBuilder(
  'insuranceDuration',
  'When were you last uninsured?',
  'Select',
  string().oneOf(Object.keys(insuranceDurationOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(insuranceDurationOptions),
);

export const priorPolicyExpDate = questionBuilder(
  'priorPolicyExpDate',
  'Prior Policy Expiration Date',
  'MM/DD/YYYY',
  string(),
  'date',
);

const canceledBeforeOptions = {
  Yes: 'Yes',
  No: 'No',
};

export const canceledBefore = questionBuilder(
  'canceledBefore',
  'Have you ever been canceled, declined, or non-renewed for insurance?',
  'Have you ever been canceled, declined, or non-renewed for insurance?',
  string().oneOf(Object.keys(canceledBeforeOptions)).required('This can’t be left blank'),
  'radio',
  'No',
  generateSelectOptionsArray(canceledBeforeOptions),
);

export const allDriversListed = closedQuestionBuilder(
  'Are all drivers in the household listed on this quote?',
  'Are all drivers in the household listed on this quote?',
  'yes',
  'all_drivers_listed',
);

export const bodilyInjuryOptions = {
  '25000 / 50000': '$25/$50k',
  '30000 / 60000': '$30/$60k',
  '50000 / 100000': '$50/$100k',
  '100000 / 300000': '$100/$300k',
  '250000 / 500000': '$250/$500k',
  '500000 / 500000': '$500k',
};
export const primaryBodilyInjuryLimit = questionBuilder(
  'primaryBodilyInjuryLimit',
  'Bodily Injury (BI) limit',
  'Select',
  string().oneOf(Object.keys(bodilyInjuryOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(bodilyInjuryOptions),
  'left',
);

export const uninsuredBodilyInjuryLimit = questionBuilder(
  'uninsuredBodilyInjuryLimit',
  'Bodily Injury (BI) limit',
  'Select',
  string().oneOf(Object.keys(bodilyInjuryOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(bodilyInjuryOptions),
  'left',
);

export const propertyDamageOptions = {
  25000: '$25,000',
  35000: '$35,000',
  50000: '$50,000',
  100000: '$100,000',
  150000: '$150,000',
  200000: '$200,000',
  250000: '$250,000',
  300000: '$300,000',
  400000: '$400,000',
  500000: '$500,000',
  750000: '$750,000',
};

export const primaryPropertyDamage = questionBuilder(
  'primaryPropertyDamage',
  'Property damage',
  'Select',
  string().oneOf(Object.keys(propertyDamageOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(propertyDamageOptions),
  'left',
);

export const uninsuredPropertyDamage = questionBuilder(
  'uninsuredPropertyDamage',
  'Property damage',
  'Select',
  string().oneOf(Object.keys(propertyDamageOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(propertyDamageOptions),
  'left',
);

export const medPay = questionBuilder(
  'medPay',
  'Medical Payments',
  'Select',
  string().oneOf([]).required('This can’t be left blank'),
  'select',
  undefined,
  [],
  'left',
);

export const pip = questionBuilder(
  'pip',
  'Personal Injury Protection',
  'Select',
  string().oneOf([]).required('This can’t be left blank'),
  'select',
  undefined,
  [],
  'left',
);

export const underinsuredMotoristsBodilyInjuryOptions = {
  None: 'None',
  '15,000/30,000': '15000/30000',
  '25,000/50,000': '25000/50000',
  '30,000/60,000': '30000/60000',
  '50,000/100,000': '50000/100000',
  '100,000/300,000': '100000/300000',
  '250,000/500,000': '250000/500000',
  '500,000/500,000': '500000/500000',
};
export const underinsuredMotoristsBodilyInjury = questionBuilder(
  'underinsuredMotoristsBodilyInjury',
  'Underinsured body injury',
  'Select',
  string().oneOf(Object.keys(underinsuredMotoristsBodilyInjuryOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(underinsuredMotoristsBodilyInjuryOptions),
  'left',
);

export const comprehensiveDeductibleOptions = {
  None: 'None',
  100: '100',
  200: '200',
  250: '250',
  300: '300',
  400: '400',
  500: '500',
  750: '750',
  1000: '1000',
  2000: '2000',
  2500: '2500',
  5000: '5000',
};
export const comprehensiveDeductible = questionBuilder(
  'comprehensiveDeductible',
  'Comprehensive Deductible',
  'Select',
  string().oneOf(Object.keys(comprehensiveDeductibleOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(comprehensiveDeductibleOptions),
  'left',
);
export const collisionDeductibleOptions = {
  None: 'None',
  100: '100',
  200: '200',
  250: '250',
  300: '300',
  400: '400',
  500: '500',
  750: '750',
  1000: '1000',
  2000: '2000',
  2500: '2500',
  5000: '5000',
};
export const collisionDeductible = questionBuilder(
  'collisionDeductible',
  'Collision Deductible',
  'Select',
  string().oneOf(Object.keys(collisionDeductibleOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(collisionDeductibleOptions),
  'left',
);
