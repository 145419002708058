import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import './quote-card.scss';
import ChevronRight from '../../../../assets/common/chevron-right.svg';
import QuoteInfo from '../QuoteInfo/QuoteInfo';
import SecondaryButton from '../../buttons/SecondaryButton';
import { AfiQuoteType } from '../../../interfaces/AFIInterfaces';

type QuoteCardProps = {
  logo: string;
  price: string;
  product: AfiQuoteType;
};
const AfiQuoteCard = ({ logo, price, product }: QuoteCardProps) => {
  const { isMobile, device } = useContext(UserContext);
  const [showQuoteInfoModal, setShowQuoteInfoModal] = useState(false);
  return (
    <div className="afi-quote-card-container">
      <div className="afi-quote-card-top">
        {device !== 'desktop' && (
          <div className="afi-quote-card-logo" style={{ backgroundImage: `url(${logo || ''})` }}>
            {!logo && product?.insurer?.name}
          </div>
        )}
        {isMobile && (
          <ChevronRight
            onClick={() => {
              setShowQuoteInfoModal(true);
            }}
          />
        )}
        {device === 'tablet' && (
          <SecondaryButton
            text="View details"
            onClick={() => {
              setShowQuoteInfoModal(true);
            }}
          />
        )}
      </div>
      {device !== 'desktop' && (
        <div className="afi-quote-price">
          <span className="price">{price}</span>
          <span className="description"> monthly</span>
        </div>
      )}
      {showQuoteInfoModal && <QuoteInfo setShowModal={setShowQuoteInfoModal} product={product} logo={logo} />}
      {device === 'desktop' && (
        <QuoteInfo setShowModal={setShowQuoteInfoModal} product={product} logo={logo} />
      )}
    </div>
  );
};

export default AfiQuoteCard;
