import { useContext, useState } from 'react';
import './add.scss';
import { getSafetyNetPolicyInfo, introContent } from './constants';
import VerticalPageLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { introImages, IntroPageModal, introTitles } from '../../constants/AddConstants';
import { getADDisclaimer } from '../../services/helpers/AddHelpers';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { DisclaimerTable } from '../../components/common/flow-disclaimer/DisclaimerList';
import { titleCaseString } from '../../services/helpers/CommonHelpers';

const AddIntro = () => {
  const navigate = useNavigate();
  const { setProgressStep } = useContext(AddInsuranceContext);
  const { device, partnerName, setShowDisclaimer, headerContent, showDisclaimer, isAmalgamated } =
    useContext(UserContext);
  const [modalInfo, setModalInfo] = useState('list');

  const openADDInfoModal = () => {
    setModalInfo('list');
    setShowDisclaimer(true);
  };

  const handleClick = () => {
    navigate('/accidental-death/name');
    setProgressStep(1);
  };

  return (
    <>
      <div className="add-intro-page with-hero">
        <VerticalPageLayout
          {...introContent}
          onIconClick={() => {
            setModalInfo('table');
            setShowDisclaimer(true)}}
          sameRow={device === 'desktop'}
          headerContent={headerContent}
          showInfoIcon={true}
          img={introImages(partnerName, device)}
          title={introTitles[device]}
          subtitle="Act now on this member benefit"
          description={
            <>
              <p className="vertical-layout-description">
                Good things come to those who prepare. And as a{' '}
                {partnerName !== 'default' ? titleCaseString(partnerName) : ''} customer, you can now claim a
                $10,000 Accidental Death & Dismemberment policy at no cost to you.{' '}
              </p>
              <p className="vertical-layout-description">
                AD&D insurance helps ensure financial protection for your loved ones if you suffer from a
                serious accident.{' '}
                <span onClick={openADDInfoModal} className="vertical-link">
                  Learn More
                </span>
              </p>
            </>
          }
          content={
            <>
              <div className="add-button-container">
                <PrimaryButton text="Enroll Now" onClick={handleClick} />
              </div>
            </>
          }
        />
        <p
          className="add-intro-disclaimer"
          dangerouslySetInnerHTML={{ __html: getADDisclaimer(partnerName) }}
        ></p>
      </div>
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          subtitle={modalInfo === 'list' ? 'Carshield Cares' : ''}
          content={
            <>
              {modalInfo === 'list' ? (
                <IntroPageModal
                  toggleFunction={() => setModalInfo('table')}
                  enrollFunction={() => {
                    setShowDisclaimer(false);
                    handleClick();
                  }}
                />
              ) : (
                <>
                  <DisclaimerTable info={getSafetyNetPolicyInfo(isAmalgamated)} />
                  {!isAmalgamated && (
                    <>
                      <p className="disclaimer-table-text">
                        FOR NY RESIDENTS: AD&D policy provides ACCIDENT insurance only. It does NOT provide
                        basic hospital, basic medical or major medical insurance as defined by the New York
                        State Department of Financial Services.
                      </p>
                      <p className="disclaimer-table-text">
                        IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
                        <br /> <br />
                        Accidental Death & Dismemberment insurance coverage is issued by The Prudential
                        Insurance Company of America, Newark, NJ. The Booklet-Certificate contains all details
                        including any policy exclusions, limitations and restrictions, which may apply. CA COA
                        #1179, NAIC #68241. Contract Series: 83500.
                        <br />
                        1061616-00001-00
                      </p>
                    </>
                  )}
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default AddIntro;
