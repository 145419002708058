import {
  HavenLinkResponseInterface,
  RecommendedValuesInterface,
} from '../../../../interfaces/LifeInterfaces';
import './life-recommendation-card.scss';
import PrimaryButton from '../../../buttons/PrimaryButton';
import { defaultHavenUrl } from '../../../../../constants/LifeConstants';
import { formatPrice } from '../../../../../services/helpers/CommonHelpers';
import { useContext } from 'react';
import { UserContext } from '../../../../../context/UserContext';

type LifeRecommendationType = {
  recommendedValues: RecommendedValuesInterface;
  showExamDisclaimer: boolean;
  havenRedirectInfo: HavenLinkResponseInterface | null;
};

const LifeRecommendationCard = ({
  recommendedValues,
  showExamDisclaimer,
  havenRedirectInfo,
}: LifeRecommendationType) => {
  const { device } = useContext(UserContext);
  const { termLength, monthlyCost, policyAmount } = recommendedValues;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.target.submit();
  };

  return (
    <div className="life-rec-card">
      <div className="life-rec-content-container">
        <div
          className={`life-rec-amounts ${showExamDisclaimer ? 'disclaimer' : ''
            }`}
        >
          <div className="life-rec-field">
            <label htmlFor="rec-years">Term: </label>
            <span>{termLength} Years</span>
          </div>
          <div className="life-rec-field">
            <label htmlFor="rec-amount">Policy Amount: </label>
            <span>${policyAmount.toLocaleString()}</span>
          </div>
          <div className="life-rec-field">
            <label htmlFor="rec-cost">Cost: </label>
            <span>${formatPrice(monthlyCost)}/mo</span>
          </div>

          {showExamDisclaimer && (
            <p className="life-rec-exam">
              No medical exam required for this policy!
            </p>
          )}
        </div>

        <form
          target="_blank"
          onSubmit={handleSubmit}
          action={havenRedirectInfo?.endpoint || defaultHavenUrl}
          method="post"
          id="haven-form"
        >
          <input type="hidden" name="token" value={havenRedirectInfo?.token} />
          <input
            type="hidden"
            name="source"
            value={havenRedirectInfo?.source}
            required
          />
          <input
            type="hidden"
            name="version"
            value={havenRedirectInfo?.version}
            required
          />
        </form>
        <PrimaryButton
          text="Continue"
          form="haven-form"
          type={havenRedirectInfo?.endpoint ? 'submit' : undefined}
        />
      </div>
      {device === 'desktop' && <div className="life-rec-card-image"></div>}
    </div>
  );
};

export default LifeRecommendationCard;
