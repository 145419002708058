import { ToggleButton } from 'react-bootstrap';
import './radio-question.scss';

type RadioFieldProps = {
  name: string;
  questionText: string;
  boldTitle?: boolean;
  currentValue?: boolean | null;
  onChange: Function;
};

/**
 * Component that generates a question with yes/no answers
 * @param name the name of the field in the Formik values object
 * @param questionText Question to be displayed
 * @param boldTitle Boolean. Will turn the question text bold
 * @param currentValue Is values[name] from Formik. Can be a boolean or undefined.
 * @param onChange setFieldValue from Formik
 */

const RadioQuestionField = ({
  name,
  questionText,
  currentValue,
  onChange,
  boldTitle = false,
}: RadioFieldProps) => {
  return (
    <div className="radio-question-container">
      <p className={`radio-question-text ${boldTitle ? 'bold' : ''}`}>{questionText}</p>
      <div className="radio-buttons-container">
        {[
          { display: 'Yes', value: true },
          { display: 'No', value: false },
        ].map((answer, index) => {
          const handleClick = () => onChange(name, answer.value);
          const isChecked = currentValue === answer.value;
          return (
            <div key={`bttn-${index}`} className="radio-button" onClick={handleClick}>
              <ToggleButton
                value={answer.display}
                type="radio"
                onChange={handleClick}
                checked={isChecked}
                id={name}
              />
              <label className="radio-button-label">{answer.display}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioQuestionField;
