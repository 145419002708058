import { useContext, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './auto.scss';
import { getProgressStep } from './constants';
import ProgressBar from '../../components/common/progress-bar/ProgressBar/ProgressBar';
import { DriverCreditScore } from '../../components/common/steps/auto-insurance/driver-info/DriverCreditScoreStep';
import { DrivingHistoryStep } from '../../components/common/steps/auto-insurance/driver-info/DrivingHistoryStep';
import { HomeOwnerStep } from '../../components/common/steps/auto-insurance/driver-info/HomeownerStep';
import { ContactDetails } from '../../components/common/steps/auto-insurance/personal-info/ContactDetails';
import { CurrentlyInsured } from '../../components/common/steps/auto-insurance/user-insurance/CurrentlyInsured';
import { CurrentProvider } from '../../components/common/steps/auto-insurance/user-insurance/CurrentProvider';
import { AutoInsuranceContext } from '../../context/AutoInsuranceContext';
import { VehicleInfoStep } from '../../components/common/steps/auto-insurance/vehicle/VehicleInfo';
import { VehicleExtraStep } from '../../components/common/steps/auto-insurance/vehicle/VehicleExtra';
import { DriverExtra } from '../../components/common/steps/auto-insurance/driver-info/DriverExtra';
import { DriverAge } from '../../components/common/steps/auto-insurance/driver-info/DriverAge';
import { createBrowserHistory } from 'history';
import { UserContext } from '../../context/UserContext';
import Header from '../../components/common/Header/Header';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { getAutoDisclaimerText } from '../../services/helpers/AutoHelpers';

const AutoQuestionsPage = () => {
  const { progressStep, setProgressStep, totalSteps, setStepAnimationType } =
    useContext(AutoInsuranceContext);
  const { headerContent, setShowDisclaimer, showDisclaimer, partnerName } = useContext(UserContext);
  let { stepId } = useParams();
  let navigate = useNavigate();
  let history = createBrowserHistory({ window });

  useEffect(() => {
    const parts = stepId?.split('-') || ['default'];
    let newProgress = progressStep;
    if (!isNaN(Number(parts[parts?.length - 1]))) {
      if (parts[0] === 'model' || parts[0] === 'mileage') {
        setProgressStep(2);
        newProgress = 2;
      } else {
        setProgressStep(10);
        newProgress = 10;
      }
    } else {
      setProgressStep(getProgressStep(stepId || ''));
      newProgress = getProgressStep(stepId || '');
    }
    if (stepId === 'current-insurance') {
      setProgressStep(3);
      newProgress = 3;
    }

    if (newProgress - 1 > progressStep) {
      navigate('/auto/start');
      return;
    }
  }, [stepId]);

  useEffect(() => {
    if (progressStep === 0) {
      navigate('/auto/start');
    }
  }, []);

  useEffect(() => {
    history.listen(({ action }) => {
      action === 'POP' && setStepAnimationType('slide-in_navback');
    });
  }, [history]);

  const checkStep = useMemo(() => {
    const parts = stepId?.split('-') || ['default'];
    const index = Number(parts[parts.length - 1]) || 1;
    switch (stepId) {
      case 'model':
        return <VehicleInfoStep vehicleNumber={0} />;

      case 'model-2':
        return <VehicleInfoStep vehicleNumber={1} />;

      case 'second-vehicle':
        return <VehicleExtraStep />;

      case 'current-insurance':
        return <CurrentlyInsured />;

      case 'insurer':
        return <CurrentProvider />;

      case 'derogatories':
        return <DrivingHistoryStep />;

      case 'homeowner':
        return <HomeOwnerStep />;

      case 'credit-score':
      case 'credit-score-2':
      case 'credit-score-3':
      case 'credit-score-4':
      case 'credit-score-5':
        return <DriverCreditScore driverIndex={index - 1} />;

      case 'driver-age':
      case 'driver-age-2':
      case 'driver-age-3':
      case 'driver-age-4':
      case 'driver-age-5':
        return <DriverAge driverIndex={index - 1} />;

      case 'contact':
      case 'contact-2':
      case 'contact-3':
      case 'contact-4':
      case 'contact-5':
        return <ContactDetails driverIndex={index - 1} />;

      case 'second-driver':
      case 'second-driver-2':
      case 'second-driver-3':
      case 'second-driver-4':
      case 'second-driver-5':
        return <DriverExtra driverIndex={index - 1} />;

      default:
        break;
    }
  }, [stepId]);

  return (
    <div className={`auto-questionnaire-page`}>
      <Header headerContent={headerContent} showIcon={true} onIconClick={() => setShowDisclaimer(true)} />
      <ProgressBar currentStep={progressStep} totalSteps={totalSteps} />
      {checkStep}
      {showDisclaimer && (
        <FlowDisclaimer
          subtitle="Disclaimer"
          headerContent={headerContent}
          content={getAutoDisclaimerText(partnerName)}
        />
      )}
    </div>
  );
};

export default AutoQuestionsPage;
