import { useContext, useState } from 'react';
import MultipleOptions from '../../../../multiple-options/MultipleOptions';
import { ConciergeContext } from '../../../../../../context/ConciergeContext';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import './net-worth.scss';
import InputField from '../../../../forms/InputField/InputField';

const NetWorth = ({
  onSubmit,
}: {
  onSubmit: (newValues: { netWorth: string; liquidPercentage: string }) => void;
}) => {
  const { userAnswers } = useContext(ConciergeContext);
  const defaultAnswers = userAnswers?.assets?.netWorth || '';

  const [options, setOptions] = useState<{ [key: string]: boolean }>({
    '$500,000 - $5 million': '$500,000 - $5 million' === defaultAnswers.netWorth,
    '$5 million - $10 million': '$5 million - $10 million' === defaultAnswers.netWorth,
    '$10 million - $25 million': '$10 million - $25 million' === defaultAnswers.netWorth,
    '$25 million and more': '$25 million and more' === defaultAnswers.netWorth,
  });

  const formikConfig = useFormik({
    initialValues: {
      netWorth: defaultAnswers.netWorth,
      liquidPercentage: defaultAnswers.liquidPercentage,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: object({
      netWorth: string().oneOf(Object.keys(options)).required('Required'),
      liquidPercentage: number()
        .min(0, 'Value must be greater than 0%')
        .max(100, 'Value must be less than 100%')
        .required('Required')
        .typeError('Value must be a number'),
    }),
    onSubmit: () => { },
  });
  const { values, setFieldValue, isValid, handleChange, setErrors, errors, handleBlur, touched } =
    formikConfig;

  const onCheck = (name: string, value: boolean) => {
    const newObj = { ...options } as { [key: string]: boolean };
    Object.keys(newObj).map((item) => {
      if (item === name) newObj[item] = value;
      else newObj[item] = false;
    });
    setFieldValue('netWorth', value ? name : '');
    setOptions(newObj);
  };

  const isDisabled = !(values.liquidPercentage && values.netWorth) || !isValid ? true : false;

  return (
    <div className="net-worth-container">
      <div className="net-worth-question">
        <p className="title">What is your net worth?</p>
        <MultipleOptions options={options} onCheck={onCheck} />
      </div>
      <div className="liquid-percentage-question">
        <p className="question">
          What percentage of your net worth is illiquid? <span>(It’s ok to estimate!)</span>
        </p>

        <div className="liquid-percentage-input">
          <InputField
            name="liquidPercentage"
            value={values.liquidPercentage}
            onChange={handleChange}
            placeholder=""
            setErrors={setErrors}
            type="text"
            maxLength={3}
            errors={touched.liquidPercentage ? errors : null}
            onBlur={handleBlur}
          ></InputField>
        </div>
      </div>
      <div className="submit-button-container">
        <PrimaryButton text="Next" disabled={isDisabled} onClick={() => onSubmit(values)}></PrimaryButton>
      </div>
    </div>
  );
};

export default NetWorth;
