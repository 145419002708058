import { useState } from 'react';
import './multiple-option-section.scss';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import MultipleOptions from '../../../../multiple-options/MultipleOptions';

const MultipleOptionsSection = ({
  title,
  defaultOptions,
  defaultData,
  onSubmit,
}: {
  title: string;
  defaultOptions: string[];
  defaultData: string[];
  onSubmit: (newValues: any) => void;
}) => {
  // Turning the array of possible options and the array of answers stored in the context into:
  // {optionName: optionIsChecked}
  const defaultObject = defaultOptions.reduce((obj: { [key: string]: boolean }, item: string) => {
    obj[item] = defaultData?.includes(item);
    return obj;
  }, {});

  const [options, setOptions] = useState<{ [key: string]: boolean }>(defaultObject);

  const onCheck = (name: string, value: boolean) => {
    if (name === 'None' && value === true) {
      setOptions((prev) => {
        const newObj = { ...prev };
        Object.keys(newObj).map((item) => {
          newObj[item] = false;
        });
        return newObj;
      });
    }
    setOptions((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isDisabled = !Object.values(options).find((item) => item);

  return (
    <div className="multiple-option-section">
      <p className="subtitle">{title}</p>
      <MultipleOptions options={options} onCheck={onCheck} />
      <div className="button-container">
        <PrimaryButton
          text="Next"
          disabled={isDisabled}
          onClick={() => onSubmit(Object.keys(options).filter((item) => options[item]))}
        ></PrimaryButton>
      </div>
    </div>
  );
};

export default MultipleOptionsSection;
