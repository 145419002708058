
import './concierge-header.scss';

export default function ConciergeHeader() {
    const AldenImg = require("./logos/alden.svg").default;
    const HarmonicImg = require("./logos/harmonic.svg").default;
    return (
        <header id='header-concierge'>
            <AldenImg />
            <HarmonicImg />
        </header>
    );
}