import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import './accident.scss';
import SpartanInputsBar from '../../components/common/aflac/spartan-inputs-bar/SpartanInputsBar';
import {
  AflacLogo,
  carshieldAccidentDetails,
  CarshieldAccidentLogo,
  CarshieldAccidentMeta,
} from '../../constants/AccidentConstants';
import { UserContext } from '../../context/UserContext';
import AflacCard from '../../components/common/aflac/card/AflacCard';
import { AFLAC_DEVICES, DetailImages } from '../aflac/constants';
import { useWindowDimensions } from '../../services/hooks/useWindowDimensions';

const CarshieldAccidentInsurance = () => {
  const { device } = useContext(UserContext);
  let deviceWidth = useWindowDimensions().width;

  return (
    <div className="carshield-accident-container">
      <Helmet>
        <title>{CarshieldAccidentMeta.PAGE_TITLE}</title>
        <meta name="description" content={CarshieldAccidentMeta.META_DESCRIPTION} />
        <meta name="robots" content="nofollow"></meta>
        <meta name="googlebot" content="nofollow"></meta>
        <meta property="og:title" content={CarshieldAccidentMeta.PAGE_TITLE} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={CarshieldAccidentMeta.META_DESCRIPTION} />
        <link rel="icon" type="image/svg" href="/assets/carshield-favicon.svg" sizes="16x16" />
      </Helmet>
      <section className="header-section">
        <div className="carshield-logo"> {CarshieldAccidentLogo(deviceWidth)}</div>
        <h1 className="header-title">
          <span>with Accident Insurance from</span>
          <div>{AflacLogo(deviceWidth)}</div>
        </h1>
      </section>
      <section className="content-section">
        <h1 className="carshield-title"> Accidents Happen… Make Sure You’re Better Prepared.</h1>
        <div className="carshield-inputs-bar">
          <SpartanInputsBar baseUrl={process.env.REACT_APP_CARSHIELD_AFLAC_LINK || ''} />
        </div>
        {deviceWidth < AFLAC_DEVICES.tablet && <div className="carshield-image-mobile"></div>}
      </section>
      <section className="details-section">
        <>
          {carshieldAccidentDetails.map((elem) => (
            <AflacCard key={elem.title} {...elem} icon={DetailImages(device, elem.icon)} />
          ))}
        </>
      </section>
      <section className="disclaimer-section">
        <div className="disclaimer-compliance">
          <p>Z2201175B</p>
          <p>EXP 12/23</p>
        </div>
        <p className="disclaimer">
          Accident - Not available in New York and Virginia. In Idaho, Policies T37100ID, T37200ID, &
          T37300ID. In Oklahoma, Policy T37000OK.
          <br />
          <br />
          This is a brief product overview only. Coverage may not be available in all states. Benefits/premium
          rates may vary based on plan selected. Optional riders may be available at an additional cost. Plans
          and riders may also contain a waiting period. Refer to the exact plans and riders for benefit
          details, definitions, limitations and exclusions. For availability and costs, please contact your
          local Aflac agent/producer.
          <br />
          <br />
          Aflac’s family of insurers include Aflac, Aflac New York, Continental American Insurance Company,
          and Tier One Insurance Company. Coverage is underwritten by Tier One Insurance Company. Aflac WWHQ |
          1932 Wynnton Road | Columbus, GA 31999
        </p>
      </section>
    </div>
  );
};

export default CarshieldAccidentInsurance;
