import { Route, Routes } from 'react-router-dom';
import ConciergeContextProvider from '../context/ConciergeContext';
import ConciergeForm from '../pages/concierge/ConciergeForm';
import ConciergeIntro from '../pages/concierge/ConciergeIntro/ConciergeIntro';

const ConciergeRoutes = () => {
  return (
    <ConciergeContextProvider>
      <Routes>
        <Route path="/concierge/" element={<ConciergeIntro />} />
        <Route path="/concierge/:sectionId" element={<ConciergeForm />} />
        <Route path="/concierge/:sectionId/:stepId" element={<ConciergeForm />} />
      </Routes>
    </ConciergeContextProvider >
  );
};

export default ConciergeRoutes;
