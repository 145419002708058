import { useState, useContext, useMemo } from 'react';
import InputField from '../../../forms/InputField/InputField';
import StepComponent from '../../../step-component/StepComponent';
import './beneficiary.scss';
import { useFormik } from 'formik';
import { object, string, date } from 'yup';
import { allFieldsValid } from '../../../../../services/validation';
import SelectField from '../../../select-field/SelectField';
import { UserContext } from '../../../../../context/UserContext';
import { relationshipStatusOptions } from '../../../../../pages/add-insurance/constants';
import PrimaryButtonWithIcon from '../../../buttons/PrimaryButtonWithIcon';
import { useNavigate } from 'react-router-dom';
import DateField from '../../../forms/DateField/DateField';
import { createADDBeneficiary } from '../../../../../api/apiFunctions';
import BeneficiaryModal from '../../../modals/custom-modals/beneficiary-modal/BeneficiaryModal';
import TimedErrorBanner from '../../../error-banner/ErrorBanner';
import { AddBeneficiary, SelectedBeneficiary } from '../../../../interfaces/AddTypeInterfaces';
import AddIcon from '../../../../../assets/common/plus-sign.svg';
import MultiFieldContainer from '../../../field-container/MultiFieldContainer';
import { genderOptions } from '../../../../../constants';
import { BeneficiariesCardContainer } from './BeneficiariesCardContainer';

type BeneficiaryProps = {
  beneficiariesInfo: AddBeneficiary[];
  setBeneficiariesInfo: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  nextProgressStep: number;
  setProgressStep: Function;
  showBeneficiaryModal: boolean;
  selectedBeneficiary: SelectedBeneficiary | null;
  setShowBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<SelectedBeneficiary | null>>;
  primaryLink: string;
  secondaryLink: string;
  buttonText?: string;
  onSpecialSubmit?: Function;
};

const Beneficiary = ({
  beneficiariesInfo,
  setBeneficiariesInfo,
  nextProgressStep,
  setProgressStep,
  showBeneficiaryModal,
  setShowBeneficiaryModal,
  selectedBeneficiary,
  setSelectedBeneficiary,
  primaryLink,
  secondaryLink,
  buttonText,
  onSpecialSubmit,
}: BeneficiaryProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExtra, setIsLoadingExtra] = useState<boolean>(false);
  const [showBeneficiaryErrorBanner, setShowBeneficiaryErrorBanner] = useState<boolean>(false);
  const { authToken, setShowErrorBanner } = useContext(UserContext);
  const navigate = useNavigate();
  const beneficiarySchema = object({
    firstName: string(),
    lastName: string(),
    dob: date()
      .min(new Date('01/01/1900'), 'Invalid Date')
      .max(new Date(), 'Invalid Date')
      .typeError('Invalid Date'),
    kinship: string().required('Required Field'),
    gender: string().required('Required Field'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      kinship: '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: beneficiarySchema,
    onSubmit: () => { },
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setErrors,
    setFieldValue,
    setFieldTouched,
    handleReset,
  } = formik;

  //save beneficiary details to context
  const addNewBeneficaryToContext = (id: string) => {
    const newBeneficiary = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      gender: values.gender,
      kinship: values.kinship,
      percentage: '100%',
      id: id,
      isSelected: true,
      canBeDeleted: true,
    };
    const updatedBeneficiariesInfo = [...beneficiariesInfo, newBeneficiary];

    setBeneficiariesInfo(updatedBeneficiariesInfo);
  };

  const selectedBeneficiaries = useMemo(() => {
    return beneficiariesInfo.filter((ben) => ben.isSelected);
  }, [beneficiariesInfo]);

  const addBeneficiaryApiCall = async () => {
    return await createADDBeneficiary(values, authToken);
  };

  const handleAddExtraBeneficiary = async (e: any) => {
    setIsLoadingExtra(true);
    try {
      const response = await addBeneficiaryApiCall();
      if (response) {
        addNewBeneficaryToContext(response?.data?.id);
        //reset form fields
        handleReset(e);
      }
    } catch (err) {
      setShowErrorBanner(true);
    } finally {
      setIsLoadingExtra(false);
    }
  };

  const handleDisableButton = () => {
    if (selectedBeneficiaries.length >= 1) {
      return false;
    }

    return allFieldsValid(values, errors) || isLoading;
  };

  const formContent = {
    header: 'Who do you want to list as your beneficiary?',
    buttonTxt: buttonText || 'Continue',
    disabledButton: handleDisableButton(),
    onAPIClick: async (onSubmit: Function) => {
      //check number of empty fields
      let emptyFields = Object.values(values).reduce((sum: number, val: string) => {
        val === '' && sum++;
        return sum;
      }, 0);

      //Allow user to continue to amounts or terms page after clicking "add new person" but without adding a new beneficiary
      if (emptyFields === 5 && allFieldsValid(values, errors)) {
        onSubmit(selectedBeneficiaries.length === 1);
        return;
      }

      if (emptyFields > 0 && emptyFields < 5) {
        Object.values(values).forEach((elem) => elem === '' && setShowBeneficiaryErrorBanner(true));
        return;
      }

      if (selectedBeneficiaries.length === 4) {
        onSubmit(false);
        return;
      }

      setIsLoading(true);

      try {
        if (allFieldsValid(values, errors)) {
          return false;
        }

        if (selectedBeneficiaries.length !== 4) {
          const response = await addBeneficiaryApiCall();
          if (response) {
            addNewBeneficaryToContext(response?.data?.id);
          }
        }

        onSubmit(selectedBeneficiaries.length === 0);
      } catch (err) {
        //replace with api error banner
        setShowErrorBanner(true);
      } finally {
        setIsLoading(false);
      }
    },
    onSubmit: (flag: boolean) => {
      if (flag) {
        const beneficiaryIndex = beneficiariesInfo.findIndex((elem) => elem.isSelected);
        if (beneficiaryIndex > -1) {
          const tmp = [...beneficiariesInfo];
          tmp[beneficiaryIndex].percentage = '100%';
          setBeneficiariesInfo([...tmp]);
        }
        onSpecialSubmit && onSpecialSubmit(true);
        navigate(secondaryLink);
        setProgressStep(nextProgressStep);
        return;
      }
      onSpecialSubmit && onSpecialSubmit(false);
      navigate(primaryLink);
    },

    fields: [
      <BeneficiariesCardContainer
        beneficiaries={beneficiariesInfo}
        setBeneficiaries={setBeneficiariesInfo}
        setShowBeneficiaryModal={setShowBeneficiaryModal}
        setSelectedBeneficiary={setSelectedBeneficiary}
      />,
      <MultiFieldContainer>
        <InputField
          name="firstName"
          type="text"
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          setErrors={setErrors}
          errors={touched['firstName'] ? errors : {}}
        />
        <InputField
          name="lastName"
          type="text"
          value={values.lastName}
          placeholder="Last Name"
          onChange={handleChange}
          onBlur={handleBlur}
          setErrors={setErrors}
          errors={touched['lastName'] ? errors : {}}
        />
      </MultiFieldContainer>,
      <DateField
        value={values.dob}
        setValue={(e: any) => {
          setFieldValue('dob', e);
        }}
        errors={touched['dob'] ? errors : {}}
        setErrors={setErrors}
        setFieldTouched={setFieldTouched}
        placeholder="Date of Birth"
        formik={true}
      />,
      <SelectField
        options={genderOptions}
        handleChange={(e) => setFieldValue('gender', e.target.value)}
        handleBlur={() => { }}
        name="gender"
        placeholder="Gender"
        value={values.gender}
      />,

      <SelectField
        options={relationshipStatusOptions}
        handleChange={(e) => setFieldValue('kinship', e.target.value)}
        handleBlur={() => { }}
        name="kinship"
        placeholder="Relationship Status (parent...)"
        value={values.kinship}
      />,
      <>
        {showBeneficiaryModal && (
          <BeneficiaryModal
            setShowBeneficiaryModal={setShowBeneficiaryModal}
            selectedBeneficiary={selectedBeneficiary}
            setSelectedBeneficiary={setSelectedBeneficiary}
            beneficiariesInfo={beneficiariesInfo}
            setBeneficiariesInfo={setBeneficiariesInfo}
          />
        )}
      </>,
      <div className="beneficiaries-button">
        {selectedBeneficiaries.length > 3 && (
          <p className="beneficiaries-limit-warning">You can only add up to four beneficiaries.</p>
        )}
        <PrimaryButtonWithIcon
          icon={<AddIcon />}
          disabled={allFieldsValid(values, errors) || selectedBeneficiaries.length >= 4 || isLoadingExtra}
          loading={isLoadingExtra}
          text="Add new person to protect"
          onClick={handleAddExtraBeneficiary}
        />
      </div>,
      <>
        {showBeneficiaryErrorBanner && (
          <TimedErrorBanner
            closeFunction={setShowBeneficiaryErrorBanner}
            delay={4000}
            message="You must complete all of the fields in order to continue. Or leave them empty if you don’t want another beneficiary."
          />
        )}
      </>,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        className="beneficiary"
        isLoading={isLoading}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disabledButton || isLoading}
      />
    </>
  );
};

export default Beneficiary;
