import _ from 'lodash';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { checkUserEmail, createUser, getAfiUserInfo, submitAfiUserInfo } from '../api/apiFunctions';
import {
  AfiNavigationType,
  AfiUserType,
  AfiIndexes,
  AfiQuoteType,
  CanopyInsurerType,
  PriorPolicyCoverageType,
} from '../components/interfaces/AFIInterfaces';

import {
  AfiAnswersTemplate,
  afiPolicyName,
  AfiDefaultIndexes,
  defaultPriorPolicyCoverage,
} from '../constants/AFIConstants';
import { AfiNavigation as defaultNavigation } from '../constants/AFIConstants';
import {
  addCarFieldsToCoverage,
  toggleAfiStepsForCanopy,
  toggleDriverSteps,
  updateCarsSteps,
  updateCoverageSteps,
} from '../services/helpers/AFICustomNavigation';
import { deserializeAfiData, serializeAfiData } from '../services/helpers/AFIHelpers';
import { UserContext } from './UserContext';

export type AnswerFinancialContextType = {
  AFIAnswersObject: { [key: string]: any };
  setAFIAnswersObject: Dispatch<SetStateAction<{ [key: string]: any }>>;
  getAfiAnswerVariables: (path: (string | number)[]) => any;
  setAfiAnswerVariables: (newValue: any, path: (string | number)[]) => void;
  AfiNavigation: AfiNavigationType;
  setAfiNavigation: Dispatch<SetStateAction<AfiNavigationType>>;
  getUserData: (email: string) => any;
  submitUserData: (data: any) => any;
  createNewAfiUser: (data: any) => any;
  indexes: AfiIndexes;
  setIndexes: Dispatch<SetStateAction<AfiIndexes>>;
  quotes: AfiQuoteType[];
  setQuotes: Dispatch<SetStateAction<AfiQuoteType[]>>;
  carriersError: boolean;
  setCarriersError: React.Dispatch<React.SetStateAction<boolean>>;
  vehicleMake: string | null;
  setVehicleMake: React.Dispatch<React.SetStateAction<string | null>>;
  lastStepCompleted: string;
  setLastStepCompleted: Dispatch<SetStateAction<string>>;
  isCanopy: boolean;
  setIsCanopy: React.Dispatch<React.SetStateAction<boolean>>;
  updateCanopyNavigation: Function;
  currentCanopyInsurer: CanopyInsurerType;
  setCurrentCanopyInsurer: React.Dispatch<React.SetStateAction<CanopyInsurerType>>;
  priorPolicyCoverage: PriorPolicyCoverageType;
  setPriorPolicyCoverage: React.Dispatch<React.SetStateAction<PriorPolicyCoverageType>>;
  canopyPullId: string;
  setCanopyPullId: React.Dispatch<React.SetStateAction<string>>;
};

export const AnswerFinancialContext = createContext<AnswerFinancialContextType>({
  AFIAnswersObject: {},
  setAFIAnswersObject: () => { },
  getAfiAnswerVariables: () => null,
  setAfiAnswerVariables: () => null,
  AfiNavigation: {},
  setAfiNavigation: () => null,
  getUserData: () => { },
  submitUserData: () => { },
  createNewAfiUser: () => { },
  indexes: { ...AfiDefaultIndexes },
  setIndexes: () => null,
  quotes: [],
  setQuotes: () => null,
  carriersError: false,
  setCarriersError: (data: React.SetStateAction<boolean>) => { },
  vehicleMake: null,
  setVehicleMake: (data: React.SetStateAction<string | null>) => { },
  lastStepCompleted: 'start',
  setLastStepCompleted: (data: React.SetStateAction<string>) => { },
  isCanopy: true,
  setIsCanopy: (data: React.SetStateAction<boolean>) => { },
  updateCanopyNavigation: (newCanopyValue: boolean) => { },
  currentCanopyInsurer: { name: '', carrier_id: '', homepageUrl: '' },
  setCurrentCanopyInsurer: (data: React.SetStateAction<CanopyInsurerType>) => { },
  priorPolicyCoverage: { ...defaultPriorPolicyCoverage },
  setPriorPolicyCoverage: (data: React.SetStateAction<PriorPolicyCoverageType>) => { },
  canopyPullId: '',
  setCanopyPullId: (data: React.SetStateAction<string>) => { },
});

export const getDefaultAfiData = () => {
  try {
    // Attempt to gather data from local storage
    const localStorageObject = JSON.parse(localStorage.getItem('afi_answers') as string);
    // Match local storage data to the template object, this will prevent users from manually adding or removing fields
    const matchedObject = _.defaultsDeep(localStorageObject, AfiAnswersTemplate);
    // Update the local storage to the correct structure
    localStorage.setItem('afi_answers', JSON.stringify(matchedObject));
    return matchedObject;
  } catch (error) {
    // This will trigger if the object was not found or its syntax is wrong
    localStorage.setItem('afi_answers', JSON.stringify(AfiAnswersTemplate));
    return AfiAnswersTemplate;
  }
};

const getDefaultAfiNavigation = (dataObject: any) => {
  const newObj = _.cloneDeep(defaultNavigation);
  _.assign(newObj, updateCoverageSteps(newObj, dataObject.basics.address).newNavigation);
  _.assign(newObj, updateCarsSteps(newObj, dataObject));
  _.assign(newObj, addCarFieldsToCoverage(newObj, dataObject).newNavigation);
  _.assign(newObj, toggleDriverSteps(newObj, dataObject.drivers[0].history));

  return newObj;
};

const getDefaultCanopyNavigation = (dataObject: any) => {
  const newObj = _.cloneDeep(defaultNavigation);
  delete newObj.coverage;
  _.assign(newObj, updateCarsSteps(newObj, dataObject));
  _.assign(newObj, toggleDriverSteps(newObj, dataObject.drivers[0].history));
  return newObj;
};

const AnswerFinancialContextProvider = ({ children }: { children: ReactNode }) => {
  const [AFIAnswersObject, setAFIAnswersObject] = useState(getDefaultAfiData());
  const [AfiNavigation, setAfiNavigation] = useState(getDefaultCanopyNavigation(getDefaultAfiData()));

  const [quotes, setQuotes] = useState<AfiQuoteType[]>([]);

  const [indexes, setIndexes] = useState({ ...AfiDefaultIndexes });

  const [AfiUserInfo, setAfiUserInfo] = useState<AfiUserType>({ hasInsurance: false, isEmailUsed: false });
  const { setAuthToken, authToken } = useContext(UserContext);
  const [carriersError, setCarriersError] = useState(false);
  const [vehicleMake, setVehicleMake] = useState<string | null>(null);
  const [lastStepCompleted, setLastStepCompleted] = useState(
    localStorage.getItem('lastStepCompleted') || 'start',
  );
  const [isCanopy, setIsCanopy] = useState<boolean>(JSON.parse(localStorage.getItem('isCanopy') || 'true'));
  const [canopyPullId, setCanopyPullId] = useState('');
  const [currentCanopyInsurer, setCurrentCanopyInsurer] = useState<CanopyInsurerType>(
    JSON.parse(localStorage.getItem('afi_current_insurer') as string) || '',
  );
  const [priorPolicyCoverage, setPriorPolicyCoverage] = useState(
    JSON.parse(localStorage.getItem('canopy_coverages') as string) || {},
  );

  const getAfiAnswerVariables = (path: (string | number)[]) => {
    return _.get(AFIAnswersObject, path);
  };

  // Get and Set the indexes localStorage
  useEffect(() => {
    const storedIndexes = localStorage.getItem('indexes');
    if (storedIndexes) {
      setIndexes(JSON.parse(storedIndexes));
    }

    const canopyValue = localStorage.getItem('isCanopy');
    if (canopyValue) {
      setIsCanopy(JSON.parse(canopyValue));
      return;
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('lastStepCompleted', lastStepCompleted);
  }, [lastStepCompleted]);

  useEffect(() => {
    localStorage.setItem('indexes', JSON.stringify(indexes));
  }, [indexes]);

  useEffect(() => {
    localStorage.setItem('isCanopy', JSON.stringify(isCanopy));
    updateCanopyNavigation(isCanopy);
  }, [isCanopy]);

  // Will modify an entire step
  const setAfiAnswerVariables = (newValues: any, path: (string | number)[]) => {
    setAFIAnswersObject((prev: any) => {
      let newObj = _.cloneDeep(prev);
      _.set(newObj, path, newValues);
      localStorage.setItem('afi_answers', JSON.stringify(newObj));
      submitUserData(serializeAfiData(_.defaultsDeep(newObj, AfiAnswersTemplate)));
      return newObj;
    });
  };

  // User Logic
  const getUserData = async (email: string) => {
    try {
      const check = await checkUserEmail({
        email: email,
        policyName: afiPolicyName,
      });
      setAfiUserInfo(check.data);
      check.data.token && setAuthToken(check.data.token);
      if (check.data.isEmailUsed) {
        const newData = await getAfiUserInfo(check.data.token);
        newData.data && setAFIAnswersObject(deserializeAfiData(newData.data));
      }
    } catch (error) {
      return error;
    }
  };

  const createNewAfiUser = async (data: any) => {
    if (!authToken && !AfiUserInfo.isEmailUsed) {
      try {
        const newData = {
          user: {
            firstName: data.driverFirstName,
            lastName: data.driverLastName,
            dob: data.driverDob,
            email: AFIAnswersObject.basics.name.emailAddress,
            gender: data.driverGender === 'M' ? 'male' : 'female',
          },
          address: {
            address: AFIAnswersObject.basics.address.address,
            city: AFIAnswersObject.basics.address.city,
            state: AFIAnswersObject.basics.address.state,
            zip: AFIAnswersObject.basics.address.zip,
          },
          policyName: afiPolicyName,
        };
        const newUser = await createUser(newData);
        setAfiUserInfo({ ...newUser.data, isEmailUsed: true });
        setAuthToken(newUser.data.token);
        return newUser;
      } catch (error) {
        return error;
      }
    }
  };

  const submitUserData = async (data: any) => {
    if (authToken && AfiUserInfo.isEmailUsed) {
      const submitData = await submitAfiUserInfo(authToken as string, data).then((data) => {
        return data;
      });
      return submitData;
    }
  };

  const updateCanopyNavigation = (newCanopyValue: boolean) => {
    const defaultAfiNavigation = getDefaultAfiNavigation(getDefaultAfiData());
    //if isCanopy is true, remove the coverages section, else reset the navigation to the original one
    setAfiNavigation(toggleAfiStepsForCanopy(newCanopyValue, defaultAfiNavigation));
    setIsCanopy(newCanopyValue);
  };

  return (
    <AnswerFinancialContext.Provider
      value={{
        AFIAnswersObject,
        setAFIAnswersObject,
        getAfiAnswerVariables,
        setAfiAnswerVariables,
        AfiNavigation,
        setAfiNavigation,
        getUserData,
        submitUserData,
        createNewAfiUser,
        indexes,
        setIndexes,
        quotes,
        setQuotes,
        carriersError,
        setCarriersError,
        vehicleMake,
        setVehicleMake,
        lastStepCompleted,
        setLastStepCompleted,
        isCanopy,
        setIsCanopy,
        canopyPullId,
        setCanopyPullId,
        updateCanopyNavigation,
        currentCanopyInsurer,
        setCurrentCanopyInsurer,
        priorPolicyCoverage,
        setPriorPolicyCoverage,
      }}
    >
      {children}
    </AnswerFinancialContext.Provider>
  );
};

export default AnswerFinancialContextProvider;

export const useAnswerFinancial = () => {
  return useContext(AnswerFinancialContext);
};
