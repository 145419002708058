import { useEffect, useContext, useState } from 'react';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { UserContext } from '../../context/UserContext';
import { voluntarySuccessImages, VoluntaryDisclaimerContent } from '../../constants/VoluntaryConstants';
import { useSearchParams } from 'react-router-dom';
import { bindPolicy } from '../../api/apiFunctions';
import { getPaymentMonths } from '../../services/helpers/VoluntaryHelpers';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import LoadingMessage from '../../components/common/loading-screen/LoadingMessage/LoadingMessage';

const POLICY_IDENTIFIER = 'voluntary_accidental_death';

const VoluntarySuccess = () => {
  const { device, partnerName, authToken, showDisclaimer, setShowDisclaimer, headerContent } =
    useContext(UserContext);
  const { nextMonth } = getPaymentMonths(new Date());
  const [isBindSuccessful, setIsBindSuccessful] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const coverageAmount = localStorage.getItem('voluntaryCoverageAmount');

  let [searchParams] = useSearchParams();

  useEffect(() => {
    const safetyNetToken = searchParams.get('SafetyNetToken');
    if (safetyNetToken && authToken && coverageAmount) {
      const handleBindPolicy = async () => {
        let payload = { coverageAmount: parseInt(coverageAmount) };
        bindPolicy(POLICY_IDENTIFIER, payload, authToken)
          .then((resp) => {
            setIsBindSuccessful(true);
          })
          .catch((err) => {
            setIsBindSuccessful(false);
          })
          .finally(() => setIsLoading(false));
      };
      handleBindPolicy();
    } else {
      setIsBindSuccessful(false);
    }
    return () => {
      localStorage.clear();
      sessionStorage.clear();
    };
  }, []);

  return (
    <>
      {isBindSuccessful === undefined ? (
        isLoading && (
          <div className="voluntary-success-loading">
            <LoadingMessage text="Thanks! We are now binding your policy" />{' '}
          </div>
        )
      ) : (
        <div className="voluntary-success-page">
          <VerticalLayout
            title={
              isBindSuccessful
                ? 'Congrats on your <br/> <span> AD&D Insurance</span> policy'
                : 'Contact our Support<br/> Team'
            }
            headerContent={headerContent}
            showInfoIcon={true}
            onIconClick={() => setShowDisclaimer(true)}
            img={voluntarySuccessImages(partnerName, device)}
            content={
              <>
                {isBindSuccessful ? (
                  <p className="intro-layout-description">
                    {` A confirmation email is on its way to your inbox. On ${nextMonth} 1st, you will also receive official policy documents via email from Amalgamated Life. If you have any questions, feel free to`}{' '}
                    <a
                      className="voluntary-success-link"
                      href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                    >
                      contact us.
                    </a>
                  </p>
                ) : (
                  <p className="intro-layout-description">
                    Opps! Something happened during your enrollment. Please contact our support team at
                    hello@safetynetcommunity.org
                  </p>
                )}

                {showDisclaimer && (
                  <FlowDisclaimer
                    headerContent={headerContent}
                    subtitle="Disclaimer"
                    content={<VoluntaryDisclaimerContent />}
                  />
                )}
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default VoluntarySuccess;
