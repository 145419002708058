import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
import InputField from '../../forms/InputField/InputField';
// Images
import { userInfoImages } from '../../../../constants/AddConstants';
import { useNavigate } from 'react-router';
import { AddInsuranceContext } from '../../../../context/AddInsuranceContext';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { allFieldsValid } from '../../../../services/validation';
import { UserContext } from '../../../../context/UserContext';
import { checkUserEmail } from '../../../../api/apiFunctions';
import { formatDate } from '../../../../services/helpers/CommonHelpers';
import { POLICY_IDENTIFIERS } from '../../../../constants/CommonConstants';

export const UserInfoStep = () => {
  const { updateAddInsuranceStep } = useContext(AddInsuranceContext);
  const {
    setUserInfo,
    userInfo,
    setAddressInfo,
    setAuthToken,
    device,
    partnerName,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const userInfoSchema = object({
    firstName: string()
      .min(2, 'First name is required')
      .required('First name is required'),
    lastName: string()
      .min(2, 'Last name is required')
      .required('Last name is required'),
    email: string()
      .email('Please enter a valid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: userInfoSchema,
    onSubmit: () => { },
  });

  const { values, handleChange, handleBlur, errors, touched, setErrors } =
    formik;

  const formContent = {
    header: "What's your name?",
    Img: userInfoImages(partnerName, device),
    buttonTxt: 'Continue',
    disabledButton: allFieldsValid(values, errors),
    onAPIClick: (onSubmit: Function) => {
      setIsLoading(true);
      checkUserEmail({
        email: values.email,
        policyName: POLICY_IDENTIFIERS.ACCIDENTAL_DEATH,
      })
        .then((res) => {
          if (res?.data?.hasInsurance) {
            setUserInfo({
              id: res?.data?.user?.id || '',
              firstName: res?.data?.firstName || values.firstName,
              lastName: res?.data?.lastName || values.lastName,
              gender: res?.data?.user?.gender || '',
              dob: res?.data?.user?.dob ? formatDate(res?.data?.user?.dob) : '',
              ssn: res?.data?.user?.ssn || "",
              phone: res?.data?.user?.phone || "",
              email: values.email,
            });
            if (res?.data?.user?.address) {
              setAddressInfo({
                address: res.data.user.address.address || '',
                address2: res.data.user.address.address2 || '',
                city: res.data.user.address.city || '',
                state: res.data.user.address.state || '',
                zip: res.data.user.address.zip || '',
              });
            }
            if (res?.data?.token) {
              setAuthToken(res.data.token);
            }
            onSubmit(true);
            return false;
          }
          setUserInfo({
            ...userInfo,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          });
          updateAddInsuranceStep('address');
          onSubmit(false);
        })
        .catch((err) => {
          setUserInfo({
            ...userInfo,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          });
          updateAddInsuranceStep('address');
          onSubmit(false);
        })
        .finally(() => setIsLoading(false));
    },
    onSubmit: (flag: boolean) => {
      navigate(
        flag
          ? '/accidental-death/upsell-already-have'
          : '/accidental-death/address'
      );
    },
    fields: [
      <InputField
        name="firstName"
        type="text"
        value={values.firstName}
        placeholder="First Name"
        onChange={handleChange}
        onBlur={handleBlur}
        setErrors={setErrors}
        errors={touched['firstName'] ? errors : {}}
      />,
      <InputField
        name="lastName"
        type="text"
        value={values.lastName}
        placeholder="Last Name"
        onChange={handleChange}
        onBlur={handleBlur}
        setErrors={setErrors}
        errors={touched['lastName'] ? errors : {}}
      />,
      <InputField
        name="email"
        type="email"
        value={values.email}
        placeholder="Email Address"
        onChange={handleChange}
        onBlur={handleBlur}
        setErrors={setErrors}
        errors={touched['email'] ? errors : {}}
        inputMode="email"
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        onAPIClick={formContent.onAPIClick}
        hasApiCall={true}
        fields={formContent.fields}
        cardImage={formContent.Img}
        disabledButton={formContent.disabledButton || isLoading}
        isLoading={isLoading}
      />
    </>
  );
};
