import { useContext, useState } from 'react';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import PrimaryButton from '../../../buttons/PrimaryButton';
import RadioQuestionField from '../../../forms/RadioQuestionField/RadioQuestionField';
import './existing-coverage.scss';
import { eligibilityContent, eligibilityDisclaimer } from '../../../../../constants/ConciergeConstants';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const Eligibility = () => {
  const { userAnswers, setUserAnswers, setLastStep } = useContext(ConciergeContext);
  const [eligibilityAnswers, setEligibilityAnswers] = useState({ ...userAnswers.eligibility });
  const navigate = useNavigate();

  const handleSetField = (name: string, newValue: boolean) => {
    setEligibilityAnswers((prev) => ({ ...prev, [name]: newValue }));
  };

  const disableButton = () => {
    const hasNullField = Object.values(eligibilityAnswers).some((answer) => answer === null);
    return hasNullField;
  };

  const handleSubmit = () => {
    const newObj = _.cloneDeep(userAnswers);
    _.set(newObj, ['eligibility'], eligibilityAnswers);
    setUserAnswers(newObj);

    setLastStep('existing-coverage/eligibility',);
    localStorage.setItem('lastConciergeStep', 'existing-coverage/eligibility');
    navigate('/concierge/request');
  };

  return (
    <div className="coverages-container">
      <h1 className="form-title">Eligibility questions </h1>
      {eligibilityContent.map((element) => (
        <div key={element.name + element.question} className="coverages-field eligibility">
          <RadioQuestionField
            name={element.name}
            questionText={element.question}
            currentValue={eligibilityAnswers[element.name]}
            onChange={handleSetField}
          />
        </div>
      ))}
      <PrimaryButton text="Continue" onClick={handleSubmit} disabled={disableButton()} />
      <div className="coverages-disclaimer">{eligibilityDisclaimer}</div>
    </div>
  );
};

export default Eligibility;
