import AnswerFinancialContextProvider from "../../../context/AnswerFinancialContext";
import CanopyCode from "../../../pages/answer-financial-insurance/canopy-connect/CanopyCode/CanopyCode";
import CanopyFailure from "../../../pages/answer-financial-insurance/canopy-connect/CanopyFailure/CanopyFailure";
import CanopyLoading from "../../../pages/answer-financial-insurance/canopy-connect/CanopyLoading/CanopyLoading";
import CanopyLogin from "../../../pages/answer-financial-insurance/canopy-connect/CanopyLogin/CanopyLogin";
import CanopyOptions from "../../../pages/answer-financial-insurance/canopy-connect/CanopyOptions/CanopyOptions";
import CanopySuccess from "../../../pages/answer-financial-insurance/canopy-connect/CanopySuccess/CanopySuccess";
import { CanopyInsurerType, CanopyUserType } from "../../interfaces/AFIInterfaces";
import './canopy-integration-flow.scss';

interface CanopyConnectProps {
    stepId: string,
    canopyUser: CanopyUserType,
    canopyInsurer: CanopyInsurerType,
    setCanopyUser: Function,
    canopyTOS: string,
    setLastStep: (lastStep: string) => void
    timeOut: Function
    mfaOptions: { [key: string]: string }
    setIsCanopy: Function
    onSuccess: Function
    onTryAgain: Function
    onSkip: Function
}
const CanopyIntegrationFlow = ({
    canopyUser, stepId, canopyInsurer, setCanopyUser, canopyTOS, timeOut, setLastStep, mfaOptions, setIsCanopy,
    onSuccess, onTryAgain, onSkip }: CanopyConnectProps) => {
    return (
        // TODO: Create component for manage all layout container
        <AnswerFinancialContextProvider>
            <div className="container">
                {/* TODO: change to switch case*/}
                {(stepId === 'connect-account') &&
                    <CanopyLogin
                        currentInsurer={canopyInsurer}
                        updateCanopy={setIsCanopy}
                        setCanopyUser={setCanopyUser}
                        canopyTOS={canopyTOS}
                        startNewTimeout={timeOut}
                        onSkip={onSkip}
                        onSuccess={() => {
                            setLastStep('existing-coverage/selection')
                        }}
                    />
                }
                {(stepId === 'selection') &&
                    <CanopyOptions
                        user={canopyUser}
                        currentInsurer={canopyInsurer}
                        options={mfaOptions}
                        startNewTimeout={timeOut}
                    />}
                {(stepId === 'code') &&
                    <CanopyCode
                        currentInsurer={canopyInsurer}
                        canopyUser={canopyUser}
                        startNewTimeout={timeOut}
                        tryAgain={onTryAgain}
                    />
                }
                {(stepId === 'connecting') && <CanopyLoading startNewTimeout={timeOut} />}
                {(stepId === 'success') &&
                    <CanopySuccess currentInsurer={canopyInsurer}
                        onNext={() => {
                            if (onSuccess !== undefined) {
                                onSuccess();
                            }
                        }} />
                }
                {(stepId === 'failed') && <CanopyFailure onTryAgain={onTryAgain} onSkip={onSkip} setIsCanopy={setIsCanopy} />}
            </div>
        </AnswerFinancialContextProvider>
    );
}

export default CanopyIntegrationFlow;