import { object, string, boolean } from 'yup';
import { yupMessages, yupValidations } from '../../services/yupValidations';

export const userInfoSchema = object({
  firstName: string().min(3, yupMessages.stringLengthRestriction(3, "min")).required(yupMessages.required),
  lastName: string().min(3, yupMessages.stringLengthRestriction(3, "min")).required(yupMessages.required),
  gender: string().required(yupMessages.required),
  dob: yupValidations.ageMinMax(18, 70),
  phone: string().min(10, yupMessages.phone).required(yupMessages.required),
  email: string().email(yupMessages.email).required(yupMessages.required),
});

export const aboutYouSchema = object({
  civilStatus: string().required(yupMessages.required),
  profession: string().required(yupMessages.required),
  passions: string().min(3, yupMessages.required).required(yupMessages.required),
  strategicReview: boolean().required(yupMessages.required),
  estatePlanning: boolean().required(yupMessages.required),
});
