import { vehicleMakers } from '../../../../constants';
import SelectField from '../../select-field/SelectField';
import { useFormik } from 'formik';
import {
  generateArrayOfYears,
  generateModels,
  generateSelectOptions,
} from '../../../../services/helpers/selectFieldHelpers';
import { conciergeMinVehicleYear, conciergeVehicleTypes } from '../../../../constants/ConciergeConstants';
import InputField from '../InputField/InputField';
import './vehicle-form.scss';
import { ChangeEvent, useEffect } from 'react';
import { object, string } from 'yup';
import { VehicleInfo } from '../../../interfaces/ConciergeInterfaces';

const VehicleForm = ({
  vehicleInfo,
  onInputChange,
  setIsFormValid,
}: {
  vehicleInfo: VehicleInfo;
  onInputChange: (newValues: VehicleInfo) => void;
  setIsFormValid: (newValue: boolean) => void;
}) => {
  const formikConfig = useFormik({
    initialValues: {
      year: vehicleInfo.year,
      type: vehicleInfo.type,
      make: vehicleInfo.make,
      model: vehicleInfo.model,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: object({
      year: string()
        .oneOf(generateArrayOfYears(conciergeMinVehicleYear).map((item) => item.value))
        .required('Year is required'),
      type: string().oneOf(conciergeVehicleTypes).required('Type is required'),
      make: string().when('type', {
        is: 'Car',
        then: string().oneOf(Object.keys(vehicleMakers)).required(),
        otherwise: string().required('Make is required'),
      }),
      model: string().required('Model is required'),
    }),
    onSubmit: () => {},
  });

  const { values, handleChange, errors, setErrors, setValues, touched, setFieldTouched, isValid } =
    formikConfig;

  const onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // Updating all values at the same time to avoid race conditions
    const newValues = {
      year: values.year,
      type: e.target.value,
      make: '',
      model: '',
    };
    setValues(newValues);
    onInputChange(newValues);
    // Resetting touched field conditions so the user doesn't get an error message whenever they change the type
    setFieldTouched('model', false);
    setFieldTouched('make', false);
  };

  const onMakeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // Updating all values at the same time to avoid race conditions
    const newValues = {
      year: values.year,
      type: values.type, // Will always be "Car"
      make: e.target.value,
      model: '',
    };
    setValues(newValues);
    onInputChange(newValues);
    setFieldTouched('model', false);
  };

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    // Will update both the local value and the parent value at the same time
    // It needs to be done in such way to avoid racing conditions or infinite loops
    handleChange(e);
    onInputChange({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="vehicle-form-container">
      <div className="year-type">
        <div className="year">
          <SelectField
            name="year"
            value={values.year}
            handleChange={onChange}
            placeholder="Year"
            options={generateArrayOfYears(conciergeMinVehicleYear)}
            handleBlur={() => setFieldTouched('year')}
            error={touched.year && errors.year}
          ></SelectField>
        </div>

        <div className="type">
          <SelectField
            name="type"
            value={values.type}
            handleChange={onTypeChange}
            placeholder="Type"
            options={generateSelectOptions(conciergeVehicleTypes)}
            handleBlur={() => setFieldTouched('type')}
            error={touched.type && errors.type}
          ></SelectField>
        </div>
      </div>

      <div className="make">
        {values.type === 'Car' ? (
          <SelectField
            name="make"
            value={values.make}
            handleChange={onMakeChange}
            placeholder="Make"
            options={generateSelectOptions(Object.keys(vehicleMakers))}
            disabled={!values.type}
            error={touched.make && errors.make}
            handleBlur={() => setFieldTouched('make')}
          ></SelectField>
        ) : (
          <InputField
            name="make"
            onChange={onChange}
            placeholder="Make"
            type="input"
            value={values.make}
            errors={touched.make ? errors : null}
            onBlur={() => setFieldTouched('make')}
            setErrors={setErrors}
            disabled={!values.type}
          ></InputField>
        )}
      </div>

      <div className="model">
        {values.type === 'Car' ? (
          <SelectField
            name="model"
            value={values.model}
            handleChange={onChange}
            placeholder="Model"
            options={generateModels(values.make)}
            disabled={!(values.type || values.model)}
            error={touched.model && errors.model}
            handleBlur={() => setFieldTouched('model')}
          ></SelectField>
        ) : (
          <InputField
            name="model"
            onChange={onChange}
            placeholder="Model"
            type="input"
            value={values.model}
            errors={touched.model ? errors : null}
            onBlur={() => setFieldTouched('model')}
            setErrors={setErrors}
            disabled={!(values.type || values.model)}
          ></InputField>
        )}
      </div>
    </div>
  );
};

export default VehicleForm;
