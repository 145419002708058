import { SelectOptionInterface } from '../components/interfaces/InputInterfaces';

export const partnerImages = (partner: string, device: string) => {
  const PartnerImage =
    require(`../assets/images/partners/${partner}/common/${device}/${partner}.svg`).default;

  return <PartnerImage />;
};

export const legalComplianceURL = 'https://www.harmonicft.com/legal-compliance';

export const partnerNames: { [key: string]: string } = {
  carshield: 'carshield',
  car_shield: 'carshield',
  'car-shield': 'carshield',
  blueacorn: 'blueacorn',
  blue_acorn: 'blueacorn',
  'blue-acorn': 'blueacorn',
  'upsell-test': 'blueacorn',
  grid: 'grid',
};

export const defaultFlowTitles: { [key: string]: string } = {
  'accidental-death': 'AD&D Insurance at no cost to you',
  auto: 'Auto Insurance',
  'accidental-death-voluntary': 'Voluntary AD&D Insurance',
  life: 'Life Insurance',
  'full-quote-auto': 'Auto Insurance',
  concierge: 'Insurance',
};

export const defaultCoveredUser = {
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  phoneNumber: '',
  ssn: '',
  id: new Date().getTime().toString(),
};

export const defaultCoveragePlan = {
  monthlyCost: 0,
  coverage: 0,
  interval: 0,
};

export const displayVoluntaryAddQuotes = [
  { coverage: 50000, user: 3, family: 4.15 },
  { coverage: 150000, user: 9, family: 12.45 },
  { coverage: 300000, user: 18, family: 24.9 },
];

export const voluntaryAddQuotes = [
  { coverage: 50000, user: 3, family: 4.15 },
  { coverage: 100000, user: 6, family: 8.3 },
  { coverage: 150000, user: 9, family: 12.45 },
  { coverage: 200000, user: 12, family: 16.6 },
  { coverage: 250000, user: 15, family: 20.75 },
  { coverage: 300000, user: 18, family: 24.9 },
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const POLICY_IDENTIFIERS = {
  HAVEN_LIFE: 'haven_life',
  ACCIDENTAL_DEATH: 'accidental_death',
  VOLUNTARY_ACCIDENTAL_DEATH: 'voluntary_accidental_death',
};

export const transparentlyOnlyPartners = ['grid'];

export const afiStates = [
  'AL',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'ID',
  'IN',
  'GA',
  'IA',
  'IL',
  'MO',
  'MS',
  'NE',
  'NJ',
  'NC',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'TN',
  'TX',
  'VA',
  'WA',
  'WI',
  'WV',
];

export const maritalStatusOptions: SelectOptionInterface[] = [
  { id: '0', value: 'single', displayName: 'Single' },
  { id: '1', value: 'partnered', displayName: 'Legally partnered' },
  { id: '2', value: 'married', displayName: 'Married' },
  { id: '3', value: 'widowed', displayName: 'Widowed' },
  { id: '4', value: 'registered', displayName: 'Registered Domestic Partner' },
];

export const getInsurerAutocompletePlaceholderText = (status: string) => {
  switch (status) {
    case 'success':
      return 'Ex: Geico';
    case 'error':
      return 'Error';
    case 'loading':
      return 'Loading carrier list...';
  }
};
