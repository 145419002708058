import { useContext, useState, useEffect } from 'react';
import './add.scss';
import VerticalPageLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { getSafetyNetPolicyInfo } from './constants';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { DisclaimerTable } from '../../components/common/flow-disclaimer/DisclaimerList';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { isAmalgamatedState } from '../../services/helpers/AddHelpers';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { introImages } from '../../constants/AddConstants';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import { BLOCKED_UPSELL_PARTNERS } from '../../constants/UpsellConstants';

const AddSuccess = () => {
  const { progressStep, setUpsellProgressStep, setLastCompletedUpsellStep } = useContext(AddInsuranceContext);
  const {
    device,
    userInfo,
    addressInfo,
    partnerName,
    showDisclaimer,
    headerContent,
    setShowDisclaimer,
    setIsAddCompleted,
  } = useContext(UserContext);
  const [isUpsellAvailable] = useState<boolean>(
    partnerName !== 'default' && BLOCKED_UPSELL_PARTNERS.indexOf(partnerName) === -1,
  );
  let isAmalgamated = isAmalgamatedState(addressInfo.state);

  const navigate = useNavigate();

  useEffect(() => {
    // route blocking
    if (progressStep === 0) {
      navigate('/accidental-death/start');
      return;
    }
    setLastCompletedUpsellStep('dependents');
    setIsAddCompleted(true);
  }, []);

  return (
    <>
      <div className="add-success-page">
        <VerticalPageLayout
          onIconClick={() => setShowDisclaimer(true)}
          title={
            isUpsellAvailable
              ? '<span> Boom. You did it.</span>'
              : `Congrats on your <br> <span>$10,000 policy </span>!`
          }
          img={introImages(partnerName, device)}
          headerContent={headerContent}
          showInfoIcon={true}
          description={
            isUpsellAvailable
              ? `You just locked in your $10,000 AD&D policy and will receive a confirmation email at ${userInfo.email}. <br/><br/> Why stop now? To further your coverage, we also offer life insurance and more robust AD&D plans. Want to find out how much and what type of coverage you need? Answer these four questions.`
              : `You will receive an email at ${userInfo.email} with your policy details. For now, there is nothing left to do, congrats!`
          }
          content={
            isUpsellAvailable && (
              <div className="add-button-container">
                <PrimaryButton
                  text="Find out how much I need"
                  onClick={() => {
                    setUpsellProgressStep(1);
                    navigate('/accidental-death/dependents');
                    return;
                  }}
                />
                <SecondaryButton
                  text="No, thanks. I am all set"
                  onClick={() => {
                    navigate('/accidental-death/no-thanks');
                  }}
                />
              </div>
            )
          }
        />
      </div>
      {showDisclaimer && (
        <FlowDisclaimer
          className="add-table"
          headerContent={headerContent}
          content={
            <>
              <DisclaimerTable info={getSafetyNetPolicyInfo(isAmalgamated)} />
              {!isAmalgamated && (
                <>
                  <p className="disclaimer-table-text">
                    FOR NY RESIDENTS: AD&D policy provides ACCIDENT insurance only. It does NOT provide basic
                    hospital, basic medical or major medical insurance as defined by the New York State
                    Department of Financial Services.
                  </p>
                  <p className="disclaimer-table-text">
                    IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
                    <br /> <br />
                    Accidental Death & Dismemberment insurance coverage is issued by The Prudential Insurance
                    Company of America, Newark, NJ. The Booklet-Certificate contains all details including any
                    policy exclusions, limitations and restrictions, which may apply. CA COA #1179, NAIC
                    #68241. Contract Series: 83500.
                    <br />
                    1061616-00001-00
                  </p>
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default AddSuccess;
