import { useState, useContext, useEffect } from 'react';
import CanopyCoverageGrid from '../../../../components/common/afi/CanopyCoveragesGrid/CanopyCoverageGrid';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../../components/common/buttons/SecondaryButton';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import './canopy-coverages.scss';
import _ from 'lodash';
import { useFormik } from 'formik';
import { AfiQuoteType } from '../../../../components/interfaces/AFIInterfaces';
import { afiQuotesWrapper, parseAfiPayload, parseAfiResponse } from '../../../../api/apiFunctions';
import { UserContext } from '../../../../context/UserContext';
import {
  findClosestBodilyInjuryValue,
  getClosestCoverageForCanopy,
  serializeAfiData,
} from '../../../../services/helpers/AFIHelpers';
import { customStateOptions } from '../../../../constants/AfiStateQuestions';

const CanopyCoverages = () => {
  const { AFIAnswersObject, quotes, setQuotes } = useContext(AnswerFinancialContext);
  const [toggleAdjust, setToggleAdjust] = useState<boolean>(false);
  const [canopyQuotesError, setCanopyQuotesError] = useState(false);
  const [carrier, setCarrier] = useState(quotes[0]);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(UserContext);

  const getKeyValue = (initialValue: any, keyName: string, customFields: any) => {
    if (customFields[keyName] || customStateOptions['default'][keyName]) {
      if (initialValue) {
        const closestValue = getClosestCoverageForCanopy(
          initialValue.replaceAll('$', ''),
          Object.keys(customFields[keyName] || customStateOptions['default'][keyName]),
        );
        if (closestValue) {
          return customFields[keyName][closestValue];
        }
      }
      const options = Object.values(customFields[keyName] || customStateOptions['default'][keyName]);
      return options[Math.floor((options.length - 1) / 2)];
    }
    return 'None';
  };

  const getBIKeyValue = (initialValue: any, keyName: string, customFields: any) => {
    if (customFields[keyName] || customStateOptions['default'][keyName]) {
      if (initialValue) {
        const closestValue = findClosestBodilyInjuryValue(
          initialValue.replaceAll('$', ''),
          Object.keys(customFields[keyName] || customStateOptions['default'][keyName]),
        );
        if (closestValue) {
          return customFields[keyName][closestValue];
        }
      }
      const options = Object.values(customFields[keyName] || customStateOptions['default'][keyName]);
      return options[Math.floor((options.length - 1) / 2)];
    }
    return 'None';
  };

  const checkValueWithNoDefaultOptions = (value: string) => {
    if (value && value === 'None') {
      return 'None';
    }
    return value ? 'Covered' : 'None';
  };

  const getInitialValues = (carrierValues: AfiQuoteType) => {
    let currentState = AFIAnswersObject.basics.address.state;
    if (!currentState || !(currentState in customStateOptions)) {
      currentState = 'default';
    }
    const customFields = _.omit(customStateOptions[currentState], ['insuranceCarrier']);
    const valuesObj: { [key: string]: any } = {
      medPay: getKeyValue(carrierValues?.vehicles[0]?.coverages.medicalPayments, 'medPay', customFields),
      pip: getKeyValue(carrierValues?.vehicles[0]?.coverages.pip, 'pip', customFields),
      uninsuredPropertyDamage: getKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristPropertyDamage,
        'uninsuredMotoristsPropertyDamage',
        customFields,
      ),
      primaryPropertyDamage: getKeyValue(
        carrierValues?.vehicles[0]?.coverages.propertyDamage,
        'propertyDamageLiability',
        customFields,
      ),
      primaryBodilyInjuryLimit: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.bodilyInjury,
        'bodilyInjuryLiability',
        customFields,
      ),
      uninsuredBodilyInjury: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristBodilyInjury,
        'uninsuredMotoristsBodilyInjury',
        customFields,
      ),
      underinsuredMotoristsBodilyInjury: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristBodilyInjury,
        'uninsuredMotoristsBodilyInjury',
        customFields,
      ),
    };
    AFIAnswersObject?.cars?.forEach((_: any, index: number) => {
      valuesObj[`V${index + 1}_collisionDeductible`] = getKeyValue(
        carrierValues.vehicles[index].coverages.collision,
        'collisionDeductible',
        customFields,
      );
      valuesObj[`V${index + 1}_comprehensiveDeductible`] = getKeyValue(
        carrierValues.vehicles[index].coverages.comprehensive,
        'comprehensiveDeductible',
        customFields,
      );
      valuesObj[`V${index + 1}_coveragesLoanLeaseGap`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.loanLeaseGap,
      );
      valuesObj[`V${index + 1}_coveragesRental`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.rentalReimbursement,
      );
      valuesObj[`V${index + 1}_coveragesTowing`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.towing,
      );
    });
    return valuesObj;
  };

  const formik = useFormik({
    initialValues: getInitialValues(carrier),
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    validationSchema: null,
    onSubmit: () => {},
  });
  const { values, setValues, handleChange } = formik;

  useEffect(() => {
    setValues(getInitialValues(carrier));
  }, [carrier]);

  const callSequence = async (newData: any) => {
    setIsLoading(true);
    try {
      const xmlData = await parseAfiPayload(authToken, newData);
      const startTime = new Date();
      const quotes = await afiQuotesWrapper(authToken, xmlData.data);
      const endTime = new Date();
      const { urls } = quotes.data;
      const reportingData: { [key: string]: string | number } = {};
      reportingData.firstName = newData.applicant.general.name.firstName;
      reportingData.lastName = newData.applicant.general.name.lastName;
      reportingData.state = newData.applicant.general.address.state;
      reportingData.requestDuration = Number(endTime) - Number(startTime);
      if (urls?.payloadUrl && urls?.responseUrl) {
        reportingData.payloadUrl = urls.payloadUrl;
        reportingData.responseUrl = urls.responseUrl;
      }
      const response = await parseAfiResponse(authToken, quotes.data.data, undefined, reportingData);
      if (['success', 'successwithwarning', 'successwithinfo'].includes(response.data.status)) {
        setQuotes(response.data.policies);
        setCarrier(response.data.policies[0]);
        setCanopyQuotesError(false);
      } else {
        setCanopyQuotesError(true);
      }
    } catch (error) {
      setCanopyQuotesError(true);
    } finally {
      setToggleAdjust(false);
      setIsLoading(false);
    }
  };

  const handleOnClickSave = () => {
    if (toggleAdjust) {
      const afiObjectCopy = _.cloneDeep(AFIAnswersObject);
      const tmpCoverages = afiObjectCopy.coverage['new-coverage'];
      Object.keys(values).forEach((key) => (tmpCoverages[key] = values[key]));
      _.set(afiObjectCopy, 'coverage["new-coverage"]', tmpCoverages);
      const newData = serializeAfiData(afiObjectCopy);
      callSequence(newData);
      return;
    }
    setToggleAdjust((prev) => !prev);
  };

  return (
    <div className="canopy-coverages-container">
      <section className="coverages-content">
        <h1 className="coverages-title">Verify your coverage for your new policy</h1>
        <CanopyCoverageGrid
          vehicleDetails={AFIAnswersObject?.cars}
          toggleAdjust={toggleAdjust}
          setToggleAdjust={setToggleAdjust}
          state={AFIAnswersObject.basics.address.state}
          error={canopyQuotesError}
          coverages={values}
          handleSelect={handleChange}
          onClickSave={handleOnClickSave}
          carrier={carrier}
          setCarrier={setCarrier}
          isLoading={isLoading}
        />
        <div className="coverages-button-container">
          {toggleAdjust && (
            <SecondaryButton
              text="Save"
              loading={isLoading}
              onClick={handleOnClickSave}
              disabled={isLoading}
            />
          )}
          <a
            href={toggleAdjust ? 'javascript:void(0)' : carrier?.insurer?.returnUrl}
            target={toggleAdjust ? '' : '_blank'}
            rel="noreferrer noopener"
          >
            <PrimaryButton
              text="Finish sign up"
              disabled={toggleAdjust}
              onClick={() => {
                localStorage.clear();
              }}
            />
          </a>
        </div>
      </section>
    </div>
  );
};

export default CanopyCoverages;
