import { getCanopyPull } from "../api/apiFunctions";
import { CanopyUserType, PriorPolicyCoverageType, ServerEventType } from "../components/interfaces/AFIInterfaces";
import { deserializeAfiData, deserializePriorPolicyData, processPriorPolicyData } from "./helpers/AFIHelpers";

export default class SSEService {
    eventSource: EventSource | undefined = undefined;
    serverEvent: ServerEventType | undefined = JSON.parse(localStorage.getItem('afi_sse') as string) || '';
    canopyPullId: string = "";
    priorPolicyCoverage = JSON.parse(localStorage.getItem('canopy_coverages') as string) || {};
    policyCoverage = JSON.parse(localStorage.getItem('canopy_coverages') as string) || {};

    private async getPullData(canopyUser: CanopyUserType, lastStep: Function): Promise<string | undefined> {
        try {
            const data = await getCanopyPull(canopyUser?.pullId, canopyUser?.canopyJWT);
            if (data?.data?.pullInfo && data?.data?.pullInfo?.policy) {
                this.canopyPullId = (canopyUser?.pullId || '');
                const newData = deserializeAfiData(data.data.pullInfo);
                const newPolicyData = processPriorPolicyData(
                    deserializePriorPolicyData(data.data.pullInfo.policy) as PriorPolicyCoverageType,
                    newData.basics.address.state,
                );
                this.eventSource?.close();
                this.policyCoverage = newPolicyData;
                localStorage.setItem('canopy_coverages', JSON.stringify(newPolicyData));
                // TODO: review if we can delete this
                localStorage.setItem('afi_answers', JSON.stringify(newData));
                lastStep('existing-coverage/success');
                return '/concierge/existing-coverage/success';
            }
        } catch (error) { }
        lastStep('existing-coverage/failed');
        return '/concierge/existing-coverage/failed';
    };
    // TODO:  Change to class approach
    public async nextStep(newEvent: ServerEventType, canopyUser: CanopyUserType, lastStep: Function): Promise<string | undefined> {
        this.serverEvent = newEvent;
        localStorage.setItem('afi_sse', JSON.stringify(newEvent));
        switch (newEvent.status) {
            case 'IDENTITY_VERIFICATION_OPTIONS':
                lastStep('existing-coverage/selection');
                return '/concierge/existing-coverage/selection'
            case 'IDENTITY_VERIFICATION':
                lastStep('existing-coverage/code');
                return '/concierge/existing-coverage/code';
            case 'NOT_AUTHENTICATED':
            case 'INTERNAL_ERROR':
            case 'PROVIDER_ERROR':
                lastStep('existing-coverage/failed');
                return '/concierge/existing-coverage/failed';
            case 'GETTING_CONSUMERS':
                lastStep('existing-coverage/connecting');
                return '/concierge/existing-coverage/connecting';
            case 'PULLING_DATA':
                return this.getPullData(canopyUser, lastStep);
            default:
                this.serverEvent = undefined;
                return undefined;
        }
    };
}