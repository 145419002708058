import { useFormik } from 'formik';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../../../../assets/common/plus-sign.svg';
import { maritalStatusOptions } from '../../../../../constants/CommonConstants';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import { UserContext } from '../../../../../context/UserContext';
import { generateSelectOptionsArray } from '../../../../../services/helpers/AFIHelpers';
import { allFieldsValid } from '../../../../../services/validation';
import { driverOccupationOptions } from '../../../../../utils/afi/questions/driver';
import { ConciergeKidType } from '../../../../interfaces/ConciergeInterfaces';
import { aboutYouSchema } from '../../../../schemas/conciergeSchemas';
import PrimaryButton from '../../../buttons/PrimaryButton';
import PrimaryButtonWithIcon from '../../../buttons/PrimaryButtonWithIcon';
import ChildInput from '../../../child-input/ChildInput';
import RadioQuestionField from '../../../forms/RadioQuestionField/RadioQuestionField';
import SelectField from '../../../select-field/SelectField';
import './about-you-screens.scss';
import AutocompleteWrapper from '../../../autocomplete/Autocomplete';
import InputField from '../../../forms/InputField/InputField';

const AboutYou = () => {
  const { isMobile } = useContext(UserContext);
  const { userAnswers, setUserAnswers, setLastStep } = useContext(ConciergeContext);
  const [kids, setKids] = useState<ConciergeKidType[]>(userAnswers.about.kids || []);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      civilStatus: userAnswers?.about?.civilStatus,
      profession: userAnswers?.about?.profession,
      passions: userAnswers?.about?.passions,
      strategicReview: userAnswers?.about?.strategicReview,
      estatePlanning: userAnswers?.about?.estatePlanning,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: aboutYouSchema,
    onSubmit: () => { },
  });

  const { values, setFieldValue, handleChange, handleBlur, errors, touched } = formik;

  const handleSubmit = () => {
    const userAnswersCopy = { ...userAnswers };
    _.set(userAnswersCopy, ['about'], { ...userAnswers?.about, ...values, kids: kids });
    setUserAnswers(userAnswersCopy);
    setLastStep('about/details');
    navigate('/concierge/assets/overview');
  };

  const handleKidInfo = (newValue: string, index: number, key: 'age' | 'name') => {
    const kidsCopy = [...kids];
    kidsCopy[index][key] = newValue;
    setKids(kidsCopy);
  };

  const handleRemoveKid = (index: number) => {
    const kidsCopy = [...kids];
    kidsCopy.splice(index, 1);
    setKids(kidsCopy);
  };

  const handleAddExtraKid = () => {
    const kidsCopy = [...kids];
    kidsCopy.push({ age: '', name: '' });
    setKids(kidsCopy);
  };

  const areKidsIncomplete = () => {
    if (kids.length > 0) {
      const emptyField = kids.find((kid) => kid.name === '' || kid.age === '') ? true : false;
      return emptyField;
    }
    return false;
  };

  return (
    <div className="about-you-screen-container">
      <h1 className="form-title">Tell us about yourself: </h1>

      <div className={`about-you-field-container ${!isMobile ? 'short-container' : ''}`}>
        <p className="regular-text-styling">I am </p>
        <div className="short-field">
          <SelectField
            name="civilStatus"
            value={values.civilStatus}
            options={maritalStatusOptions}
            handleChange={handleChange}
            placeholder="Marital Status"
          />
        </div>
      </div>

      <div className={`about-you-field-container ${!isMobile ? 'short-container' : ''}`}>
        <p className="regular-text-styling">Professionally, I am a </p>
        <div className="short-field">
          <InputField
            value={values.profession}
            name='profession'
            type='text'
            setErrors={() => { }}
            placeholder='Profession'
            onChange={handleChange}
            className={`${touched.passions && errors.passions ? 'textarea-error' : ''}`}
          />
        </div>
      </div>

      <div className="about-you-hobbies">
        <div className="about-you-field-container">
          <p className="regular-text-styling">Outside of work, I am passionate about.</p>
          <textarea
            name="passions"
            placeholder="Describe your activities here..."
            value={values.passions}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${touched.passions && errors.passions ? 'textarea-error' : ''}`}
          />
          {touched.passions && errors.passions && <div className="error-message">{errors.passions}</div>}
        </div>
      </div>

      <RadioQuestionField
        name="strategicReview"
        questionText="Within the last two years have you had a strategic review of your insurance?"
        onChange={setFieldValue}
        currentValue={values.strategicReview}
      />

      <RadioQuestionField
        name="estatePlanning"
        questionText="Have you incorporated insurance into your estate planning?"
        onChange={setFieldValue}
        currentValue={values.estatePlanning}
      />

      <h3 className="about-you-field-title">Do you have children (of any age) or dependents?</h3>
      {kids.map((kid: ConciergeKidType, index: number) => (
        <ChildInput
          key={`kid-${index}`}
          index={index}
          age={kid.age}
          name={kid.name}
          onAgeChange={handleKidInfo}
          onNameChange={handleKidInfo}
          onDelete={handleRemoveKid}
        />
      ))}
      <PrimaryButtonWithIcon
        icon={<AddIcon />}
        disabled={areKidsIncomplete()}
        text="Add"
        onClick={handleAddExtraKid}
      />

      <div className="button-container">
        <PrimaryButton text="Next" disabled={allFieldsValid(values, errors)} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default AboutYou;
