import './sections-progress-bar.scss';

/**
 * Component that generates a mobile progress bar with both sections and steps,
 * as implemented first in the Answer Financial flow. To be used together with SectionsProgressBarDesktop
 * @param sections Array of strings. An array containing section names
 * @param sectionId String. The name of the currentSection
 * @param progress Number. A number from 0 to 100 describing how much of the flow was completed
 */

const SectionsProgressBarMobile = ({
  sections,
  sectionId,
  progress,
}: {
  sections: string[];
  sectionId: string;
  progress: number;
}) => {
  return (
    <>
      <div className="afi-tabs-container">
        {sections.map((item) => {
          return (
            <div key={item} className={`afi-tabs-item${item === sectionId ? ' active' : ''}`}>
              {item}
            </div>
          );
        })}
      </div>
      <div className="afi-progress-bar-container">
        <div
          className={`afi-progress-bar${progress === 100 ? ' filled' : ''}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </>
  );
};

export default SectionsProgressBarMobile;
