import './summary-item.scss';
import TrashCan from '../../../assets/common/trash-can.svg';

const SummaryItem = ({ text, onDelete }: { text: string; onDelete: () => void }) => {
  return (
    <div className="summary-card">
      <p>{text}</p>
      <button onClick={onDelete}>
        <TrashCan />
      </button>
    </div>
  );
};

export default SummaryItem;
