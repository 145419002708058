import './asset-overview.scss';
import CheckMark from '../../../../../assets/common/check-mark.svg';
import { Fragment, useContext, useState } from 'react';
import PrimaryButton from '../../../buttons/PrimaryButton';
import { ConciergeContext } from '../../../../../context/ConciergeContext';
import { useNavigate } from 'react-router-dom';

const items = [
  {
    key: 'netWorth',
    title: 'Net Worth',
    description: 'Extended liability (umbrella) coverage, life',
  },
  {
    key: 'homes',
    title: 'Home(s)',
    description: 'Primary, vacation, and income properties',
  },
  {
    key: 'vehicles',
    title: 'Vehicles',
    description: 'Autos, RVs, motorcycles, and offroad vehicles',
  },
  {
    key: 'watercraft',
    title: 'Watercraft',
    description: 'Yachts, boats, and jetskis',
  },
  {
    key: 'valuables',
    title: 'Valuables',
    description: 'Jewelry, art, antiques, wine, collectibles, etc.',
  },
  {
    key: 'aircraft',
    title: 'Aircraft',
    description: 'Planes, helicopters',
  },
  {
    key: 'business',
    title: 'Business',
    description: 'Commercial coverage for your business',
  },
];

const AssetItem = ({
  checked,
  title,
  description,
  onCheck,
}: {
  checked: boolean;
  title: string;
  description: string;
  onCheck: () => void;
}) => {
  return (
    <div className="item" onClick={onCheck}>
      <div className="checkbox">{checked && <CheckMark />}</div>
      <div className="details">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

const AssetOverview = () => {
  const { setUserAnswers, userAnswers, setLastStep } = useContext(ConciergeContext);
  const navigate = useNavigate();

  const defaultItems = items.reduce((result: any, item: any) => {
    result[item.key] = userAnswers?.assetItems.includes(item.key);
    return result;
  }, {});

  const [selectedItems, setSelectedItems] = useState(defaultItems);

  const onCheck = (key: string) => {
    const newObj = { ...selectedItems };
    newObj[key] = !newObj[key];
    setSelectedItems(newObj);
  };

  const onSubmit = () => {
    setUserAnswers({
      ...userAnswers,
      assetItems: Object.entries(selectedItems)
        .filter(([key, value]) => value)
        .map(([key, value]) => key),
    });
    setLastStep('assets/overview');
    navigate('/concierge/assets/details');
  };

  const isDisabled = !Object.values(selectedItems).includes(true);

  return (
    <div className="asset-overview-container">
      <h2 className="title">
        Great to meet you {userAnswers.about?.firstName}.<br></br> What are you looking to protect?
      </h2>

      <div className="content">
        <p className="content-title">Choose all that apply</p>
        <div className="items-container">
          {items.map((item) => (
            <Fragment key={item.key}>
              <AssetItem
                title={item.title}
                description={item.description}
                checked={selectedItems[item.key]}
                onCheck={() => onCheck(item.key)}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <PrimaryButton text="Next" disabled={isDisabled} onClick={onSubmit}></PrimaryButton>
    </div>
  );
};

export default AssetOverview;
