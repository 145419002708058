import { useContext, useMemo } from 'react';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import SelectField from '../../select-field/SelectField';
import './canopy-coverages-grid.scss';
import GridRow from './GridRow/GridRow';
import DoubleRow from './GridRow/DoubleRow';
import {
  formatAFIPriceString,
  formatPriorValue,
  generateSelectOptionsArray,
} from '../../../../services/helpers/AFIHelpers';
import { customStateOptions } from '../../../../constants/AfiStateQuestions';
import { AfiQuoteType } from '../../../interfaces/AFIInterfaces';
import { Spinner } from 'react-bootstrap';

const CanopyCoverageGrid = ({
  vehicleDetails,
  toggleAdjust,
  state,
  error,
  onClickSave,
  coverages,
  handleSelect,
  carrier,
  setCarrier,
  isLoading,
}: {
  vehicleDetails: any[];
  toggleAdjust: boolean;
  setToggleAdjust: Function;
  state: string;
  error: boolean;
  coverages: any;
  handleSelect: any;
  onClickSave: React.MouseEventHandler<HTMLButtonElement>;
  carrier: AfiQuoteType;
  setCarrier: Function;
  isLoading: boolean;
}) => {
  const { currentCanopyInsurer, quotes, priorPolicyCoverage } = useContext(AnswerFinancialContext);
  const fillGridCell = (toggleAdjust: boolean, name: string, key: string) => {
    let options;
    let formatKey =
      key === 'primaryPropertyDamage' || key === 'uninsuredPropertyDamage'
        ? 'property damage'
        : key === 'underinsuredMotoristsBodilyInjury'
        ? 'bodilyInjury'
        : '';

    const formatedValue = formatAFIPriceString(coverages[key], formatKey);

    if (name === 'rental' || name === 'towing') {
      options = [
        { displayName: 'Not covered', value: 'None' },
        { displayName: 'Covered', value: 'Covered' },
      ];
    } else {
      options = generateSelectOptionsArray(customStateOptions[state][name]);
    }
    return toggleAdjust ? (
      <SelectField
        disabled={isLoading}
        options={options}
        name={key}
        placeholder={formatedValue}
        handleChange={handleSelect}
        value={coverages[key] === 'None' ? 'Not covered' : coverages[key]}
      />
    ) : (
      <span className="value">{formatedValue}</span>
    );
  };

  const carrierOptions = useMemo(() => {
    return quotes.map((elem) => ({
      id: elem.insurer.name,
      value: elem.insurer.name,
    }));
  }, [quotes]);

  return (
    <div className="coverages-container">
      {error && <p className="canopy-quotes-error">No quotes for your selection.</p>}
      <section className="coverages-grid">
        {/* Top Row of the Grid */}
        <div className="empty-area first-column"></div>
        <div className="prev-insurer second-column">
          <p
            className="prev-current-insurer"
            style={{
              color: `${
                currentCanopyInsurer.color ? currentCanopyInsurer.color : 'var(--secondary-font-color)'
              }`,
            }}
          >
            {currentCanopyInsurer.name}
          </p>
          <div className="prev-monthly">
            <p>${priorPolicyCoverage?.amount}</p>
            <span>monthly</span>
          </div>
        </div>

        <div className="coverages-dropdown third-column">
          <SelectField
            disabled={toggleAdjust}
            options={carrierOptions}
            handleChange={(e) => {
              const newValue = quotes.find((elem) => elem.insurer.name === e.target.value) || quotes[0];
              setCarrier(newValue);
            }}
            name="carrier"
            placeholder={'Select Carrier'}
            value={carrier.insurer.name}
          />
        </div>

        <div className="adjust third-column">
          <div className="new-monthly">
            <p>{formatAFIPriceString(carrier.policyInfo.fullAmount, carrier.policyInfo.duration)}</p>
            <span>monthly</span>
          </div>

          <button onClick={onClickSave} disabled={isLoading}>
            {toggleAdjust ? isLoading ? <Spinner /> : 'Save' : 'Adjust'}
          </button>
        </div>

        {[
          {
            rowLabel: 'Primary liability',
            type: 'double',
            rowPrevValuePerPerson: formatPriorValue(
              priorPolicyCoverage?.vehicles[0]?.bodilyInjuryLiability
              ,
              'bodilyInjury',
              'perPerson',
            ),
            rowPrevValuePerAccident: formatPriorValue(
              priorPolicyCoverage?.vehicles[0]?.bodilyInjuryLiability,
              'bodilyInjury',
              'perAccident',
            ),
            key: 'primaryBodilyInjuryLimit',
            toggleAdjust: toggleAdjust,
            selectOptions: generateSelectOptionsArray(customStateOptions[state].bodilyInjuryLiability),
          },
          {
            rowLabel: 'Property Damage',
            rowPrevValue: formatPriorValue(priorPolicyCoverage?.vehicles[0]?.propertyDamageLiability, ''),
            rowNewValue: fillGridCell(toggleAdjust, 'propertyDamageLiability', 'primaryPropertyDamage'),
          },
          {
            rowLabel: 'Medical Payments',
            rowPrevValue: formatPriorValue(priorPolicyCoverage?.vehicles[0]?.medPay, ''),
            rowNewValue: fillGridCell(toggleAdjust, 'medPay', 'medPay'),
            rowClassname: 'last-row',
          },
          {
            rowLabel: 'Uninsured Liability',
            type: 'double',
            rowPrevValuePerPerson: formatPriorValue(
              priorPolicyCoverage?.vehicles[0].uninsuredMotoristsBodilyInjury,
              'bodilyInjury',
              'perPerson',
            ),
            rowPrevValuePerAccident: formatPriorValue(
              priorPolicyCoverage?.vehicles[0].uninsuredMotoristsBodilyInjury,
              'bodilyInjury',
              'perAccident',
            ),
            key: 'uninsuredBodilyInjury',
            toggleAdjust: toggleAdjust,
            selectOptions: generateSelectOptionsArray(
              customStateOptions[state].uninsuredMotoristsBodilyInjury,
            ),
          },
          {
            rowLabel: 'Property Damage',
            rowPrevValue: formatPriorValue(
              priorPolicyCoverage?.vehicles[0].uninsuredMotoristsPropertyDamage,
              '',
            ),
            rowNewValue: fillGridCell(
              toggleAdjust,
              'uninsuredMotoristsPropertyDamage',
              'uninsuredPropertyDamage',
            ),
          },
          {
            rowLabel: 'Underinsured bodily injury limit',
            rowPrevValue: formatPriorValue(
              priorPolicyCoverage?.vehicles[0]?.uninsuredMotoristsBodilyInjury,
              '',
            ),
            rowNewValue: fillGridCell(
              toggleAdjust,
              'uninsuredMotoristsBodilyInjury',
              'underinsuredMotoristsBodilyInjury',
            ),
            rowClassname: 'last-row',
          },
        ].map((row, index) => {
          return row.type === 'double' ? (
            <DoubleRow
              key={`${row.rowLabel}-${index}`}
              label={row.rowLabel}
              rowPrevValuePerPerson={row.rowPrevValuePerPerson}
              rowPrevValuePerAccident={row.rowPrevValuePerAccident}
              coverages={coverages}
              covKey={row.key}
              handleSelect={handleSelect}
              toggleAdjust={toggleAdjust}
              selectOptions={row.selectOptions}
              isLoading={isLoading}
            />
          ) : (
            <GridRow
              key={`${row.rowLabel}-${index}`}
              rowLabel={row.rowLabel}
              rowPrevValue={row.rowPrevValue}
              rowNewValue={row.rowNewValue}
              rowClassname={row.rowClassname}
            />
          );
        })}

        {/* Cars section */}
      </section>
      {vehicleDetails[0].details.vehicleMake !== '' &&
        vehicleDetails.map((car, index) => (
          <section key={`car.details.vehicleMake-${index}`} className="car-grid">
            <p className="car first-column first-row">
              <span className="label-title">{`${car.details.vehicleYear} ${car.details.vehicleMake}  ${car.details.vehicleModel} coverage`}</span>
            </p>
            <p className="car second-column first-row"></p>
            <p className="car third-column first-row"></p>
            {[
              {
                rowLabel: 'Comprehensive deductible',
                rowPrevValue: formatPriorValue(
                  priorPolicyCoverage?.vehicles[index]?.comprehensiveDeductible ||
                    priorPolicyCoverage?.vehicles[0]?.comprehensiveDeductible,
                  '',
                ),
                rowNewValue: fillGridCell(
                  toggleAdjust,
                  'comprehensiveDeductible',
                  `V${index + 1}_comprehensiveDeductible`,
                ),
                rowClassname: 'car',
              },
              {
                rowLabel: 'Collision deductible',
                rowPrevValue: formatPriorValue(
                  priorPolicyCoverage?.vehicles[index]?.collisionDeductible ||
                  priorPolicyCoverage?.vehicles[0]?.collisionDeductible,
                  '',
                ),
                rowNewValue: fillGridCell(
                  toggleAdjust,
                  'collisionDeductible',
                  `V${index + 1}_collisionDeductible`,
                ),
                rowClassname: 'car',
              },
              {
                rowLabel: 'Rental Reimbursement',
                rowPrevValue: formatPriorValue(
                  priorPolicyCoverage?.vehicles[index]?.coveragesRental ||
                    priorPolicyCoverage?.vehicles[0]?.coveragesRental,
                  '',
                ),
                rowNewValue: fillGridCell(toggleAdjust, 'rental', `V${index + 1}_coveragesRental`),
                rowClassname: 'car',
              },
              {
                rowLabel: 'Towing & Labor',
                rowPrevValue: formatPriorValue(
                  priorPolicyCoverage?.vehicles[index]?.coveragesTowing ||
                    priorPolicyCoverage?.vehicles[0]?.coveragesTowing ,
                  '',
                ),
                rowNewValue: fillGridCell(toggleAdjust, 'towing', `V${index + 1}_coveragesTowing`),
                rowClassname: 'car towing',
              },
            ].map((row, index) => (
              <GridRow key={`${row.rowLabel}-${index}`} {...row} />
            ))}
          </section>
        ))}
    </div>
  );
};

export default CanopyCoverageGrid;
