import { useContext } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import AmountField from '../../forms/AmountField/AmountField';
import { useFormik } from 'formik';
import { amountFieldSchema } from '../../../schemas/lifeSchemas';
import { UserContext } from '../../../../context/UserContext';
import { savingsImages } from '../../../../constants/LifeConstants';

const savings = '';

const Savings = () => {
  const { lifeResponses, setLifeResponses } = useContext(LifeInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      savings: typeof lifeResponses.savings === "number" ? `${lifeResponses.savings}` : savings,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: amountFieldSchema,
    onSubmit: () => { },
  });

  const { values, setFieldValue } = formik;

  const handleSubmit = () => {
    setLifeResponses((prev) => ({
      ...prev,
      savings: parseInt(values.savings),
    }));
    navigate('/life/debt');
  };

  const formContent = {
    header:
      'What is your net worth?',
    subheader:
      'Add up the value of your home equity, savings, and any other asset you have like retirement accounts and stocks',
    img: savingsImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: values.savings.length < 1,
    onSubmit: handleSubmit,
    fields: [
      <AmountField
        placeholder="Enter Amount"
        name="savings"
        onSubmit={handleSubmit}
        value={values.savings}
        setFieldValue={setFieldValue}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        subHeader={formContent.subheader}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
        disabledButton={formContent.disableButton}
      />
    </>
  );
};

export default Savings;
