import './multiple-options.scss';
import CheckMark from '../../../assets/common/check-mark.svg';

const MultipleOptions = ({
  options,
  onCheck,
}: {
  options: { [key: string]: boolean };
  onCheck: (name: string, value: boolean) => void;
}) => {
  return (
    <div className="multiple-options-container">
      {Object.keys(options).map((option) => {
        return (
          <button
            className="option"
            key={option}
            onClick={() => onCheck(option, !options[option])}
            aria-label={option + ' ' + (options[option] ? 'checked' : 'unchecked')}
          >
            <div className="check-icon">{options[option] && <CheckMark />}</div>
            <p>{option}</p>
          </button>
        );
      })}
    </div>
  );
};

export default MultipleOptions;
