import LayoutContent from "../../../../layouts/layout-content-container/LayoutContentContainer";
import "./Thanks.scss"

const Thanks = () => {
    const ThanksImg = require(`./thanks.svg`).default;

    return (
        <LayoutContent>
            <div>
                <h1 className="title">
                    Thank you
                </h1>
                <p className="thanks-text">
                    Thank you for taking the time to tell us about you, your family and your assets.  Based on your responses, we are matching you with a <b>licensed specialist</b> with expertise for your unique situation.
                </p>
                <p className="thanks-text">
                    <b>Within 3 business days</b>, the specialist will reach out to you and your investment advisor to schedule your Risk Management consultation.
                </p>
                <p className="thanks-text">
                    Thank you and we look forward to this next step.
                </p>
                <div className="img-container">
                    <ThanksImg />
                </div>
            </div>
        </LayoutContent>
    );
}

export default Thanks;