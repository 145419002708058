import { useRef, useEffect } from 'react';
import './birthday-input.scss';
import { useFormik } from 'formik';
import { date, object, string } from 'yup';
import ErrorIcon from '../../../assets/common/error-icon.svg';

const BirthdayInput = ({
  defaultData,
  onChange,
  customValidation,
}: {
  defaultData: string; // e.g.: birthday
  onChange: (newValue: string) => void; // e.g.: setBirthday
  customValidation?: any; // e.g.: yupValidations.ageMinMax(18, 90)
}) => {
  const [defaultMonth, defaultDay, defaultYear] = defaultData.includes('/')
    ? defaultData.split('/') || ['', '', '']
    : ['', '', ''];

  // fullDate will check any custom validations passed as a prop (e.g.: yupValidations.ageMinMax(18, 90)), validation must be of date() type
  // month and day do not require a length validation, as they will either be 0 => Required message, 1 => will be corrected on blur, 2 => valid
  const validationSchema = object({
    month: string().required('Required'),
    day: string().required('Required'),
    year: string().length(4, 'Must have 4 digits').required('Required'),
    fullDate: date().typeError('Invalid Date').concat(customValidation),
  });

  const formik = useFormik({
    initialValues: {
      month: defaultMonth,
      day: defaultDay,
      year: defaultYear,
      fullDate: defaultData,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, touched, setFieldValue, handleSubmit, setFieldTouched } =
    formik;

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const newDate = values.month + '/' + values.day + '/' + values.year;
    setFieldValue('fullDate', newDate);
    if (values.month && values.day && values.year.length === 4 && !errors.fullDate) {
      onChange(newDate);
    }
  }, [values.month, values.day, values.year, errors]);

  useEffect(() => {
    if (values.month.length === 2) {
      dayRef.current?.focus();
    }
  }, [values.month]);

  useEffect(() => {
    if (values.day.length === 2) {
      yearRef.current?.focus();
    }
  }, [values.day]);

  // If the user inputs a single digit as a month (e.g.: January as "1"), the value will be filled once the field loses focus
  const handleMonthComplete = (e: React.FocusEvent) => {
    if (values.month.length === 1) {
      setFieldValue('month', '0' + values.month);
    }
    handleBlur(e);
    setFieldTouched('month');
  };
  // If the user inputs a single digit as a day, the value will be filled once the field loses focus
  const handleDayComplete = (e: React.FocusEvent) => {
    if (values.day.length === 1) {
      setFieldValue('day', '0' + values.day);
    }
    handleBlur(e);
    setFieldTouched('day');
  };

  // Keyboard navigation: Backspace & Arrow Left => travel to previous field, Arrow Right => travel to next field, Tab works by default
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    if (e.target)
      switch (e.key) {
        case 'Backspace':
          if (inputElement.value === '') {
            switch (inputElement.name) {
              case 'day':
                monthRef.current?.focus();
                break;
              case 'year':
                dayRef.current?.focus();
                break;
              default:
                break;
            }
          }
          break;
        case 'ArrowLeft':
          if (inputElement.selectionStart === 0) {
            switch (inputElement.name) {
              case 'day':
                monthRef.current?.focus();
                break;
              case 'year':
                dayRef.current?.focus();
                break;
              default:
                break;
            }
          }
          break;
        case 'ArrowRight':
          if (inputElement.selectionStart === inputElement.value.length) {
            switch (inputElement.name) {
              case 'day':
                yearRef.current?.focus();
                break;
              case 'month':
                dayRef.current?.focus();
                break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
  };

  const getErrorMessage = () => {
    // Full date errors will get priority, but only if all fields are completed
    if (values.day && values.month && values.year.length === 4 && errors.fullDate) {
      return errors.fullDate;
    }
    // Will swap key:value pairs in the errors object
    // {month: Required, day: "Required", year:"Must have 4 digits"} => {"Required":["month","day"], "Must have 4 digits":["year"]}
    let str = '';
    Object.entries(errors)
      .filter(([key, value]) => key !== 'fullDate')
      .forEach(([key, value]) => {
        if (key in touched) {
          str += `${value}: ${key}. `;
        }
      });
    return str;
  };

  const shouldDisplayError = (field: 'day' | 'month' | 'year' | 'all') => {
    if (!(touched.month && touched.day && touched.year)) return false; // Should not display errors if any form field hasn't been touched
    if (values.day && values.month && values.year.length === 4 && errors.fullDate) return true; // A full date error will trigger the error display on all fields
    if (field === 'all') {
      if (errors.day || errors.month || errors.year) return true;
    } else {
      if (errors[field]) return true;
    }
  };

  return (
    <>
      <div className="birthday-container">
        <div className="input-fields">
          <input
            className={`${shouldDisplayError('month') ? 'input-error month' : 'month'}`}
            type="text"
            inputMode="numeric"
            name="month"
            value={values.month}
            placeholder="MM"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            maxLength={2}
            ref={monthRef}
            onBlur={handleMonthComplete}
          />
          <input
            className={`${shouldDisplayError('day') ? 'input-error day' : 'day'}`}
            type="text"
            inputMode="numeric"
            name="day"
            placeholder="DD"
            value={values.day}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            maxLength={2}
            ref={dayRef}
            onBlur={handleDayComplete}
          />
          <form className="year" onSubmit={handleSubmit}>
            <input
              className={`${shouldDisplayError('year') ? 'input-error year' : 'year'}`}
              type="text"
              inputMode="numeric"
              name="year"
              placeholder="YYYY"
              value={values.year}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              maxLength={4}
              ref={yearRef}
              onBlur={() => {
                setFieldTouched('year');
                handleBlur;
              }}
            />
            {/* Error icon will only be displayed on the year field regardless if the error refers to this specific field */}
            {shouldDisplayError('all') && <ErrorIcon />}
          </form>
        </div>
        {<div className="error">{shouldDisplayError('all') && getErrorMessage()}</div>}
      </div>
    </>
  );
};

export default BirthdayInput;
