import { useFormik } from 'formik';
import ShortAddressForm from '../../../../forms/ShortAddressForm/ShortAddressForm';
import './homes.scss';
import { Fragment, useContext, useState } from 'react';
import { ConciergeContext } from '../../../../../../context/ConciergeContext';
import { array, object } from 'yup';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import SummaryItem from '../../../../SummaryItem/SummaryItem';
import SecondaryButton from '../../../../buttons/SecondaryButton';

const defaultHome = {
  street: '',
  city: '',
  state: '',
  zip: '',
  description: '',
};

const Homes = ({ onSubmit }: { onSubmit: (newValues: any) => void }) => {
  const { userAnswers } = useContext(ConciergeContext);
  const defaultAnswers = userAnswers?.assets?.homes || [];

  const [isHomeValid, setIsHomeValid] = useState(false);
  const [currentHome, setCurrentHome] = useState({ ...defaultHome });

  const formikConfig = useFormik({
    initialValues: {
      homes: defaultAnswers,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: object({
      homes: array().min(1),
    }),
    onSubmit: () => { },
  });

  const { values, setFieldValue, isValid } = formikConfig;

  const handleDeleteAddress = (index: number) => {
    const newHomes = [...values.homes];
    newHomes.splice(index, 1);
    setFieldValue('homes', newHomes);
  };

  const handleAddHome = () => {
    const newHomes = [...values.homes, currentHome];
    setCurrentHome({ ...defaultHome });
    setFieldValue('homes', newHomes);
  };
  const handleNext = () => {
    onSubmit(isHomeEmpty ? values.homes : [...values.homes, currentHome]);
  };

  const isHomeEmpty = !(
    currentHome.street ||
    currentHome.city ||
    currentHome.state ||
    currentHome.zip ||
    currentHome.description
  );
  const shouldProgress = () => {
    if (!isHomeEmpty && !isHomeValid) return false;
    if (
      !isValid && // If the formik configuration is not valid, we can still progress, but we must check:
      values.homes.length === 0 && // 1. We must not have any home in the formik object
      isHomeValid // 2. The current home form is valid, even if it hasn't been added to the formik object yet
    )
      return true;
    // For any other case, we follow the formik validation
    return isValid;
  };

  return (
    <div className="homes-container">
      {values.homes.map((home, index) => (
        <SummaryItem
          key={index}
          text={`${home.street}, ${home.city}, ${home.state} ${home.zip}`}
          onDelete={() => handleDeleteAddress(index)}
        />

      ))}
      <ShortAddressForm
        addressInfo={currentHome}
        onInputChange={(newValues) => setCurrentHome(newValues)}
        setIsFormValid={setIsHomeValid}
      />
      <SecondaryButton
        onClick={() => handleAddHome()}
        text={<p>Add an additional residence</p>}
        disabled={!isHomeValid}
      ></SecondaryButton>
      <div className="submit-button-container">
        <PrimaryButton text="Next" disabled={!shouldProgress()} onClick={handleNext}></PrimaryButton>
      </div>
    </div>
  );
};

export default Homes;
