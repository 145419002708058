import { useState, createContext, FC } from 'react';
import { VehicleInterface } from '../components/interfaces/VehicleInterfaces';
import { getProgressStep } from '../pages/auto-insurance/constants';
import { defaultVehicles } from '../pages/auto-insurance/constants';

type setStringFunction = (prev: string) => void;
type driverObject = {
  [key: string]: number | string;
};

type AutoContextType = {
  zip: string;
  setZip: Function;
  currentStep: number;
  setCurrentStep: (prev: number) => void;
  totalSteps: number;
  vehicles: Array<VehicleInterface>;
  setVehicles: (prev: Array<VehicleInterface>) => void;
  progressStep: number;
  setProgressStep: Function;
  hasSecondVehicle: string;
  setHasSecondVehicle: setStringFunction;
  hasDriversLicense: string;
  setHasDriversLicense: setStringFunction;
  hasTickets: string;
  setHasTickets: setStringFunction;
  hasAccident: string;
  setHasAccident: setStringFunction;
  hasDui: string;
  setHasDui: setStringFunction;
  isHomeowner: string;
  setIsHomeOwner: setStringFunction;
  isInsured: string;
  setIsInsured: Function;
  insuranceProviderName: string;
  setInsuranceProviderName: Function;
  currentInsuranceProvider: number;
  setCurrentInsuranceProvider: Function;
  termLength: string;
  setTermLength: Function;
  updateAutoInsuranceStep: Function;
  address: string;
  setAddress: Function;
  longState: string;
  setLongState: Function;
  shortState: string;
  setShortState: Function;
  city: string;
  setCity: Function;
  phoneNumber: string;
  setPhoneNumber: Function;
  hasExtraDrivers: string;
  setHasExtraDrivers: Function;
  driverInfo: driverObject[];
  setDriverInfo: Function;
  confirmedDisclaimer: boolean;
  setConfirmedDisclaimer: Function;
  showModal: boolean;
  setShowModal: Function;
  stepAnimationType: string;
  setStepAnimationType: Function;
};

const AutoInsuranceContext = createContext<AutoContextType>({
  zip: '',
  setZip: () => {},
  vehicles: defaultVehicles,
  currentStep: 0,
  setCurrentStep: (p: number) => {},
  totalSteps: 16,
  progressStep: 1,
  setProgressStep: (p: number) => {},
  hasSecondVehicle: 'No',
  hasDriversLicense: '',
  hasTickets: '',
  hasAccident: '',
  hasDui: '',
  isHomeowner: 'No',
  hasExtraDrivers: 'No',
  setIsHomeOwner: (p: string) => {},
  setVehicles: (p: Array<VehicleInterface>) => {},
  setHasSecondVehicle: (p: string) => {},
  setHasDriversLicense: (p: string) => {},
  setHasTickets: (p: string) => {},
  setHasAccident: (p: string) => {},
  setHasDui: (p: string) => {},
  isInsured: 'Yes',
  setIsInsured: (p: string) => {},
  insuranceProviderName: '',
  setInsuranceProviderName: (p: string) => {},
  currentInsuranceProvider: 0,
  setCurrentInsuranceProvider: (p: number) => {},
  termLength: 'Less than 1',
  setTermLength: (p: string) => {},
  setHasExtraDrivers: Function,
  updateAutoInsuranceStep: Function,
  address: '',
  setAddress: Function,
  city: '',
  setCity: Function,
  longState: '',
  setLongState: Function,
  shortState: '',
  setShortState: Function,
  phoneNumber: '',
  setPhoneNumber: Function,
  driverInfo: [
    {
      gender: 1,
      marital_status: 1,
      military_status: 1,
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      creditScore: '750+',
    },
  ],
  setDriverInfo: Function,
  confirmedDisclaimer: false,
  setConfirmedDisclaimer: Function,
  showModal: false,
  setShowModal: Function,
  stepAnimationType: 'slide-in',
  setStepAnimationType: Function,
});

interface Props {
  children: React.ReactNode;
}

const AutoInsuranceProvider: FC<Props> = ({ children }) => {
  const [zip, setZip] = useState('');
  const [vehicles, setVehicles] =
    useState<Array<VehicleInterface>>(defaultVehicles);
  const [hasSecondVehicle, setHasSecondVehicle] = useState('No');
  const [isInsured, setIsInsured] = useState('Yes');
  const [currentInsuranceProvider, setCurrentInsuranceProvider] = useState(0);
  const [insuranceProviderName, setInsuranceProviderName] = useState('');
  const [termLength, setTermLength] = useState('Less than 1');
  const [hasDriversLicense, setHasDriversLicense] = useState('Yes');
  const [hasTickets, setHasTickets] = useState('No');
  const [hasAccident, setHasAccident] = useState('No');
  const [hasDui, setHasDui] = useState('No');
  const [isHomeowner, setIsHomeOwner] = useState('No');
  const [hasExtraDrivers, setHasExtraDrivers] = useState('No');
  const [currentStep, setCurrentStep] = useState(0);
  const [progressStep, setProgressStep] = useState(0);
  const [driverInfo, setDriverInfo] = useState([
    {
      gender: 1,
      marital_status: 1,
      military_status: 1,
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      creditScore: '750+',
    },
  ]);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [longState, setLongState] = useState('');
  const [shortState, setShortState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmedDisclaimer, setConfirmedDisclaimer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stepAnimationType, setStepAnimationType] = useState('slide-in');
  const totalSteps = 10;

  const updateAutoInsuranceStep = (stepName: string) => {
    const value = getProgressStep(stepName);
    setProgressStep(value);
  };

  return (
    <AutoInsuranceContext.Provider
      value={{
        totalSteps,
        hasSecondVehicle,
        setHasSecondVehicle,
        isInsured,
        setIsInsured,
        hasDriversLicense,
        setHasDriversLicense,
        hasTickets,
        setHasTickets,
        hasAccident,
        setHasAccident,
        hasDui,
        setHasDui,
        isHomeowner,
        setIsHomeOwner,
        currentStep,
        setCurrentStep,
        progressStep,
        setProgressStep,
        insuranceProviderName,
        setInsuranceProviderName,
        currentInsuranceProvider,
        setCurrentInsuranceProvider,
        termLength,
        setTermLength,
        vehicles,
        setVehicles,
        zip,
        setZip,
        updateAutoInsuranceStep,
        address,
        setAddress,
        city,
        setCity,
        longState,
        setLongState,
        shortState,
        setShortState,
        phoneNumber,
        setPhoneNumber,
        hasExtraDrivers,
        setHasExtraDrivers,
        driverInfo,
        setDriverInfo,
        confirmedDisclaimer,
        setConfirmedDisclaimer,
        showModal,
        setShowModal,
        stepAnimationType,
        setStepAnimationType,
      }}
    >
      {children}
    </AutoInsuranceContext.Provider>
  );
};

export { AutoInsuranceContext, AutoInsuranceProvider };
