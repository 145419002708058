import { useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { number, object, string } from 'yup';
import InputField from '../InputField/InputField';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import SelectField from '../../select-field/SelectField';
import { states } from '../../../../constants';
import './short-address-form.scss';
import { generateSelectOptions } from '../../../../services/helpers/selectFieldHelpers';
import { addressDescriptionOptions } from '../../../../constants/ConciergeConstants';
import { getAddressAutocomplete } from '../../../../services/helpers/CommonHelpers';
const searchOptions = {
  componentRestrictions: { country: ['us'] },
};

const ShortAddressForm = ({
  addressInfo,
  onInputChange,
  setIsFormValid,
}: {
  addressInfo: any;
  onInputChange: (newValues: any) => void;
  setIsFormValid: (newValue: boolean) => void;
}) => {
  const formikConfig = useFormik({
    initialValues: {
      street: addressInfo.street,
      city: addressInfo.city,
      state: addressInfo.state,
      zip: addressInfo.zip,
      description: addressInfo.description,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: object({
      street: string().required('Street address is required'),
      city: string().required('City is required'),
      state: string()
        .oneOf(states.map((state) => state.value))
        .required('State is required'),
      zip: number().required('Zipcode is required').typeError('Zipcode must be a number'),
      description: string().oneOf(addressDescriptionOptions).required('Description is required'),
    }),
    onSubmit: () => {},
  });

  const { values, handleChange, errors, setErrors, touched, isValid, setFieldValue, handleBlur } =
    formikConfig;

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  const onChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    handleChange(e);
    onInputChange({ ...values, [e.target.name]: e.target.value });
  };

  const handleSelect = async (address: string) => {
    const { street, city, state, zip } = await getAddressAutocomplete(address);

    setFieldValue('street', street);
    setFieldValue('city', city);
    setFieldValue('state', state);
    setFieldValue('zip', zip);
    onInputChange({
      street: street,
      city: city,
      state: state,
      zip: zip,
      description: values.description,
    });
  };

  const handleStreetChange = (newValue: string) => {
    setFieldValue('street', newValue);
    onInputChange({ ...addressInfo, street: newValue });
  };

  return (
    <div className="short-address-form-container">
      <PlacesAutocomplete
        value={values.street || ''}
        onChange={handleStreetChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="location-search-container question-field-container">
            <input
              {...getInputProps({
                placeholder: 'Street Address',
                name: 'street',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: suggestion.active ? 'suggesstion-item-active' : 'suggesstion-item',
                  })}
                  key={index}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <InputField
        name="city"
        placeholder="City"
        value={values.city}
        onChange={onChange}
        onBlur={handleBlur}
        setErrors={setErrors}
        type="text"
        errors={touched.city ? errors : null}
      ></InputField>
      <div className="state-info-box">
        <SelectField
          options={states}
          value={values.state}
          handleBlur={() => handleBlur}
          handleChange={onChange}
          name="state"
          placeholder="State"
          error={touched.state ? errors.state : null}
        ></SelectField>
        <InputField
          name="zip"
          placeholder="Zipcode"
          value={values.zip}
          onChange={onChange}
          onBlur={handleBlur}
          setErrors={setErrors}
          type="text"
          errors={touched.zip ? errors : null}
        ></InputField>
      </div>

      <SelectField
        options={generateSelectOptions(addressDescriptionOptions)}
        handleChange={onChange}
        handleBlur={() => handleBlur}
        placeholder="This home is..."
        name="description"
        value={values.description}
        error={touched.description ? errors.state : null}
      ></SelectField>
    </div>
  );
};

export default ShortAddressForm;
