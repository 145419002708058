import './question-field.scss';
import { FormikProps, FormikValues } from 'formik';
import { AFIQuestionInterface, OptionType } from '../../../interfaces/AFIInterfaces';
import { SelectOptionInterface } from '../../../interfaces/InputInterfaces';
import DateField from '../../forms/DateField/DateField';
import InputField from '../../forms/InputField/InputField';
import RadioButtonContainer from '../../radio-button-container/RadioButtonContainer';
import SelectField from '../../select-field/SelectField';
import PhoneField from '../../forms/PhoneField/PhoneField';
import CheckboxOption from '../../forms/CheckboxOption/CheckboxOption';
import { getNumberInputs } from '../../../../services/helpers/inputFieldHelpers';
import { useContext } from 'react';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { vehicleMakers } from '../../../../constants';
import { useMemo } from 'react';
import { generateSelectOptionsArray } from '../../../../services/helpers/AFIHelpers';
import _ from 'lodash';
import ShortDateField from '../../forms/ShortDateField/ShortDateField';

const QuestionField = ({
  questionObject,
  formikConfig,
}: {
  questionObject: AFIQuestionInterface;
  formikConfig: FormikProps<FormikValues>;
}) => {
  const { name, placeholder, disabled, options, title, validation } = questionObject;
  const { values, setFieldValue, setFieldError, errors, setErrors, handleChange, touched, setFieldTouched, validateField } =
    formikConfig;
  const { setVehicleMake, vehicleMake, isCanopy } = useContext(AnswerFinancialContext);

  const customOptions = useMemo(() => {
    if (name === 'vehicleModel' && vehicleMake) {
      const vehicleModels = Object.assign(
        {},
        ...vehicleMakers[vehicleMake].map((elem: string) => ({ [elem]: elem })),
      );
      return generateSelectOptionsArray(vehicleModels);
    }
    return options;
  }, [vehicleMake, options]);

  const getField = () => {
    switch (questionObject.type) {
      case 'phone':
        return <PhoneField name={name} formikConfig={formikConfig} placeholder={placeholder} />;
      case 'email':
        return (
          <InputField
            type="email"
            name={name}
            placeholder={placeholder}
            value={values[name] || ''}
            setErrors={setErrors}
            onChange={handleChange}
            disabled={disabled}
            errors={touched[name] ? errors : {}}
            onBlur={() => setFieldTouched(name, true)}
          />
        );
      case 'date':
        return (
          <DateField
            value={values[name]}
            setValue={(value: any) => setFieldValue(name, value)}
            errors={touched[name] ? errors : {}}
            setErrors={setErrors}
            placeholder={placeholder}
            setFieldTouched={setFieldTouched}
            formik={true}
            name={name}
          />
        );
      case 'short-date':
        return (
          <ShortDateField
            fieldName={name}
            fieldError={touched[name] ? errors[name] : null}
            value={values[name] || ''}
            setDateValue={(value: string) => setFieldValue(name, value)}
            setFieldError={setFieldError}
            setDateFieldTouched={() => setFieldTouched(name, true)}
            validateField={validateField}
          />
        );
      case 'year':
        return (
          <SelectField
            handleChange={handleChange}
            name={name}
            options={options as SelectOptionInterface[]}
            placeholder={placeholder}
            disabled={disabled}
            value={values[name]}
            error={isCanopy && errors[name]}
          />
        );
      case 'select':
        return (
          <SelectField
            handleChange={(e) => {
              if (name === 'vehicleMake') {
                setVehicleMake(e.target.value);
                setFieldValue('vehicleModel', '');
              }
              handleChange(e);
            }}
            name={name}
            options={customOptions as SelectOptionInterface[]}
            placeholder={placeholder}
            disabled={disabled}
            value={values[name]}
            error={isCanopy && touched[name] && errors[name]}
          />
        );
      case 'checkbox':
        return (
          <CheckboxOption
            label={title}
            value={values[name]}
            onSelect={(val: string) => setFieldValue(name, val)}
            options={options as OptionType[]}
          />
        );
      case 'radio':
        return (
          <RadioButtonContainer
            options={
              options?.map((item) => {
                return item.value || '';
              }) || []
            }
            labels={
              options?.map((item) => {
                return item.displayName || '';
              }) || []
            }
            size={options && options.length > 2 ? 'medium' : 'small'}
            value={values[name]}
            handleChange={(e) => formikConfig.setFieldValue(name, e)}
          />
        );
      case 'address':
        break;
      case 'amount':
        return (
          <div className={`afi-amount-field ${values[name] === '' ? 'placeholder' : ''}`}>
            <InputField
              type="text"
              inputMode="numeric"
              name={name}
              placeholder={placeholder}
              value={values[name] || ''}
              errors={touched[name] ? errors : {}}
              setErrors={setErrors}
              onChange={handleChange}
              disabled={disabled}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
            />
          </div>
        );
      default:
      case 'input':
        return (
          <InputField
            type="text"
            name={name}
            placeholder={placeholder}
            value={values[name] || ''}
            errors={touched[name] ? errors : {}}
            setErrors={setErrors}
            onChange={(e) => {
              if (name === 'annualMileage') {
                if (e.target.value !== '' && getNumberInputs(e.target.value.replace(/,/g, ''))) {
                  e.target.value = parseFloat(e.target.value.replace(/,/g, '')).toLocaleString();
                }
                handleChange(e);
                return;
              }
              handleChange(e);
            }}
            disabled={disabled}
            onBlur={() => {
              setFieldTouched(name, true);
            }}
          />
        );
    }
  };

  return <div className="question-field-container">{getField()}</div>;
};

export default QuestionField;
