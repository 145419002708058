import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import InputField from '../../components/common/forms/InputField/InputField';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { introImages } from '../../constants/AutoConstants';
import { legalComplianceURL } from '../../constants/CommonConstants';
import { UserContext } from '../../context/UserContext';
import { setAutoAddress } from '../../services/helpers/AutoHelpers';
import { afiIntroContent, zipCodeLimit } from '../auto-insurance/constants';
import './afi.scss';
import { getNumberInputs } from '../../services/helpers/inputFieldHelpers';
import { yupMessages } from '../../services/yupValidations';
import { useFormik } from 'formik';
import { getStateByZip } from '../../services/helpers/CommonHelpers';
import { afiStates } from '../../constants/CommonConstants';
import { AutoInsuranceContext } from '../../context/AutoInsuranceContext';
import { zipCodeSchema } from '../../components/schemas/afiSchema';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';

const AfiIntro = () => {
  const { headerContent, partnerName, device, setShowDisclaimer, showDisclaimer } = useContext(UserContext);
  const { setIsCanopy } = useContext(AnswerFinancialContext);
  const { setProgressStep, setStepAnimationType, setShortState, setCity, setZip } =
    useContext(AutoInsuranceContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      zip: '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: zipCodeSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, setErrors, touched, setFieldTouched } = formik;

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    if (getNumberInputs(e.target.value) && e.target.value.length <= zipCodeLimit) {
      handleChange(e);
    }
  };

  const handleClick = async () => {
    const getState = getStateByZip(values.zip);
    if (getState.value === 'error') {
      setFieldTouched('zip', true);
      setErrors({ zip: yupMessages.zipCodeInvalid });
      return;
    }

    if (afiStates.includes(getState.value)) {
      setIsCanopy(true);
      navigate('/full-quote-auto/import/current-insurer');
    } else {
      const res = await setAutoAddress(
        values.zip,
        setErrors,
        setShortState,
        setCity,
        setZip,
        setStepAnimationType,
      );
      if (res) {
        setProgressStep(1);
        navigate('/auto/model');
        return;
      }
    }
    setErrors({ zip: yupMessages.zipCodeInvalid });
    return;
  };

  return (
    <div className="afi-intro-container">
      <VerticalLayout
        {...afiIntroContent}
        headerContent={headerContent}
        showInfoIcon={true}
        onIconClick={() => setShowDisclaimer(true)}
        img={introImages(partnerName, device)}
        content={
          <div className="afi-intro-content">
            <div className="afi-intro-fields">
              <InputField
                placeholder="Enter Zipcode"
                type="tel"
                inputMode="numeric"
                name="zip"
                value={values.zip}
                onChange={handleInputChange}
                onClick={handleClick}
                onBlur={handleBlur}
                errors={errors['zip'] && touched['zip'] ? errors : null}
                setErrors={setErrors}
                required={true}
                enableSubmit={values.zip.length === zipCodeLimit}
                className="with-banner"
              />
              <PrimaryButton
                text="Let's go!"
                onClick={handleClick}
                disabled={!(values.zip.length === zipCodeLimit)}
              />
            </div>
          </div>
        }
      />
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          content={
            <>
              <p className="disclaimer-table-text">
                In order to offer you accurate quotes and additional insurance products that may meet your
                protection needs, we will use the information collected from you and other sources, such as
                driving records, claims and credit history where permitted by law. The information will be
                used in accordance with our Privacy Policy. By seeking a quote, you agree to this collection
                and use of information. Please review our{' '}
                <a href={legalComplianceURL} target="_blank">
                  Privacy Policy
                </a>
                .
              </p>
              <p className="disclaimer-table-text">
                By clicking "Continue" you are authorizing Harmonic Insurance Services to obtain consumer
                reports on behalf of everyone you list on this online tool.
              </p>
            </>
          }
          subtitle={'Disclaimer'}
        />
      )}
    </div>
  );
};

export default AfiIntro;
