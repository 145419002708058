import PlusSign from '../../../assets/common/plus-sign.svg';
import './child-input.scss';
import InputField from '../forms/InputField/InputField';
import { useFormik } from 'formik';
import { object } from 'yup';
import { yupValidations } from '../../../services/yupValidations';

const ChildInput = ({
  age,
  name,
  index,
  onAgeChange,
  onNameChange,
  onDelete,
}: {
  age: string;
  name: string;
  index: number;
  onAgeChange: (newValue: string, index: number, key: 'age') => void;
  onNameChange: (newValue: string, index: number, key: 'name') => void;
  onDelete: (index: number) => void;
}) => {
  const formik = useFormik({
    initialValues: {
      name: name,
      age: age,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: object({
      age: yupValidations.ageNumberRequired(0),
      name: yupValidations.shortStringMinMaxLength(3, 50),
    }),
    onSubmit: () => { },
  });

  const { values, handleChange, handleBlur, errors, setErrors, touched, setFieldTouched } = formik;

  return (
    <div className="child-input-container">
      <div className={values['age'] ? 'field age suffix' : 'field age'}>
        <InputField
          name="age"
          placeholder="Age"
          value={age}
          type="text"
          maxLength={2}
          onChange={(e) => {
            onAgeChange(e.target.value, index, 'age');
            handleChange(e);
          }}
          errors={errors['age'] && touched['age'] ? errors : null}
          setErrors={setErrors}
          onBlur={handleBlur}
          onClick={() => setFieldTouched('age', true)}
        ></InputField>
      </div>
      <div className="field name">
        <InputField
          name="name"
          placeholder="Name"
          value={values.name}
          type="text"
          onChange={(e) => {
            onNameChange(e.target.value, index, 'name');
            handleChange(e);
          }}
          errors={errors['name'] && touched['name'] ? errors : null}
          setErrors={setErrors}
          onBlur={handleBlur}
          onClick={() => setFieldTouched('name', true)}
        ></InputField>
      </div>
      <button className="field delete-button" aria-label="Remove Child" onClick={() => onDelete(index)}>
        <div className="plus-sign">
          <PlusSign />
        </div>
      </button>
    </div>
  );
};

export default ChildInput;
